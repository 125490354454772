import * as React from "react";
import '../../assets/css/points.css'
import { H3 } from "@blueprintjs/core";


class BusinessList extends React.Component<any,any> {

public     componentDidMount(): void {
        console.log('list');
        
    }
    // 
public render() {
     const PointBusienssIds = this.props.businesses.PointBusinessIds;
     const businessnamelen=(n:any)=>{
        
            return n
        
     }
    return (
        <div className="BusiessListCss" >
         <H3 style={{textAlign:'center'}}>Business List</H3>
         <div>
       {this.props.businesses.business[0]!==undefined ? this.props.businesses.business.map((row:any,i:any)=>(
       <label key={i} className="label "  onClick={()=>this.props.businessChange(row.business_uid)}><p className="listtab" title={row.name===undefined ? row.business_name : row.name}>&#x2637;&nbsp;{row.name===undefined ? businessnamelen(row.business_name) : businessnamelen(row.name) }

       <span style={{float:'right'}}>
       {PointBusienssIds.includes(row.texn_business_uid) ? '' : 
         <img className="m-1" src={require("../../assets/images/sign-add-icon (1).png")}  width={'24px'} title="New Business "/>
       }
    {row.is_disabled===0 || row.is_disabled==='0' || row.is_disabled===null ? 
       <img className="m-1" src={require("../../assets/images/th.jpg")}  width={'20px'} title="Active"/>
       : PointBusienssIds.includes(row.texn_business_uid) ? 
       <img className="m-1" src={require("../../assets/images/344-3448055_locked-blocked-icon.png")}  width={'20px'} title="Disabled"/> :''
       }
          
       </span>
       </p></label>
        ))
    :
    <div >
        <img width={'50%'} style={{display:'block',margin:'auto'}} src={require("../../assets/images/loading_inline_dots.gif")}/>
    </div>
    }
        </div>
        </div>
    )
}

    
}

export default BusinessList




