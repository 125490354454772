import { doc, getDoc } from "firebase/firestore";
import { firebase } from "../global/firebase";




export const redeemCouponFunction=async(couponId:any, userId:any,token:any,mobile:any)=>{

    const couponsRef = await getDoc(doc(firebase,`users/${userId}/coupons`,couponId));
    const data=  couponsRef.data()!
    const x={
        action_type_id:1,
        token,
        encoding:"3",
        mobile,
        coupon_guid:data['coupon_guid'],
        coupon_code_guid:"{"+data['cc_guid']+"}",
        }
    await fetch(`https://client.texnrewards.net/gateway/v2/coupon_redeem.asp`,{
        method:'POST',
        body:JSON.stringify(x),        
    }).then((b)=>{
        console.log(b);
        
    }).catch((l)=>{
        console.log(l);

    })
    return 'reddemed'

}