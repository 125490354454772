import { Button, Card, Elevation, H3, H4, H5, H6, Spinner, Tab, Tabs } from "@blueprintjs/core"
import { useState ,useEffect} from "react"
import * as React from "react";

import { BusinessesCollection, urls } from "../global/firebase";
import { getDocs, query } from "firebase/firestore";


const ArchiveCheckIn=()=>{

    const [business,setbusiness]=useState([] as any);
    const [checkIns,setcheckIns]=useState([] as any);
    const [getArchiveData,setgetArchiveData]=useState([] as any);
    const [businessId,setbusinessId]=useState('');
    const [businessId2,setbusinessId2]=useState('');
    const [start,setstart]=useState('');
    const [end,setend]=useState('');
    const [startError,setstartError]=useState('');
    const [EndError,setEndError]=useState('');
    const [loading,setloading]=useState(false);
    const [loading2,setloading2]=useState(false);
    const [error,seterror]=useState('');
    const [errors,seterrors]=useState('');
    const [msg,setmsg]=useState('');
    const [timeout,settimeout]=useState(0);
    const [years,setyears]=useState([] as any);
    const [month,setmonth]=useState([] as any);
    const [sites,setsites]=useState([] as any);
    const [success,setsuccess]=useState([] as any);
    

   useEffect (()=>{
  
   if(business[0]===undefined || business[0]===null){
    setloading(true);
   const q = query(BusinessesCollection);
   const data = getDocs(q)
    data.then((e:any)=>{
      const arr:any = [];
       e.forEach((v:any)=>{       
           if(v.data().isArchive===undefined || v.data().isArchive===0){
           arr.push(v)  
           }
       })
       console.log(arr);
       
       setbusiness(arr)
       setloading(false);
   
        })

    }
   })

    const getToday=()=>{
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth()-13);
        const cDay = currentDate.getDate() < 10  ? '0'+currentDate.getDate()  : currentDate.getDate();
        const cMonth =(currentDate.getMonth()  )<10 ? '0'+(currentDate.getMonth()) : (currentDate.getMonth());
        const cYear = currentDate.getFullYear() <10 ? '0'+currentDate.getFullYear() : currentDate.getFullYear();
    return ''+cYear+'-'+cMonth+'-'+cDay+'';
    }

    const setStartDate=(e:any)=>{
     if(end!=='' && end<e.target.value){
        setstartError('Wrong! Start value');
    }else if(e.target.value<getToday()){
        setstart(e.target.value)
        setstartError('')
        }else{
         setstartError('Wrong! Start Date value');
    }
    }

    const setEndDate=(e:any)=>{
                if(e.target.value<getToday() && e.target.value>=start){
                setend(e.target.value);
                setEndError('')
                }else{
                setEndError('Wrong! End Datevalue');
                }
    }

const filter=(e:any,vv:any)=>{
    const va = e.target.value
    
    const r = getArchiveData.map((v:any)=>{
        const yy =v.month.split('_')[1];
        const y =yy.split('-')[0];
        const m =yy.split('-')[1];
        const ss =v.month.split('/')[0];
        if(vv==='m'){
            if(va===m || va===''){
                v.show=true;
            }else{
                v.show=  false;
            }
    
        }
         if(vv==='y'){
            if(va===y || va===''){
                v.show=true;
            }else{
                v.show=  false;
            }
        }
         if(vv==='s'){
            if(va===ss || va===''){
                v.show=true;
            }else{
                v.show=  false;
            }
        }
        return v;
        })
        setgetArchiveData(r)
    }
    
        const Archive=()=>{
            if(businessId==='' || start==='' || end===''){            
                setloading(false);
                seterror('All Fields are required.*');
                setsuccess('');  
            }else{
            
                const startDate = new Date(start);
                startDate.setHours(0,0,0,0);
                const endDate = new Date(end);
                endDate.setHours(0,0,0,0);
            
                if(window.confirm('Are you sure to archive this business checkIn data.')){
            
                    setloading(true)
                    seterror('');
                    setsuccess('');

                        const article = { 
                        start: start,
                        end:end,
                        bussiness_id:businessId,
            
                        start_month:startDate.getMonth(),
                        start_year:startDate.getFullYear(),
                        end_month:endDate.getMonth(),
                        end_year:endDate.getFullYear()
                        };
                        const xhttp = new XMLHttpRequest();
                        xhttp.onload = ()=> {
                            setloading(false)
                            seterror('');
                            setsuccess(' Archive request placed successfully.  This process will complete within a few minutes. ');           
                        }
                        xhttp.open("POST", urls.archivecheckIn+"p1s3e3CreateArchiveRequest");
                        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                        xhttp.send(JSON.stringify(article));
            
                }else{
                    setloading(false)
                    seterror('')
                    setsuccess('');  
                }
            }
               
            
            }
    
    
    
            const GetArchive=()=>{
                if(businessId2===''){          
                    setloading2(false)
                    seterrors('Select business');  
                }else{        
                        setloading2(true)
                        seterrors('')
                        setgetArchiveData([]);                        
                            const article = { 
                            bussiness_id:businessId2,
                            };
                            const xhttp = new XMLHttpRequest();
                            xhttp.onload = (res:any)=> {
                                if(res.target.status===200){
                                    if(JSON.parse(res.currentTarget.response)[0]===undefined){
                                        setgetArchiveData([])
                                        setloading2(false)
                                        setmsg('Data not found') 
                                    } else{   
                                        const datax = JSON.parse(res.currentTarget.response)  
                                        setgetArchiveData(datax)
                                        setloading2(false)
                                        setmsg('') 
    
                             const year:any = []
                             const month:any = []
                             const sites:any=[]
                             datax.map((x:any)=>{
                                try {
                                const YYc  =x.month.split('_')[1]
                                const YY  =YYc.split('-')[0]
                                const MM  =YYc.split('-')[1]
                                const site = x.month.split('/')[0]
                                if(!year.includes(YY)){
                                    year.push(YY)   
                                    }                    
                                if(!month.includes(MM)){
                                    month.push(MM)   
                                    } 
                                if(!sites.includes(site)){
                                    sites.push(site) 
                                }                                                            
                            } catch (error) {
                                 console.log(error);
                                    
                            }    
    
                             })
                            setyears(year);
                            setmonth(month);
                            setsites(sites) ;  
    
                                    }     
                                                        
                                }else{
                                    alert('Server error try again.')
                                   setloading2(false);
                                   seterrors(''); 
                                }
                            }
                            xhttp.open("POST", urls.archivecheckIn+"/p1s3e4GetArchivesUrls");
                            xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
                            xhttp.send(JSON.stringify(article));
                
                    
                }                
                }
    
    

return (
    <div className="main_div_Archive">
              <Tabs
                animate={true}
                key={"vertical"}
                vertical={false}
            >
                {/* <Tab id="rx" title="Archive data" panel={            
          <Card interactive={true} elevation={Elevation.TWO}>
            {success!=='' ? <H4 style={{color:'green',textAlign:'center'}}>{success}</H4> :''}
            <H3 style={{marginBottom:'20px'}}>Archive Business  </H3>
            
    <H5>
    <select  className="businessselect" onChange={(e)=>setbusinessId(e.target.value)}>
    <option>Select Business</option>    
    {business.map((v:any)=>
  (  <option key={v.id}  value={v.data().businessId}>{v.data().businessName}</option>)
)}
    </select>  
    </H5>
    <H5><label>Start Date (Date Should be 13 month ago)</label></H5>
    <H5><input onChange={(e)=>setStartDate(e)} className="w-100" type="month" /> <span className="danger">{startError}</span> </H5>
 
    <H5><label>End Date  (Date Should be 13 month ago)</label></H5>
    <H5><input  onChange={(e)=>setEndDate(e)} className="w-100"  type="month" />  <span  className="danger">{EndError}</span> </H5>
<H5><span className="danger">{error}</span></H5>
    <Button disabled={loading || startError!=='' || EndError!==''} large={true} onClick={Archive} intent={'warning'}>
      {loading ? <Spinner
                    intent={'danger'} 
                    size={20}
                /> :  'Continue' }</Button>
               
                
</Card>
   } /> */}
   <Tab id="ng" title="Get Archive data" panel={
         <Card interactive={true} elevation={Elevation.TWO}>
         <H3 style={{marginBottom:'20px'}}>Get Archive Business Data</H3>
         <H6>Note:Download button is only valid for 15 minutes.</H6>
 <H5>
 <select className="businessselect" onChange={(e)=>setbusinessId2(e.target.value)}>
 <option>Select Business</option>    
 {business.map((v:any)=>
(  <option key={v.id}  value={v.data().businessId}>{v.data().businessName}</option>)
)}
 </select> 
 
 <H5><span className="danger">{errors}</span></H5> 
 <Button disabled={loading2} large={true} intent={'warning'} onClick={()=>GetArchive()}>Get Data</Button>
 </H5>
<H4 style={{color:'red',textAlign:'center'}}>{msg}</H4>
<div className="w-100">
{month.length>0 ?<select className="float-right" onChange={(e:any)=>filter(e,'m')}>
    <option value={''}>Select Month</option>  
{month.length>0 ? month.map((x:any,i:any)=><option key={i} value={x}>{x}</option>) :''}
</select>:''}

{years.length>0 ?<select className="float-right" onChange={(e:any)=>filter(e,'y')}>
    <option value={''}>All Years Data</option>  
{years.length>0 ? years.map((x:any,i:any)=><option key={i} value={x}>{x}</option>) :''}
</select>:''}

{sites.length>0 ?<select className="float-right" onChange={(e:any)=>filter(e,'s')}>
    <option value={''}>Select Site</option>  
{sites.length>0 ? sites.map((x:any,i:any)=><option key={i} value={x}>{x}</option>) :''}
</select>:''}
</div>
<div>
       {getArchiveData.map((v:any,index:any)=>(
       v.show===undefined || v.show===true ?  <div className="archivedata" key={index}>
            <span className="w-50">{v.month}</span>&nbsp;&nbsp;&nbsp;
            <span className="w-50">{v.size}<span style={{fontSize:'11px'}}> KB</span></span>&nbsp;&nbsp;&nbsp;
            <span className="w-50"> <Button onClick={()=>window.open(v.link)} large={true} intent={'primary'} >Download</Button></span>
            </div> : ''
        ))}

</div>

 </Card>
     
   } />
   
</Tabs>
        </div>
)

}


export default  ArchiveCheckIn