
import React, { useEffect, useState }  from 'react';
import { Button, Card, Elevation, HTMLTable, InputGroup, Label, Tab, Tabs } from '@blueprintjs/core';
import { urls } from '../global/firebase';
import { useAuthUser } from 'react-auth-kit';
import { LocationCouponData } from './LocationCouponData/LocationCouponData';
let interval:any;
 const Pointsystemquery=()=>{

  const [key,setkey]=useState('');
  const [date,setdate]=useState('');
    const [mobile,setmobile]=useState('');
    const [url,seturl]=useState('');
    const [busdaata,setbusdaata]=useState([] as any);
    const [bool,setbool]=useState(false);
    const [disable,setdisable]=useState(false);
    const [datalog,setdatalog]=useState([])
    const [firstname,setfirstname]=useState('')
    const [lastname,setlastname]=useState('')
    const [datex,setdatex]=useState('')
    const [conc,setconc]=useState('')
    const auth:any = useAuthUser();
    const [user,setuser]=useState({permissions:{role:''}})

    const role = auth().accountData.permissions.role
    useEffect(() => {
      if(!bool){
        setbool(true)
        p1s5e6_get_businesses()
      }
 
    },[busdaata])

const p1s5e6_get_businesses=async()=>{

   const requestOptions:any = {
    method: 'POST',
    redirect: 'follow'
  };

  const userState = localStorage.getItem('_auth_state');
  const user = userState!==null ?   JSON.parse(userState).accountData:null;
  setuser(user)
 await fetch(urls.pointsystem+'p1s5e6_get_businesses', requestOptions)
    .then((response) => response.json())
    .then((result) => {
      const arrx:any= [];
      if(user!==null && userState!==undefined && userState!==null){        
       Object.keys(result).forEach((k,i) => {            
        if((user.permissions.businesses.includes(result[k].texn_business_uid) || user.permissions.businesses.length<=0) && result[k].is_disabled===0){
         
          arrx.push(result[k])         

        }
      });    
      

   
      
      setbusdaata(arrx);
      
    }
    
    });



  }




const openwin=()=>{    
  let con = '';
  try {

  busdaata.map((x:any)=>{
   const  keyx = key.split('_')[1];
   if(x.s3_ids[0]!==undefined && x.s3_ids[0]===keyx){

if(x.coupon_rule_settings!==undefined && x.coupon_rule_settings.CET1!==undefined && x.coupon_rule_settings.CET1.coupon_brackets!==undefined){
  x.coupon_rule_settings.CET1.coupon_brackets.map((r:any)=>{

    con+=`${r[2]}% - ${r[0]} Points,`


  });
}
   }

  })
      
} catch (error) {
    console.log(error);
    
}


  // const  url = `/LocationCouponData?yard_api_key=${key}&val=${con}`;
  if(key=='' || key.length>20){
   alert('please enter valid key')
  }else{

    setconc(con)

  
  }

}

function getLast7Days() {
  const weekdays = [];
  let daysChecked = 0;
  const today = new Date();
  
  while (weekdays.length < 7) {
      const dayToCheck = new Date();
      dayToCheck.setDate(today.getDate() - daysChecked);
      const dayOfWeek = dayToCheck.getDay();
      
      if (dayOfWeek <= 7) {
          weekdays.push(dayToCheck.toISOString().split('T')[0]);
      }
      daysChecked++;
  }


  const arr:any = []
  weekdays.map((x:any)=>{    
    
    arr.push({date:x,day:convertToPythonWeekday(new Date(x).getDay())})
  })
  console.log(arr)
  return arr;
}

function convertToPythonWeekday(jsDay:any) {
  // If it's Sunday in JS (0), convert it to 6 (Python Sunday)
  if (jsDay === 0) {
      return 6;
  }
  // Otherwise, subtract 1 to align with Python's weekdays
  return jsDay - 1;
}

const RunBackup =async()=>{
  setdisable(true)
  if(window.confirm('Are you sure to run this backup.')){
  const myHeaders = new Headers();
  
  const requestOptions:any = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow',
    mode:'no-cors'
  };

 clearInterval(interval); 
 interval =setInterval(() => {
  p1s5e6_get_datalog()
  
 }, 20000); 
 await fetch(urls.pointsystem+'BackupRunCron?day='+date,requestOptions).then(async(e)=>{
    console.log(e);
    setdisable(false)
    
  }).catch((e)=>{
      console.log(e);
      setdisable(false)
  })
  }
}


const p1s5e6_get_datalog=async()=>{

  const requestOptions:any = {
   method: 'GET',
 };


await fetch(urls.pointsystem+'p1s5e6_get_backuplog?day='+date, requestOptions)
   .then((response) => response.json())
   .then((resultx) => {
     
     console.log(resultx.res.length);

if(resultx.res.length<=0){
alert('Proccess complete');
clearInterval(interval);
}
     setdatalog(resultx.res);

   setTimeout(() => {
    window.scrollTo({ top: 100000, behavior: 'smooth' });
   }, 500);  

     
   })
   




 }




 const clearfilter=()=>{

  setmobile('')
  setfirstname('')
  setlastname('')
  setdatex('')
 }

    return(
      <div className="main_div_report" style={{width:'95%'}}>
            
            <br/>          
            <Tabs
                animate={true}
                key={"vertical"}
                vertical={false}
            >

       <Tab id="ng" title="Point System Dashboard" panel={
       
       <Card interactive={true} elevation={Elevation.TWO} >  
         <h2>Point System Dashboard</h2>
         <div style={{display:'inline-flex',marginBottom:'10px'}}>          
         
            <select name="name" id="ifd" onChange={(e:any)=>{ setkey(e.target.value); setconc('')}}>
              <option value={''}>Select Business</option>
              { busdaata.map((rz:any)=>
                  <option value={'S3Crush_'+rz.s3_ids[0]}>{rz.name}</option>
              )}
            </select>&nbsp;&nbsp;
          
           
        

            
            <Button disabled={key==''}  intent='success' onClick={()=>openwin()}>Continue</Button>
         </div>
         <br/>

    {key!==undefined && key!=='' && conc!=='' ? <LocationCouponData keyd={key} conc={conc}/> :''}
         </Card>
       }/>



{role=='admin'?<Tab id="ngt" title="Point System Backup Run" panel={
       
       <Card interactive={false} elevation={Elevation.TWO}>  
         <div >
            <h1>Select Backup Date To Run</h1>
            <Label>Select Backup Date  * <br/>
            <select name="name" id="ifd" onChange={(e:any)=>{ setdate(e.target.value)}}>
              <option value={''}>Select Date</option>
              { getLast7Days().map((rz:any)=>
                  <option value={rz.day}>{rz.date}</option>
              )}
            </select>
            </Label>
            
          {date!='' ?<HTMLTable border={1}>
              <tr>
                <th>Business</th>
                <th>Files List To Proccess:</th>
              </tr>
              {busdaata.map((rz:any)=>
              <tr>
                <td>{rz.name}</td>
                <td>{'backup_'+rz.s3_ids[0]+'_'+date+'_dailytrasactions.xml'}</td>
                <td>
        <a target="_blank"  href={'http://45.79.157.162/saved7daysdata/'+'backup_'+rz.s3_ids[0]+'_'+date+'_dailytrasactions.xml'} ><Button intent={'success'} rightIcon={'eye-open'}>View</Button></a>
       &nbsp;
        <Button intent={'success'} onClick={()=>window.open(urls.toolsandautomated+'downloadxmlfile?url=http://45.79.157.162/saved7daysdata/'+'backup_'+rz.s3_ids[0]+'_'+date+'_dailytrasactions.xml','download.xml')} rightIcon={'download'}>Download</Button>
        
        </td>
              </tr>)}
            </HTMLTable>:''}
            <br/>
            <Button disabled={(date=='' || disable) && user.permissions.role!='admin'}   intent='success' onClick={()=>RunBackup()}>{disable ? 'Stay on page until proccess  not complete.It takes 15-20 minutes':'Run'}</Button>
         </div>

         {disable ? 
      <div id="datalog"
      style={{ padding: '10px',background: '#000',color: '#01d101', marginTop: '10px'}}
      >
  {datalog.map((x:any)=>
    <p>{x}</p>
  )}
      </div>
    :'' } 
         </Card>
       }/>:''}

    
</Tabs>

        </div>
    )
}


export default Pointsystemquery