
import React , { useEffect, useState }  from 'react';
import { firebase } from '../global/firebase';
import { collection, deleteDoc, doc, getDoc, getDocs, query } from 'firebase/firestore';
import { Button, H1, Icon, Spinner } from '@blueprintjs/core';
import store from '../actions/store/store';
import { useSelector } from 'react-redux';
const ReportScheduling=()=>{
    const [Scheduling,setScheduling]=useState([] as any);
    const [loading,setloading]=useState(false);
    const [bool,setbool]=useState(false);
    const [Businesses,setBusiness]=useState([]);
    const store = useSelector((state:any) => state)
    useEffect(()=>{
        
        if(Scheduling.length<=0 && !bool){
        getScheduler()
        setbool(true)
        }
if((store.BusinessesReducer.Businesses.length+store.BusinessesReducer.Archived.length)===store.BusinessesReducer.len){    
    setBusiness(store.BusinessesReducer.Businesses)
}
        
    },[Scheduling,Businesses])

const getScheduler=async()=>{
    setloading(true)    
    const queryRef =await getDocs(query(collection(firebase,'report-scheduling')))
    const arr:any = [];

   queryRef.docs.map(async(res:any)=>{
       const data = res.data().map;
      if(data!==undefined){
           
        arr.push({
            id:res.id,
            active:data.active,
            businessId:data.businessId,
            email:data.email,
            occurence:data.occurence,
            siteId:data.siteId,
            type:data.type,
            });
        
    }
    });    
    setScheduling(arr) 
if(queryRef.empty){
    setloading(false)
}
}

    const DeleteScheduling=async(id:any)=>{        
        if(window.confirm('Are you sure to delete this schedule')){
            setloading(true)
            setScheduling([]) 
            await deleteDoc(doc(collection(firebase,'report-scheduling'), id)).catch((e:any)=>{
                                    console.log(e);                                
            }).then(()=>{         
                alert('Successfully Deleted.')
                getScheduler()
            });
     }        
    }

const getbusinessname=(id:any,sid:any)=>{
    let name= 'All'
    
    Businesses.map((bus:any,indexb:any)=>{
            if(bus.businessId===id){
                if(sid!==null){
                    bus.sites.map((site:any,indexb:any)=>{
                        if(site.siteId===sid){
                            name= site.path
                        }
                    })
                }else{             
               name =bus.businessName; 
                }
            }
    })
    return name
}

return(
    <div >
        <H1 className='mt-10'>Scheduled Reports</H1>
    <table  className='table1' border={1} style={{overflow:'auto'}}>
        <tr>
            <th>Delete</th>
            <th>Email</th>
            <th>Business</th>
            <th>Site</th>
            <th>Type</th>
            <th>Occurence</th>
        </tr>
        <tbody>
           {Scheduling!==undefined && Scheduling!==null && Scheduling.length>0 ? Scheduling.map((val:any,i:any)=>(
            <tr key={i}>
            <td><Button intent='danger' onClick={()=>DeleteScheduling(val.id)}><Icon icon='trash'/></Button></td>
            <td>{val.email}</td>
            <td>{getbusinessname(val.businessId,null)}</td>
            <td>{getbusinessname(val.businessId,val.siteId)}</td>
            <td>{val.type}</td>
            <td>{val.occurence}</td>
            </tr>
            )):loading ? <tr><td colSpan={6}><Spinner         
            intent={'primary'}
            size={50} /></td></tr>:''}
        </tbody>
    </table>
  
    </div>
)

}


export default ReportScheduling