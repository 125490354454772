import { Button, Classes, Dialog, H3, H4, Icon, Popover, Spinner } from "@blueprintjs/core";
import * as React from "react";

import SelectTimeFrame from "./SelectTimeFrame";
import { firebase, urls } from "../../../global/firebase";
import { collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";
import { redeemCouponFunction } from "../../../functions/redeemCoupon";
import { Refreshcoupons } from "../../../functions/refreshcoupons";



const CheckInsList = (dat:any)=>{
const data= dat.data;
const viewCheckIn=dat.viewCheckIn;
const maskNumber=dat.maskNumber;
const stampTotime=dat.stampTotime;
const searchRecoards=dat.searchRecoards;
const setEtate = dat.setEtate;
const dialogMore = data.dialogMore;


const closepopup=()=>{
    if(data.popup===true){
        setEtate({popup:false})
    }else{
        setEtate({popup:true})  
    }
}

const onclickMore=async(v:any)=>{       
    setEtate({coupons:[],load:true,dialogMore:true})
        if(v.userId!=='opt-out'){
                await refreshCoupons(v.businessId,v.mobile,v.userId,v.siteId)
                setEtate({userIdCurrent:v.userId})    
                snapshortUserCoupons(v)   
        }    
}

const snapshortUserCoupons=async(v:any)=>{

  const docRef =query(collection(firebase, `users/${v.userId}/coupons`))
   onSnapshot(docRef,(dart:any)=>{
    if(!dart.empty){                    
      setEtate({coupons: dart.docs})
  }
   })
  
     
}

const expiresDateCheck=(date:any)=>{
const dd=  new Date();
const seconds = dd.getTime() / 1000;
if(seconds>date.seconds){    
return true
}else{
  return  false
}
}



const callRefreshCoupons = async (
    businessId: string,
    guid: string,
    mobile: string,
    userId: string,
    token:string,
  ) => {

    try {
      console.log("querying coupons....");
      const data:any = {businessId,guid,mobile,userId,token };
      await Refreshcoupons(data).then((e:any)=>{
        if(e===undefined){
        setEtate({load:false})
        }
      }).catch(()=>{
        setEtate({load:false})
      }) 
      

    } catch (err) {
      console.log(err);
    }
  };

  const refreshCoupons = async (
    businessId: string,
    mobile: string,
    userId: string,
    siteId?: string
  ) => {
    // refresh coupons
    console.log("starting coupon refresh....");


      const docRef =query(collection(firebase, `businesses`),where('businessId','==',businessId)) 
      const queryx =await getDocs(docRef);
    const businessGuid = queryx.docs[0].data().guid;
    const businesstoken = queryx.docs[0].data().businesstoken;
    callRefreshCoupons(businessId, businessGuid, mobile, userId,businesstoken);

    // now do this for every site
    const quersite = query(collection(firebase, `businesses/${businessId}/sites`))
    const sitesQuery = await getDocs(quersite);
    sitesQuery.forEach((doc:any) => {
      const siteGuid = doc.data().guid;
      if (siteGuid) {
        callRefreshCoupons(businessId, siteGuid, mobile, userId,businesstoken);
      }
    });
  };

const redeemCoupon=async(v:any,bool:any)=>{
    if(bool===false){
        const busienssId = v.data().businessId
        
        const dartx = (await getDoc((doc(firebase, `users`,data.userIdCurrent)))).data()!        
        const busines =(await getDoc((doc(firebase, `businesses`,busienssId)))).data()!

       const dart = await updateDoc(doc(firebase, `users`,data.userIdCurrent,'coupons',v.id), {
          redemption_flag:true,
      }).then(async()=>{
        if(busienssId && busines.businesstoken!==undefined && busines.businesstoken){
            try {
              console.log("querying redeem coupons....");
              await redeemCouponFunction(
                v.id,
                data.userIdCurrent,
                busines.businesstoken,
                dartx.mobile
            ).then((e:any)=>{
              if(e===undefined){
              setEtate({load:false})
              }
            }).catch(()=>{
              setEtate({load:false})
            })         
            
            } catch (err) {
              console.log(err);
            }
          }
    }); 
    console.log(dart);
    }
}

    return  <div>
     
    <H3 className="listAboveText" style={{padding:'8px'}}>{data.listAboveText}</H3>
    <div>
    <input className="searchInput" onChange={(e)=>searchRecoards(e)} type="search" placeholder="search.."/>
    {data.screenSize<750 ?  <Button className="selecttimeMobileButton" onClick={(e)=>closepopup()} intent={"primary"} >Select Time Frame</Button>:''}
{data.screenSize<750 ?    <Dialog
                // popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                portalClassName="fooo"
                enforceFocus={false}                
                onClose={()=>setEtate({popup:false})}
                isOpen={data.popup}
            >    
            {/* <Button className="selecttimeMobileButton" onClick={(e)=>closepopup()} intent={"primary"} >Select Time Frame</Button> */}
            <SelectTimeFrame 
            data={data}
            getPreviousData={(e:any)=>dat.getPreviousData(e)}
            hourtoAmPm={(e:any)=>dat.hourtoAmPm(e)}
            /> 
            </Dialog>:''}

       </div>
    <p style={{height:'0px',padding:'0px'}}>&nbsp;</p>
    {data.loading ?  <p className="pclass">   <img className="loader" src={require("../../../assets/loading_inline_dots.gif")}/></p> :''}
    {data.msg!=='' ? <span style={{display:'block',textAlign:'center'}}>{data.msg }</span> :''}
    <div className="listcss">
            {data.currentData.length>0 ? data.currentData.map((valu:any,i:any)=> 
  (valu!==undefined ?  <p key={i} onClick={()=>viewCheckIn(valu)} style={{display:valu.show===undefined || valu.show===true ?'block':'none'}}> 
    <a href="#">{valu.name!==undefined && valu.name!==null && valu.name.displayName!==undefined && valu.name.displayName!==null ? valu.name.displayName.substr(0, 15) : ''}  {!isNaN(parseInt(valu.mobile,10)) ? maskNumber(valu.mobile) : 'Opt-Out'}  <span className="float-right"> {stampTotime(valu.timestamp)}
    {data.todaysCouponOption!==undefined && data.todaysCouponOption!==false  ? <a onClick={()=>onclickMore(valu)} className="icontodayscheckins" style={{padding:'4px'}}>&nbsp;<Icon icon={'more'}/>&nbsp;</a> : ''} </span></a> </p> 
 :'' )   ):''}
     </div>


     <Dialog onClose={()=>setEtate({dialogMore:false})} title="User Setting" icon="info-sign" isOpen={dialogMore}>
      {data.coupons[0]!==undefined && data.coupons[0]!==null ?   <H4>&nbsp;Coupons</H4>:''}
   {!data.load ? <div className="coupons">
      {data.coupons[0]!==undefined && data.coupons[0]!==null ? data.coupons.map((v:any,i:any)=>(
      <p key={i} className="couponlist">{v.data().coupon_name} {<Button className="float-right" intent={'success'} disabled={v.data().redemption_flag || expiresDateCheck(v.data().expires_date)}
       onClick={()=>redeemCoupon(v,(v.data().redemption_flag || expiresDateCheck(v.data().expires_date)))}>
        {v.data().redemption_flag  ? 'Redeemed' : expiresDateCheck(v.data().expires_date) ? 'Expired' : 'Redeem'}</Button>}</p>
      )):<H4>&nbsp;Coupons Not Found</H4>}
      </div>: 
       <Spinner                   
            intent={'primary'}
            size={50}  
                />
      }
    </Dialog>
    </div>
}

export default CheckInsList;