 // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { collection, getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getFunctions } from 'firebase/functions';
import { useAuthUser } from "react-auth-kit";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

let devFlag:any = false;
let firebaseConfig = {};

// Dev / live flag set
if (window.location.hostname.toString().includes('dev') ||
    window.location.hostname.toString().includes('localhost') || 
    window.location.hostname.toString().includes('127.0.0.1')
  ) {
  devFlag = true;
}
   
if (devFlag === true) {
  console.debug("USING Dev DATABASE");
  firebaseConfig = {
    apiKey: "AIzaSyCPC-jgFEHRjYR1tOBtWeYVv3W-nUU8rgs",
    authDomain: "dev-just-in-north.firebaseapp.com",
    projectId: "dev-just-in-north",
    storageBucket: "dev-just-in-north.appspot.com",
    messagingSenderId: "890180893613",
    appId: "1:890180893613:web:09de53eaf92f6058b83f24"
  };
} else if(devFlag===false){
  console.debug("USING PRODUCTION DATABASE");
  firebaseConfig = {
    apiKey: "AIzaSyCJfKcc_OyA0rv-jA9yH_HC7PDi8PylfCc",
    authDomain: "just-in-north.firebaseapp.com",
    databaseURL: "https://just-in-north.firebaseio.com",
    messagingSenderId: "581547763015",
    projectId: "just-in-north",
    storageBucket: "just-in-north.appspot.com"
  };
}else{

  console.log("USING upgraded DATABASE");
  firebaseConfig = {
    apiKey: "AIzaSyCfC8xBG3HRt0vgrEch0-t7a9qoLI15vJQ",
    authDomain: "dev-just-in-north-upgraded.firebaseapp.com",
    databaseURL: "https://dev-just-in-north-upgraded-default-rtdb.firebaseio.com",
    projectId: "dev-just-in-north-upgraded",
    storageBucket: "dev-just-in-north-upgraded.appspot.com",
    messagingSenderId: "441962057488",
    appId: "1:441962057488:web:457c691b739d9474bb9f95",
    measurementId: "G-0P3J1B61BS"
  };
}
// Initialize Firebase

const app = initializeApp(firebaseConfig);
 
export const functions = getFunctions(app);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const auth = getAuth(app);
export const firebase = getFirestore(app);


// db
export const UsersCollection = collection(firebase, 'users');
export const BusinessesCollection = collection(firebase, 'businesses');



export const urls={
  baseUrlKiosk:devFlag===false ? 'https://kiosk.texnrewards.com' : devFlag===true ? 'https://dev-just-in-kisok.firebaseapp.com' : 'https://dev-just-in-kiosk-upgraded.web.app',
  baseUrlJustIn:devFlag===false ? 'https://just-in.texnrewards.com/just-in' : devFlag===true ?  'https://dev-just-in-north.firebaseapp.com/just-in': 'https://dev-just-in-north-upgraded.web.app/just-in',
  baseUrlJustInMulti:devFlag===false ? 'https://just-in.texnrewards.com/just-in-ml' : devFlag===true ?  'https://dev-just-in-north.firebaseapp.com/just-in-ml': 'https://dev-just-in-north-upgraded.web.app/just-in-ml',

  // archivecheckIn:'https://p1s3-archive-check-ins-dot-dev-just-in-north.ue.r.appspot.com/'
  archivecheckIn:devFlag===false ? 'https://p1s3-archive-check-ins-dot-just-in-north.uc.r.appspot.com/' : devFlag===true ? 'https://p1s3-archive-check-ins-dot-dev-just-in-north.ue.r.appspot.com/' : 'https://p1s4-tools-auto-test-dot-dev-just-in-north-upgraded.uc.r.appspot.com/',
  toolsandautomated:devFlag===false ? 'https://p1s4-tools-auto-test-dot-just-in-north.uc.r.appspot.com/' :  devFlag===true ? 'https://p1s4-tools-auto-test-dot-dev-just-in-north.ue.r.appspot.com/': 'https://p1s4-tools-auto-test-dot-dev-just-in-north-upgraded.uc.r.appspot.com/',
  getMatchesForVehicle:devFlag===false ? 'https://p1s4-tools-auto-test-dot-just-in-north.uc.r.appspot.com/getMatchesForVehicle' :  devFlag===true ? 'https://p1s4-tools-auto-test-dot-dev-just-in-north.ue.r.appspot.com/getMatchesForVehicle': 'https://p1s4-tools-auto-test-dot-dev-just-in-north-upgraded.uc.r.appspot.com/',
 pointsystem:devFlag===false ? 'https://p1s5-dot-live-points-coupon-system.uc.r.appspot.com/' :'https://p1s5-dot-dev-just-in-north-upgraded.uc.r.appspot.com/'


}

