import { Alignment, Button, Classes, InputGroup, Navbar, NavbarDivider, NavbarGroup, NavbarHeading } from "@blueprintjs/core"
import React  from 'react';
import { useAuthUser, useIsAuthenticated, useSignOut } from "react-auth-kit";
import { Link, useNavigate } from "react-router-dom";
import { AllRoutes } from '../routes/routes';
export  const NavBarMenu = ()=>{
    const auth:any = useAuthUser();
    let navigate = useNavigate();
    const signOut = useSignOut()
    const isAuthenticated = useIsAuthenticated();
    const locationnn = window.location.pathname
    const email=isAuthenticated() && auth()!==null && auth().accountData!==undefined && auth().accountData!==null && auth().accountData.email!==undefined ? auth().accountData.email : '';

    const signoutuser=()=>{
        navigate('/')
        signOut()
        window.location.href ='/'
    }
return(
   ( locationnn.indexOf('just-in')==-1 &&  locationnn.indexOf('unsubscribe-from-reports')==-1) ? <Navbar fixedToTop style={{width:'auto',overflowX:'auto',overflowY:'hidden'}} >
    <NavbarGroup align={Alignment.LEFT} >
        <NavbarHeading><img src="https://just-in.texnrewards.com/favicon.ico"/></NavbarHeading>
        {/* <NavbarDivider /> */}
        {AllRoutes().map((r:any,index:any)=>( r.menu ?         
        <Link key={index} to={r.path}><Button className={Classes.MINIMAL} text={r.name} /></Link>
        :''
        ))}  
        {/* &nbsp;&nbsp; <NavbarDivider /> */}
        <div style={{display:'flex',position:window.innerWidth>=1460?'absolute':'unset',right:'5px'}}>
        <InputGroup 
        

                    large={false}
                    placeholder="Enter email"
                    defaultValue={email}
                    readOnly={true}    
                            
                /> 
                <Button  intent="danger" icon='archive' text={'Logout'} onClick={(e)=>signoutuser()}/>     
                </div>  
    </NavbarGroup>
</Navbar>:<div></div>
)


}