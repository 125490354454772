
import { Button, ButtonGroup, Icon } from '@blueprintjs/core';
import { doc, query, setDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState }   from 'react';
import { useSelector } from 'react-redux';
import { UsersCollection } from '../global/firebase';
export const BusinessAndSiteDropdown = (data:any)=>{

    const [UserBusiness,setUserBusiness]=useState([] as any);
    const [UserSite,setUserSite]=useState([] as any);
    const [Businesses,setBusinesses]=useState([])
    const [SelectedBusinesses,setSelectedBusinesses]=useState([] as any)
    const [SelectedSite,setSelectedSite]=useState([] as any)
    const store = useSelector((state:any) => state)
    useEffect(()=>{          
        setBusinesses(store.BusinessesReducer.Businesses)
           if(data.user.accountData!==undefined && data.user.accountData!==null){

            setUserBusiness(data.user.accountData.permissions.businesses);
            setUserSite(data.user.accountData.permissions.sites);
    
           }                 
    },[store])

    const SelectedSit=(e:any)=>{
    if(e.target.value!==''){       
        setSelectedSite(e.target.value)
    }
    }

    const selectBusiness=(e:any)=>{
        if(e.target.value!==''){        
       setSelectedBusinesses(e.target.value)
       setSelectedSite('')
        }
    }

    
    // remove code //
    const removeSite=(bId:any,sId:any)=>{      
        const final = UserSite.filter((v:any)=>v!==sId)    
            
        setUserSite(final)
       }

    const removeBusiness=(bId:any)=>{      
        Businesses.map((value:any)=>{
                if(value.businessId===bId){
                    const arr:any = []
                    value.sites.map((site:any)=>{
                        const final = UserSite.filter((v:any)=>v!==site.siteId);
                            if(final!==undefined && final.length>0){
                            arr.push(final)
                            }
                    });
                   
                    console.log(arr,'remove buseiness');
                    
                    setUserSite(arr) 
                }
        });
        const finalx = UserBusiness.filter((v:any)=>v!==bId)
        setUserBusiness(finalx)
       }
     
    //  remove code end
    
    // Add code //
    const addbusiness=()=>{
        if(SelectedBusinesses.length>=1){
         
        const arr:any[] =UserBusiness.concat(SelectedBusinesses)                         
        setUserBusiness(arr)
        if(SelectedSite.length<=0){
                Businesses.map((value:any)=>{
                    if(SelectedBusinesses!=='' && value.businessId===SelectedBusinesses){
                        const arr:any = []
                        value.sites.map((site:any)=>{
                            arr.push(site.siteId)
                        });
                        const arr2:any[] = UserSite.concat(arr)                                 
                        setUserSite(arr2) 
                    }
                });
            }else{
        const arr2:any[] = UserSite.concat(SelectedSite)     
        console.log(arr2,'76587528754');
        const arrs = arr2.filter((e)=>(e!==undefined && e!==null))        
        setUserSite(arrs)  
            }  
        }else{
            alert('Please select business and sites');
        }      
    }
    // Add code close

    const SaveBusinesses=async()=>{
        const user:any = data.user;
        
        user.accountData.permissions.businesses = UserBusiness
        user.accountData.permissions.sites = UserSite 


        await setDoc(doc(UsersCollection, user.userId), 
        user
            );
        data.returnAfterUpdate(user);    
        alert('Successfully updated')    

    }

return(
    <div className="row p-1">
<div className='mt-mb-10'>
<select className='busiteDropdown' onChange={(e)=>selectBusiness(e)}>
    <option  value={''}>All Businesses</option>
   {Businesses[0]!==undefined ? Businesses.map((bu:any,ind:any)=>(
   <option key={ind} value={bu.businessId} disabled={UserBusiness.includes(bu.businessId)}>{bu.businessName}</option>
    )) :''}
  </select>&nbsp;&nbsp;

   {Businesses[0]!==undefined ? Businesses.map((bu:any,indx:any)=>(
 SelectedBusinesses===bu.businessId ? 
 <span  key={indx}>
 <select key={indx} className='busiteDropdown' onChange={(e)=>SelectedSit(e)}>
 <option value={''}>All Sites</option>
    {bu.sites.map((se:any,indexsite:any)=>(
        <option key={indexsite} value={se.siteId} disabled={UserSite.includes(se.siteId)}>{se.path}</option>
    ))}
 </select>&nbsp;<Button  icon='plus'  intent='success' onClick={()=>addbusiness()} /></span>
 :''
    )) :''}


</div>
{Businesses[0]!==undefined ? Businesses.map((b:any,index:any)=>(
     UserBusiness.includes(b.businessId) ? 
    <div key={index} className='mt-10'>
       <ButtonGroup minimal={false}  > <Button  small={true} className='mr-1' intent='primary'>{b.businessName}</Button><Button onClick={()=>removeBusiness(b.businessId)} intent='danger' small={true}><Icon icon="cross" color='darkred' /></Button> </ButtonGroup>       {
            b.sites.map((s:any,unde:any)=>(UserSite.includes(s.siteId) ? <ButtonGroup key={unde+'7'}  minimal={false} > <Button small={true} className='mr-1' intent='success'>{s.path}</Button><Button intent='danger' onClick={()=>removeSite(b.businessId,s.siteId)} small={true}><Icon  icon="cross" color='darkred' /></Button></ButtonGroup>:''))
        }
    </div>
     :'')) : ''}
<div style={{float:'right'}}>
     <Button large={true} intent='primary' onClick={()=>SaveBusinesses()}>Save</Button>
</div>     
    </div>
)
}