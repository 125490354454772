import {
  Button,
    ControlGroup,
    FormGroup,
    // H1,
    HTMLSelect,
    InputGroup,
    Tag,
    // IOptionProps,
  } from "@blueprintjs/core";
  
  import "@blueprintjs/core/lib/css/blueprint.css";
import { collection, getDocs, query, where } from "firebase/firestore";
  import * as React from "react";
import {  firebase } from "../global/firebase";
import store from "../actions/store/store";
import { withAuthUser } from "react-auth-kit";
import { substractRead } from "../global/documentreadcount";
  
  const tagStyle = {
    backgroundColor: "#0D47F0"
  };
  const tagNumStyle = {
    backgroundColor: "white"
  };
  const TagStyletotal = {
    backgroundColor: "red"
  };
  interface Sites {
    label:undefined | string;
    value:number | string
  }
  
  interface Business{
    businessId: null | string;
    businessName: string;
    dailyCheckIns:null | number;
    dailyOptOuts:null | number;
    sites: Sites[];
    sitesId:null | string;
  }
  let progress=0;
  
  interface State{
    businessData: Business[],
    init:boolean,
    progress:number,
  }
  
  class Dashboard extends React.Component<any, State> {
    public state:State = {
        businessData : [],
        init:false,
        progress:0,
    }
  
  
    public componentDidMount(){
        progress=0;
        this.initDashboard();
    }

 
  
    public get_total(){
      const { businessData } = this.state;
      let total=0;
  
      businessData.sort(
        ({ businessName: pathA }, { businessName: pathB }) =>
          pathA === pathB ? 0 : pathA > pathB ? 1 : -1
      ).map((bData,index)=>{
        total +=bData.dailyOptOuts==null ? 0 : bData.dailyOptOuts;
  
      });
  
  return total;
  
    }
  
  
    public get_total_daily_count(){
      const { businessData } = this.state;
      let totalt=0;
  
      businessData.sort(
        ({ businessName: pathA }, { businessName: pathB }) =>
          pathA === pathB ? 0 : pathA > pathB ? 1 : -1
      ).map((bData,index)=>{
        totalt +=bData.dailyCheckIns==null ? 0 : bData.dailyCheckIns;
  
      });
  
  return totalt;
  
    }
  
  
    
    public render(){
        const { businessData } = this.state;
        return(
            <div  className="dashboardDiv" style={{marginTop:'20px'}}>
            {this.state.init ? businessData.sort(
                ({ businessName: pathA }, { businessName: pathB }) =>
                  pathA === pathB ? 0 : pathA > pathB ? 1 : -1
              ).map((bData,index)=>(bData.sites.length>0?
                <div id="dashboard-inner-block" style={{padding: '10px',background: '#d7d7d7',marginBottom:'10px'}}>
                    <ControlGroup>
                    <FormGroup><InputGroup placeholder="Placeholder text" value={bData.businessName?bData.businessName:''} disabled={true} /></FormGroup>
                    <HTMLSelect
                    style={{minWidth:'149px'}}
                        data-key={index}
                        // className={hideSites ? Hidden : undefined}
                        onChange={this.handleSite}
                        options={
                            [{ label: "all", value: "all" },...bData.sites]
                        }
                        value={bData.sitesId || 'all'}
                    />
                    </ControlGroup>
                    <br />
                    <ControlGroup style={{justifyContent:'center'}}>
                        <div id="dashboard-daily-cnt-style">
                        <Tag style={tagStyle} large={true}>Daily Count</Tag>
                        <Tag style={tagNumStyle} large={true} minimal={true}>
                            {bData.dailyCheckIns}
                        </Tag>
                        </div>
                        &nbsp;
                        <div id="dashboard-daily-kisk-style">
                        <Tag style={tagStyle} large={true}>Daily Kiosk Opt-Outs</Tag>
                        <Tag style={tagNumStyle} large={true} minimal={true}>
                            {bData.dailyOptOuts}
                        </Tag>
                        </div>
                    </ControlGroup>
                </div>
            :'')):
            <Button style={{marginTop:'30%'}} large={true} intent={this.state.progress==100 ? 'success':"primary"}>Loading...  {this.state.progress}%</Button>
            }
  
  
{this.state.init ?  <ControlGroup>
  {this.get_total_daily_count() ?  <div id="dashboard-daily-cnt-style">
                        <Tag style={TagStyletotal} large={true}>Total Daily Count</Tag>
                        <Tag style={tagNumStyle} large={true} minimal={true}>
                        {this.get_total_daily_count() ? this.get_total_daily_count()  : 0  }
                        </Tag>
                        </div> :''}
                        &nbsp;
                        {this.get_total() ? <div id="dashboard-daily-kisk-style">
                        <Tag style={TagStyletotal} large={true}>Total Daily Kiosk Opt-Outs</Tag>
                        <Tag style={tagNumStyle} large={true} minimal={true}>
                        {this.get_total() ? this.get_total()  : 0}
                        </Tag>
                        </div> :''}
                    </ControlGroup>:''}
  
            </div>
        )    
    }
  
  
    private handleSite = async (currentTarget: React.ChangeEvent<HTMLSelectElement>) => {
        const sId = currentTarget.target.value;
        const index:any = currentTarget.target.getAttribute('data-key');
        if(index != null){
            const dailyCheckIns = await this.getDailyCount(this.state.businessData[index].businessId, sId);
            const dailyOptOuts = await this.watchOptOutsCount(this.state.businessData[index].businessId, sId);
            const businessData = Object.assign(this.state.businessData);
            businessData[index].sitesId = sId;
            businessData[index].dailyCheckIns = dailyCheckIns;
            businessData[index].dailyOptOuts = dailyOptOuts;
            this.setState({ businessData });
        }
      };
  
      private async getDailyCount(businessId:any,siteId:any){
        let dailyCount = 0;  
        const today = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
        dd = "0" + dd;
        }
  
        if (mm < 10) {
        mm = "0" + mm;
        }
        const date = mm + "-" + dd + "-" + yyyy;    
  
        if (siteId && siteId !== "all") {

           await getDocs(query(collection(firebase,'checkIns'),where('businessId','==',businessId),where('siteId','==',siteId),where('timestamp','>=',new Date(date))))
            .then(snap => {
              substractRead(snap.size)
                dailyCount = snap.size;
            });
        } else if (siteId === "all") {
           await getDocs(query(collection(firebase,'checkIns'),where('businessId','==',businessId),where('timestamp','>=',new Date(date))))
            .then(snap => {
              substractRead(snap.size)
                dailyCount = snap.size;
            });
        }
        return dailyCount;
  
    }
    
    private watchOptOutsCount = async (businessId:any, siteId:any) => {
        let dailyOptOuts = 0;
        if (businessId === "VQMWUEYKKs4rSpAzQ7P2") {
          console.log("WAP...");
          dailyOptOuts = await this.watchWrenchAPartOptOutsCount(businessId, siteId);
          return dailyOptOuts;
        }
        const now = new Date();
        const lastMidnight = now.setHours(0, 0, 0, 0);
        console.log(lastMidnight);
    
        // then convert those to UTC0 to pass on in your query to firestore
        // const lastMidNightUTC = new Date(lastMidnight + now.getTimezoneOffset() * 60000).toString();
        const today = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
    
        if (mm < 10) {
          mm = "0" + mm;
        }
        const date = mm + "-" + dd + "-" + yyyy;


        progress++
        this.setState({progress:progress})
        if(store.getState().BusinessesReducer.Businesses.length==progress){
          this.setState({progress:100})
          setTimeout(() => {
            this.setState({init:true})
          }, 1000);

        }
        
        console.log("DAILY OPT OUTS");
        if (siteId && siteId !== "all") {
      await  getDocs(query(collection(firebase,'checkIns'),where('businessId','==',businessId),where('siteId','==',siteId),where("userId", "==", "opt-out"),where('timestamp','>=',new Date(date))))
            .then((snap) => {
              substractRead(snap.size)
              dailyOptOuts = snap.size;
            });
        } else if (siteId === "all") {
          console.log("finding size...");
        await  getDocs(query(collection(firebase,'checkIns'),where('businessId','==',businessId),where("userId", "==", "opt-out"),where('timestamp','>=',new Date(date))))
            .then((snap) => {
              substractRead(snap.size)
              dailyOptOuts = snap.size;
            });
        }


        return dailyOptOuts;
      };
        
      private watchWrenchAPartOptOutsCount = async (businessId:any, siteId:any) => {
        let dailyOptOuts = 0;
        const now = new Date();
        const lastMidnight = now.setHours(0, 0, 0, 0);
        console.log(lastMidnight);
    
        // then convert those to UTC0 to pass on in your query to firestore
        // const lastMidNightUTC = new Date(lastMidnight + now.getTimezoneOffset() * 60000).toString();
        const today = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1;
        const yyyy = today.getFullYear();
        if (dd < 10) {
          dd = "0" + dd;
        }
    
        if (mm < 10) {
          mm = "0" + mm;
        }
        const date = mm + "-" + dd + "-" + yyyy;
    
        if (siteId && siteId !== "all") {
        await  getDocs(query(collection(firebase,'checkIns'),where('businessId','==',businessId),where("siteId", "==", siteId),where("optin", "==", false),where('timestamp','>=',new Date(date))))
            .then((snap) => {
              substractRead(snap.size)
              dailyOptOuts = snap.size;
            });
        } else if (siteId === "all") {
          console.log("finding size...");
        await  getDocs(query(collection(firebase,'checkIns'),where('businessId','==',businessId),where("optin", "==", false),where('timestamp','>=',new Date(date))))
            .then((snap) => {
              substractRead(snap.size)
              dailyOptOuts = snap.size;
            });
        }
        return dailyOptOuts;
      };
  
    private async getSites(businessId:any){
        const siteDta : Sites[] = [];
        
        await  getDocs(query(collection(firebase,`businesses/${businessId}/sites`)))      
        .then((sites) => {
            substractRead(sites.size)
            sites.forEach((sdoc) => {
              try {
             const auth = this.props.authState
              if(auth.accountData.permissions.sites!==undefined && auth.accountData.permissions.sites.length>0 && auth.accountData.permissions.sites.includes(sdoc.id)){
                siteDta.push({ label: sdoc.data().path, value: sdoc.id });
              }else if(auth.accountData.permissions.sites===undefined ||auth.accountData.permissions.sites.length==0){
                siteDta.push({ label: sdoc.data().path, value: sdoc.id });
              }
                   
            } catch (error) {

              siteDta.push({ label: sdoc.data().path, value: sdoc.id });
            }
            })
        });
        return siteDta;
    }
  
    private async initDashboard() {
      try {
        
   
        let allBusinessData:any =store.getState().BusinessesReducer.Businesses;
        // await  getDocs(query(collection(firebase,`businesses`)))    
        // .then((snap) =>{
        //     const filterBus : any = [];
        //     snap.forEach(doc=>{
        //         if(!doc.data().isArchive){
        //             filterBus.push(doc.data());
        //         }
        //     });
        //     const sortedBus = filterBus
        //     allBusinessData = sortedBus;
  
        // });
        
      if(allBusinessData.length>0){

        
      await  allBusinessData.forEach(async(doc:any) => {
            
            const siteDta  = await this.getSites(doc.businessId);
            
            const dailyCount = await this.getDailyCount(doc.businessId,'all');
            const dailyOptOut = await this.watchOptOutsCount(doc.businessId,'all');
  
            const businessData = {
                businessId:doc.businessId,
                businessName:doc.path,
                dailyCheckIns:dailyCount,
                dailyOptOuts:dailyOptOut,
                sites:siteDta,
                sitesId:"all"
            }
            this.setState(prevState => ({
                businessData: [...prevState.businessData, businessData]
            }));
  
        });

      }
    } catch (error) {
       console.log(error);
        
    }
    }
  
  }
  
  export default withAuthUser(Dashboard);