import React , { useEffect, useState }  from 'react';
import {useIsAuthenticated, useSignIn} from 'react-auth-kit';
import { useNavigate } from 'react-router-dom';
import '../assets/css/style.css'
import { Button, Card, Elevation, InputGroup, Label, Toast } from '@blueprintjs/core';
import { UsersCollection, auth, firebase } from '../global/firebase';
import {  createUserWithEmailAndPassword,   sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDocs, query, setDoc, where } from 'firebase/firestore';
import {Users} from '../interfaces/users'
export const Login = ()=>{
    let navigate = useNavigate();
    const signIn = useSignIn()
    const isAuthenticated = useIsAuthenticated()
    const [page, setpage] = useState('login');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [cpassword, setcPassword] = useState('');
    const [emailDisable, setemailDisable] = useState(false);
    const [passwordDisable, setpasswordDisable] = useState(false);
    const [cpasswordDisable, setcpasswordDisable] = useState(false);
    const [signInDisable, setsignInDisable] = useState(false);
    const [signUpDisable, setsignUpDisable] = useState(false);
    const [ForgotDisable, setForgotDisable] = useState(false);
    const [ErrorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if(isAuthenticated()){                  
            navigate('/home')
        }
        });
        
        const submitSignIn=()=>{

        if(password!=='' && email!=='' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        setErrorMessage('')   
        EnableDisableInputs(true)
        signInWithEmailAndPassword(auth, email, password)
            .then(async(userCredential) => {
            
                    // Signed in 
                    const user = userCredential.user;   
                    const q = query(UsersCollection, where("userId", "==", user.uid));
                    const querySnapshot = await getDocs(q);
                        querySnapshot.forEach((doc) => {
                        console.log(doc.data(),'login');

            if(signIn(
                    {
                        token: user.uid,
                        expiresIn:24894848584,
                        tokenType: "Bearer",
                        authState: doc.data(),
                        // refreshToken: '0',                    // Only if you are using refreshToken feature
                        // refreshTokenExpireIn:      // Only if you are using refreshToken feature
                    }
                )){
                                     
                    navigate('/home')
                    window.location.href= ''
            }else {   
                    setErrorMessage('Server error.Please reload page and try again.');
                    EnableDisableInputs(false)
            }  

            });
            })
            .catch((error) => {
                    // const errorCode = error.code;
                    // const errorMessage = error.message;
   
                    handleSignInError(error.message)                   
                    EnableDisableInputs(false)
                
                });
        }else{
            setErrorMessage('Incorrect email or password.'); 
        }        
            }

    const submitSignUp= async()=>{
            if(password!=='' && cpassword!=='' && email!=='' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && password===cpassword){                
                setErrorMessage('')   
                EnableDisableInputs(true)


 
                const result = createUserWithEmailAndPassword(auth,
                    email,
                    password
                  ).then(async(r)=>{
                    const accountData:Users.AccountData= {
                        displayName:r.user.displayName,
                        email: r.user.email,
                        permissions: {
                          businesses: [],
                          ismask:false,
                          role: "user",
                          sites: [],
                        },
                        phoneNumber:r.user.phoneNumber,
                        photoURL:r.user.photoURL,
                        uid:r.user.uid || "unknown",
                        providerId:r.user.providerId,
                      };
                    const data:any=  {
                        accountData: {
                          ...accountData,
                          uid: r.user.uid,
                        },
                        email: email || null,
                        firebaseData: {
                          displayName: r.user.displayName,
                          email: r.user.email,
                          emailVerified: r.user.emailVerified,
                          isAnonymous: r.user.isAnonymous,
                          metadata: {
                            creationTime:new Date(),
                            lastSignInTime:new Date(),
                          },
                          phoneNumber:r.user.phoneNumber,
                          photoURL:r.user.photoURL,
                          uid:r.user.uid,
                        },
                        hasAccount: true,
                        language:'eng',
                        mobile:r.user.phoneNumber,
                        name: {
                          displayName:r.user.displayName,
                          firstname:  null,
                          lastname:  null,
                        },
                        secretMobile:null,
                        uid:r.user.uid,
                        userId:r.user.uid,
                      };
  
                   await setDoc(doc(firebase, "users", r.user.uid), data).then(async()=>{                    
                    if(signIn(
                        {
                            token: r.user.uid,
                            expiresIn:24894848584,
                            tokenType: "Bearer",
                            authState: data,
                            // refreshToken: '0',                    // Only if you are using refreshToken feature
                            // refreshTokenExpireIn:      // Only if you are using refreshToken feature
                        }
                    )){                        
                        navigate('/home')
                   }else {                           
                        setErrorMessage('Server error.Please reload page and try again.');
                        EnableDisableInputs(false)
                   }  
                   }).catch(()=>{                        
                    setErrorMessage('Something goes wrong.Please refresh the browser.');
                        EnableDisableInputs(false)
                   });

                  }).catch((e)=>{
                    handleCreateAccountError(e.message);
                  })
         
            }else{
                setErrorMessage('Incorrect email or password.And confirm-password'); 
            }            
        }


    const submitForgot=async()=>{
if(email!=='' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) ){
    EnableDisableInputs(true)
    await sendPasswordResetEmail(auth,email).then((r)=>{
        setErrorMessage('A password reset link has been sent. Please check your email for further instructions.');
        EnableDisableInputs(false)
    }).catch((e)=>{
        console.log(e.message);
        EnableDisableInputs(false)
        if(e.message==='Firebase: Error (auth/user-not-found).'){
         setErrorMessage('User is not registred.Please enter valid email.');
        }else{
        setErrorMessage('Somthing goes wrong.Please refresh the browser.');
        }
    });
}else{
    setErrorMessage('Please enter valid email address.');
}
    }
        

   const handleCreateAccountError = (code: string) => {
            if (code === 'Firebase: Error (auth/email-already-in-use).') {
                setErrorMessage('This email account is already in use. See if you can remember the password you used.');              
            }
            if (code === 'Firebase: Error (auth/weak-password).') {
            setErrorMessage("Your password isn't strong enough. Add some characters!");   
            }
        };


  const handleSignInError = (code: string) => {           
        console.log(code);
        
            if (code === 'Firebase: Error (auth/user-not-found).') {
                setErrorMessage("Looks like you don't have an account yet. Just confirm your password and we'll sign you right up!");   
            }        
            if (code === 'Firebase: Error (auth/invalid-email).') {
                setErrorMessage("Your email doesn't look valid. Please try again.");   
            }        
            if (code === 'Firebase: Error (auth/user-disabled).') {
                setErrorMessage("Sorry, your account has been disabled. Contact support to see if we can fix the situation.");   
            }        
            if (code === 'Firebase: Error (auth/wrong-password).') {
                setErrorMessage("You can reset your password with the 'reset password' button below.");   
            }        
            return code;
          };
        
const EnableDisableInputs=(bool:any)=>{
    setpasswordDisable(bool)
    setsignUpDisable(bool)
    setForgotDisable(bool)
    setcpasswordDisable(bool)
    setemailDisable(bool)
    setsignInDisable(bool)
}

    return(
        <div className='loginPage' style={{marginTop:'-51px'}}>
            <div className='loginCard'>
<form>
            <Card interactive={false} elevation={Elevation.FOUR}>
            <img src="https://www.texnrewards.com/wp-content/uploads/2023/04/TexnRewards_mobile_connection-01.png" className="loginimg" width="200" height="150"/>
           
           {ErrorMessage ? <Toast icon='error' intent='danger' timeout={5000}  onDismiss={()=>setErrorMessage('')} message={ErrorMessage} /> :''}

            <Label  className='left'>Email</Label> 
           <InputGroup
                    disabled={emailDisable}
                    large={false}
                    name='email'
                    placeholder="Enter email"
                    defaultValue={email}
                    readOnly={false}     
                    onChange={(e)=>setEmail(e.target.value)}               
                />
            {page==='register' || page==='login' ?
            <span><Label className='left'>Password</Label>    
            <InputGroup
                    disabled={passwordDisable}
                    large={false}
                    type='password'
                    placeholder="Enter password"
                    readOnly={false}
                     name='password'
                    defaultValue={password}
                    onChange={(e)=>setPassword(e.target.value)} 
                /> </span>
                :''}

              {page==='register' ?
              <span> <Label className='left'>Confirm Password</Label>   
              <InputGroup
                    disabled={cpasswordDisable}
                    large={false}
                       type='password'
                    placeholder="Enter confirm password"
                    readOnly={false}
                       name='cpassword'
                   
                    defaultValue={cpassword}
                    onChange={(e)=>setcPassword(e.target.value)} 
                /></span>
                 :''}
<br/>
       {page==='login' ?  
       <Label className='left1'> 
           <Button className='m-3' intent="primary" text="Sign-In" disabled={signInDisable} loading={signInDisable}   onClick={()=>submitSignIn()} />  
           <Button className='m-3'intent="primary" text="create account" onClick={()=>setpage('register')} disabled={signUpDisable} />
           <Button className='m-3'  intent="primary" text="forgot password"  onClick={()=>{setpage('forgot')}}  disabled={ForgotDisable}/>
         </Label>
         : page==='register' ? 
         <Label className='left1'> 
         <Button className='m-3'  intent="primary" text="go back" disabled={signUpDisable}  onClick={()=>setpage('login')} />
         <Button className='m-3' intent="primary" text="create account" onClick={()=>submitSignUp()} loading={signUpDisable} disabled={signUpDisable} />
       </Label>
       : page==='forgot' ? 
       <Label className='left1'> 
       <span>&nbsp;</span>
           <Button className='m-3'  intent="primary" text="go back" disabled={ForgotDisable}  onClick={()=>setpage('login')} />
       <Button className='m-3' intent="primary" text="send reset email" loading={ForgotDisable}  onClick={()=>submitForgot()}  disabled={ForgotDisable}/>
     </Label>
       :''}
       
            </Card>
            </form>
            </div>
        </div>
    )


    
}



