import { ControlGroup, Tag, Tooltip } from '@blueprintjs/core';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface ICopyLinkProps {
  url: string;
}

export const CopyLink =({ url }: ICopyLinkProps) => (
  <Tooltip content="Click to copy">
    <ControlGroup style={{ marginBottom: '1rem' }}>
      <Tag>link</Tag>
      <CopyToClipboard text={url}>
        <Tag
          interactive={true}
          large={true}
          minimal={true}
          rightIcon="duplicate">
          {url}
        </Tag>
      </CopyToClipboard>
    </ControlGroup>
  </Tooltip>
);