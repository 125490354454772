
import React, { useEffect, useState }  from 'react';
import '../assets/css/justIn.css'
import { Button, Classes, Dialog, DialogBody, H2, H3, H4, Icon, InputGroup, Spinner } from '@blueprintjs/core';
import { MakeModelYearInput } from '../components/justInComponents/makeModelYearInput';
import { strings } from '../components/justInComponents/strings';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { firebase } from '../global/firebase';
import { VehiclePicksPage } from '../components/justInComponents/VehiclePicksPage';
import { fontWeight } from 'html2canvas/dist/types/css/property-descriptors/font-weight';
import parse from 'html-react-parser'
export const JustIn=()=>{
    const [lang,setlang]=useState(strings.eng)
    const [langstr,setlangstr]=useState('eng')
    // vip
    const [spanishVipClubHoverText,setspanishVipClubHoverText]=useState('');
    const [pageloader,setpageloader]=useState(null as any);
    const [spanishLocation,setspanishLocation]=useState('');
    const [englishVipClubHoverText,setenglishVipClubHoverText]=useState('');
    const [enableVipClub,setenableVipClub]=useState('');
    const [englishKeyword,setenglishKeyword]=useState('');
    const [englishVipClubCheckboxText,setenglishVipClubCheckboxText]=useState('');
    const [spanishKeyword,setspanishKeyword]=useState('');
    const [englishLocation,setenglishLocation]=useState('');
    const [spanishVipClubCheckboxText,setspanishVipClubCheckboxText]=useState('');
    const [isVipClubS,setisVipClubS]=useState(false);
    const [isVipClub,setisVipClub]=useState(false);

    const [businessId,setBusinessId]=useState(null as any)
    const [business,setBusiness]=useState([] as any)
    const [siteId,setSiteId]=useState(null as any)
    const [busienssPath,setBusinessPath]=useState(null as any)
    const [businessName,setbusinessName]=useState(null as any)
    const [siteName,setsiteName]=useState(null as any)
    const [sitePath,setSitePath]=useState(null as any)
    const [mobile,setMobilex]=useState('')
    const [homeButotn,sethomeButotn]=useState(false)
    const [logo,setLogo]=useState(null as any)
    const [site,setSite]=useState([] as any)
    const [feedType,setfeedType]=useState('');
    const [vehiclePicksPage,setvehiclePicksPage]=useState(false)
    const [theme,settheme]=useState([] as any);
    const [showLogo,setshowLogo]=useState(true)
    const [heightForDesktop,setheightForDesktop]=useState('')
    const [widthForDesktop,setwidthForDesktop]=useState('')
    const [kioskUiLogoBorderColor,setkioskUiLogoBorderColor]=useState('')
    const [homeBtnUrl,sethomeBtnUrl]=useState('')
    const [homeButtonText,sethomeButtonText]=useState('Home')
    const [homeButtonTextSpanish,sethomeButtonTextSpanish]=useState('Hogar')
    const [homeButtonBgColor,sethomeButtonBgColor]=useState('rgb(13, 71, 240)')
    const [homeButtonColor,sethomeButtonColor]=useState('#fff')
    const [Infomodal,setInfomodal]=useState(false)
    const [enableExpanolBtn,setenableExpanolBtn]=useState(false);
    const [howitworksImg,sethowitworksImg]=useState('');
    const [mob1Disable,setmob1Disable]=useState(true)

    const [howitworksColor,sethowitworksColor]=useState('');
    const [howitworksBgColor,sethowitworksBgColor]=useState('');

    const [ReturnFontSize,setReturnFontSize]=useState('28px')
    const [ReturnFontSizeM,setReturnFontSizeM]=useState('28px')
    const [ReturnFontType,setReturnFontType]=useState('sans-serif')
    const [ReturnpageText,setReturnpageText]=useState('Returning to this page?  ')
    const [ReturnpageTextSp,setReturnpageTextSp]=useState('Ingrese su número de teléfono móvil ')
    const [returnTextString,setreturnTextString]=useState(ReturnpageText)

    const [accessdashboardurnFontSize,setaccessdashboardurnFontSize]=useState('22px')
    const [accessdashboardurnFontSizeM,setaccessdashboardurnFontSizeM]=useState('22px')
    const [accessdashboardurnFontType,setaccessdashboardurnFontType]=useState('sans-serif')
    const [accessdashboardurnpageText,setaccessdashboardurnpageText]=useState('Enter your mobile number to access Dashboard')
    const [accessdashboardurnpageTextSp,setaccessdashboardurnpageTextSp]=useState('Ingrese su número de teléfono móvil ')
    const [accessdashboardurnTextString,setaccessdashboardurnTextString]=useState(accessdashboardurnpageText)

    const [descSize,setdescSize]=useState('18px')
    const [descSizeM,setdescSizeM]=useState('18px')
    const [descType,setdescType]=useState('sans-serif')
    const [descText,setdescText]=useState('Get Alerts for newly-added vehicles,')
    const [descTextSpanish,setdescTextSpanish]=useState("Regístrese para recibir alertas de texto de Fresh Stock en   ")
    const [descTextTextString,setdescTextTextString]=useState(descText)

    const [descTextSecondline,setdescTextSecondline]=useState("Straight to your phone! We'll send you a text when it arrives.  ")
    const [descTextSpanishSecondline,setdescTextSpanishSecondline]=useState('Directo a su teléfono! Le enviaremos un mensaje de texto cuando llegue  ')
    const [descTextSecondlineTextString,setdescTextSecondlineTextString]=useState(descTextSecondline)


    const [tncSize,settncSize]=useState('19px')
    const [tncSizeM,settncSizeM]=useState('19px')
    const [tncType,settncType]=useState('sans-serif')
    const [tncText,settncText]=useState('By selecting Next, you are signing up to receive automated text alerts and marketing calls from an autodialer system or live person. Consent to receive automated text alerts not required for purchase. Upon completion of this form, you will receive a text message with instructions to confirm your opt-in. Approx 31 msg/mo & Data Rates May Apply. Reply HELP for HELP, STOP ALL to cancel.')
    const [tncTextSpanish,settncTextSpanish]=useState('Al seleccionar Siguiente, se registra para recibir alertas de texto automatizadas y llamadas de marketing de un sistema de marcación automática o una persona en vivo. No se requiere consentimiento para recibir alertas de texto automáticas para la compra. Al completar este formulario, recibirá un mensaje de texto con instrucciones para confirmar su suscripción. Se pueden aplicar hasta 31 msg / mes y tarifas de datos. Responda HELP para HELP, STOP ALL para cancelar.')
    const [tncTextSpanishTextString,settncTextSpanishTextString]=useState(tncText)


    const [titleSize,settitleSize]=useState('28px')
    const [titleSizeM,settitleSizeM]=useState('28px')
    const [titleType,settitleType]=useState('sans-serif')
    const [titleText,settitleText]=useState('Sign up for Just In vehicle alerts at ')
    const [titleTextSpanish,settitleTextSpanish]=useState('Regístrese para recibir alertas de texto de Fresh Stock en ')
    const [titleTextTextString,settitleTextTextString]=useState(titleText)


    const [screenSize,setScreenSize]=useState(window.innerWidth)
useEffect(()=>{
 try {

   const param = window.location.pathname;
   const splitPath:any = param.split('/');

    if(splitPath[2]!==undefined && splitPath[2]!==null){
        setBusinessPath(splitPath[2])
    }
    if(splitPath[3]!==undefined && splitPath[3]!==null){
        setSitePath(splitPath[3])
    }
    if(splitPath[4]!==undefined && splitPath[4]!==null && splitPath[4]!==''){
        
        setMobilex(splitPath[4])
        setvehiclePicksPage(true)
    }
   if(businessId===null){
    getSetBusiness()    

    if ( window.location !== window.parent.location ){
        if(busienssPath==='garys-u-pull-it' || busienssPath==="ctv-pik-a-part" || busienssPath ==="horseheads-pick-a-part" || busienssPath==="upullusave"){
             setshowLogo(false)   
        }
     } 
   }
            
 } catch (error) {
    console.log(error);        
 }



});

const getSetBusiness=async()=>{

    try {
        setpageloader(true)
     const response =  await getDocs(query(collection(firebase,'businesses'),where('path','==',busienssPath)));
     if(!response.empty){
        const data =response.docs[0].data()
        if(data.feedType!==undefined){
        setfeedType(data.feedType)
        }
        setBusinessId(response.docs[0].id);
        setBusiness(response.docs[0])
        setbusinessName(data.businessName)       
        const responseSite =  await getDocs(query(collection(firebase,`businesses/${response.docs[0].id}/sites`)));
        
        if(!responseSite.empty){           
            settheme(responseSite.docs[0].data());           
            try {
                const sitedata = responseSite.docs[0].data()
                vipcheckbox(response,sitedata);
                try {
                
                    if (sitedata.theme && sitedata.theme.logo) {
                        setLogo(sitedata.theme.logo)
                    }
                        
                } catch (error) {
                   console.log(error);
                        
                }
    
                
        if(sitedata.howitworksImg!==undefined && sitedata.howitworksImg!==''){ sethowitworksImg(sitedata.howitworksImg);  }

        if(sitedata.howitworksColor!==undefined && sitedata.howitworksColor!==''){ sethowitworksColor(sitedata.howitworksColor) }
        if(sitedata.howitworksBgColor!==undefined && sitedata.howitworksBgColor!==''){ sethowitworksBgColor(sitedata.howitworksBgColor) }

        if(sitedata.enableExpanolBtn!==undefined && sitedata.enableExpanolBtn===true){ setenableExpanolBtn(sitedata.enableExpanolBtn)  }
                if(sitedata.homeBtnEnable!==undefined){ sethomeButotn(sitedata.homeBtnEnable); }
                if(sitedata.homeBtnUrl!==undefined){ sethomeBtnUrl(sitedata.homeBtnUrl); }
                if(sitedata.homeButtonText!==undefined && sitedata.homeButtonText!==null && sitedata.homeButtonText!==''){ sethomeButtonText(sitedata.homeButtonText); }
                if(sitedata.homeButtonTextSpanish!==undefined && sitedata.homeButtonTextSpanish!==null &&  sitedata.homeButtonTextSpanish!=='' ){ sethomeButtonTextSpanish(sitedata.homeButtonTextSpanish); }
                if(sitedata.homeButtonBgColor!==undefined && sitedata.homeButtonBgColor!==null && sitedata.homeButtonBgColor!==''){ sethomeButtonBgColor(sitedata.homeButtonBgColor); }
                if(sitedata.homeButtonColor!==undefined && sitedata.homeButtonColor!==null && sitedata.homeButtonColor!==''){ sethomeButtonColor(sitedata.homeButtonColor); }
            if(sitedata.heightForDesktop!==undefined){ setheightForDesktop(sitedata.heightForDesktop); }
            if(sitedata.widthForDesktop!==undefined){ setwidthForDesktop(sitedata.widthForDesktop); }
            if(sitedata.kioskUiLogoBorderEnable!==undefined && sitedata.kioskUiLogoBorderEnable==true){ setkioskUiLogoBorderColor(sitedata.kioskUiLogoBorderColor);          }
            setLogo(data.theme.logo)
            if(sitedata.retToPageSize!==undefined){  setReturnFontSize(sitedata.retToPageSize)   }
            if(sitedata.retToPageSizeM!==undefined){  setReturnFontSizeM(sitedata.retToPageSizeM)   }
            if(sitedata.retToPageText!==undefined &&  sitedata.retToPageText!==''){  setReturnpageText(sitedata.retToPageText); setreturnTextString(sitedata.retToPageText)   }
            if(sitedata.retToPageTextSpanish!==undefined && sitedata.retToPageTextSpanish!==''){  setReturnpageTextSp(sitedata.retToPageTextSpanish)   }            
            if(sitedata.retToPageType!==undefined){  setReturnFontType(sitedata.retToPageType)   }

            if(sitedata.accessDashboardSize!==undefined){  setaccessdashboardurnFontSize(sitedata.accessDashboardSize)   }
            if(sitedata.accessDashboardSizeM!==undefined){  setaccessdashboardurnFontSizeM(sitedata.accessDashboardSizeM)   }
            if(sitedata.accessDashboardText!==undefined &&  sitedata.accessDashboardText!==''){  setaccessdashboardurnpageText(sitedata.accessDashboardText); setaccessdashboardurnTextString(sitedata.accessDashboardText);   }
            if(sitedata.accessDashboardTextSpanish!==undefined && sitedata.accessDashboardTextSpanish!==''){  setaccessdashboardurnpageTextSp(sitedata.accessDashboardTextSpanish)   }           
            if(sitedata.accessDashboardType!==undefined){  setaccessdashboardurnFontType(sitedata.accessDashboardType)   }
                      

            if(sitedata.descSize!==undefined){  setdescSize(sitedata.descSize)   }
            if(sitedata.descSizeM!==undefined){  setdescSizeM(sitedata.descSizeM)   }
            if(sitedata.descText!==undefined &&  sitedata.descText!==''){  setdescText(sitedata.descText); setdescTextTextString(sitedata.descText);   }
            if(sitedata.descTextSpanish!==undefined && sitedata.descTextSpanish!==''){  setdescTextSpanish(sitedata.descTextSpanish)   }           
            if(sitedata.descType!==undefined){  setdescType(sitedata.descType)   }
            
            if(sitedata.descTextSecondline!==undefined &&  sitedata.descTextSecondline!==''){  setdescTextSecondline(sitedata.descTextSecondline); setdescTextSecondlineTextString(sitedata.descTextSecondline);   }
            if(sitedata.descTextSpanishSecondline!==undefined && sitedata.descTextSpanishSecondline!==''){  setdescTextSpanishSecondline(sitedata.descTextSpanishSecondline)   } 

            if(sitedata.tncSize!==undefined && sitedata.tncSize!==''){  settncSize(sitedata.tncSize)   }
            if(sitedata.tncSizeM!==undefined && sitedata.tncSizeM!==''){  settncSizeM(sitedata.tncSizeM)   }
            if(sitedata.tncText!==undefined &&  sitedata.tncText!==''){  settncText(sitedata.tncText); settncTextSpanishTextString(sitedata.tncText)   }
            if(sitedata.tncTextSpanish!==undefined && sitedata.tncTextSpanish!==''){  settncTextSpanish(sitedata.tncTextSpanish)   }            
            if(sitedata.tncType!==undefined){  settncType(sitedata.tncType)   }

            if(sitedata.tncSize!==undefined && sitedata.tncSize!==''){  settncSize(sitedata.tncSize)   }
            if(sitedata.tncSizeM!==undefined && sitedata.tncSizeM!==''){  settncSizeM(sitedata.tncSizeM)   }
            if(sitedata.tncText!==undefined &&  sitedata.tncText!==''){  settncText(sitedata.tncText); settncTextSpanishTextString(sitedata.tncText)   }
            if(sitedata.tncTextSpanish!==undefined && sitedata.tncTextSpanish!==''){  settncTextSpanish(sitedata.tncTextSpanish)   }            
            if(sitedata.tncType!==undefined){  settncType(sitedata.tncType)   }

            if(sitedata.titleSize!==undefined){  settitleSize(sitedata.titleSize)   }
            if(sitedata.titleSizeM!==undefined){  settitleSizeM(sitedata.titleSizeM)   }
            if(sitedata.titleText!==undefined &&  sitedata.titleText!==''){  settitleText(sitedata.titleText); settitleTextTextString(sitedata.titleText)   }
            if(sitedata.titleTextSpanish!==undefined && sitedata.titleTextSpanish!==''){  settitleTextSpanish(sitedata.titleTextSpanish)   }            
            if(sitedata.titleType!==undefined){  settitleType(sitedata.titleType)   }

        
        } catch (error) {
                console.log(error);
                
        }
        } 

     }

    if(sitePath!=='-' && sitePath!==null && sitePath!==undefined && sitePath!==''){
        const responseSite =  await getDocs(query(collection(firebase,`businesses/${response.docs[0].id}/sites`),where('path','==',sitePath)));
        if(!responseSite.empty){
            const sitedata=  responseSite.docs[0].data()
            vipcheckbox(response,sitedata);
            setSiteId(responseSite.docs[0].id);
            setSite(responseSite.docs[0])       
            try {

                try {
                
                if (sitedata.theme && sitedata.theme.logo) {
                    setLogo(sitedata.theme.logo)
                }
                    
            } catch (error) {
               console.log(error);
                    
            }

                if(sitedata.howitworksColor!==undefined && sitedata.howitworksColor!==''){ sethowitworksColor(sitedata.howitworksColor) }
                if(sitedata.howitworksBgColor!==undefined && sitedata.howitworksBgColor!==''){ sethowitworksBgColor(sitedata.howitworksBgColor) }
                
             if(sitedata.enableExpanolBtn!==undefined && sitedata.enableExpanolBtn===true){  setenableExpanolBtn(sitedata.enableExpanolBtn)  }
             if(sitedata.howitworksImg!==undefined && sitedata.howitworksImg!==''){   sethowitworksImg(sitedata.howitworksImg); }
            if(sitedata.homeBtnEnable!==undefined){ sethomeButotn(sitedata.homeBtnEnable); }
            if(sitedata.homeBtnUrl!==undefined){ sethomeBtnUrl(sitedata.homeBtnUrl); }
            if(sitedata.homeButtonText!==undefined && sitedata.homeButtonText!==null && sitedata.homeButtonText!==''){ sethomeButtonText(sitedata.homeButtonText); }
            if(sitedata.homeButtonTextSpanish!==undefined && sitedata.homeButtonTextSpanish!==null &&  sitedata.homeButtonTextSpanish!=='' ){ sethomeButtonTextSpanish(sitedata.homeButtonTextSpanish); }
            if(sitedata.homeButtonBgColor!==undefined && sitedata.homeButtonBgColor!==null && sitedata.homeButtonBgColor!==''){ sethomeButtonBgColor(sitedata.homeButtonBgColor); }
            if(sitedata.homeButtonColor!==undefined && sitedata.homeButtonColor!==null && sitedata.homeButtonColor!==''){ sethomeButtonColor(sitedata.homeButtonColor); }
            if(sitedata.heightForDesktop!==undefined && sitedata.heightForDesktop!==''){ setheightForDesktop(sitedata.heightForDesktop); }else{  setheightForDesktop('130px');  }
            if(sitedata.widthForDesktop!==undefined && sitedata.widthForDesktop!==''){ setwidthForDesktop(sitedata.widthForDesktop); }else{  setwidthForDesktop('280px');  }
            if(sitedata.kioskUiLogoBorderEnable!==undefined && sitedata.kioskUiLogoBorderEnable==true){ setkioskUiLogoBorderColor(sitedata.kioskUiLogoBorderColor); }
                   
            if(sitedata.retToPageSize!==undefined){  setReturnFontSize(sitedata.retToPageSize)   }
            if(sitedata.retToPageSizeM!==undefined){  setReturnFontSizeM(sitedata.retToPageSizeM)   }
            if(sitedata.retToPageText!==undefined &&  sitedata.retToPageText!==''){  setReturnpageText(sitedata.retToPageText); setreturnTextString(sitedata.retToPageText)      }
            if(sitedata.retToPageTextSpanish!==undefined && sitedata.retToPageTextSpanish!==''){  setReturnpageTextSp(sitedata.retToPageTextSpanish)   }           
            if(sitedata.retToPageType!==undefined){  setReturnFontType(sitedata.retToPageType)   }

            if(sitedata.accessDashboardSize!==undefined){  setaccessdashboardurnFontSize(sitedata.accessDashboardSize)   }
            if(sitedata.accessDashboardSizeM!==undefined){  setaccessdashboardurnFontSizeM(sitedata.accessDashboardSizeM)   }
            if(sitedata.accessDashboardText!==undefined &&  sitedata.accessDashboardText!==''){  setaccessdashboardurnpageText(sitedata.accessDashboardText); setaccessdashboardurnTextString(sitedata.accessDashboardText)  }
            if(sitedata.accessDashboardTextSpanish!==undefined && sitedata.accessDashboardTextSpanish!==''){  setaccessdashboardurnpageTextSp(sitedata.accessDashboardTextSpanish)   }           
            if(sitedata.accessDashboardType!==undefined){  setaccessdashboardurnFontType(sitedata.accessDashboardType)   }
                        

            if(sitedata.descSize!==undefined){  setdescSize(sitedata.descSize)   }
            if(sitedata.descSizeM!==undefined){  setdescSizeM(sitedata.descSizeM)   }
            if(sitedata.descText!==undefined &&  sitedata.descText!==''){  setdescText(sitedata.descText); setdescTextTextString(sitedata.descText);   }
            if(sitedata.descTextSpanish!==undefined && sitedata.descTextSpanish!==''){  setdescTextSpanish(sitedata.descTextSpanish)   }           
            if(sitedata.descType!==undefined){  setdescType(sitedata.descType)   }
            
            if(sitedata.descTextSecondline!==undefined &&  sitedata.descTextSecondline!==''){  setdescTextSecondline(sitedata.descTextSecondline); setdescTextSecondlineTextString(sitedata.descTextSecondline);   }
            if(sitedata.descTextSpanishSecondline!==undefined && sitedata.descTextSpanishSecondline!==''){  setdescTextSpanishSecondline(sitedata.descTextSpanishSecondline)   } 


            
            if(sitedata.tncSize!==undefined  && sitedata.tncSize!==''){  settncSize(sitedata.tncSize)   }
            if(sitedata.tncSizeM!==undefined  && sitedata.tncSizeM!==''){  settncSizeM(sitedata.tncSizeM)   }
            if(sitedata.tncText!==undefined &&  sitedata.tncText!==''){  settncText(sitedata.tncText); settncTextSpanishTextString(sitedata.tncText)   }
            if(sitedata.tncTextSpanish!==undefined && sitedata.tncTextSpanish!==''){  settncTextSpanish(sitedata.tncTextSpanish)   }            
            if(sitedata.tncType!==undefined){  settncType(sitedata.tncType)   }

            if(sitedata.titleSize!==undefined){  settitleSize(sitedata.titleSize)   }
            if(sitedata.titleSizeM!==undefined){  settitleSizeM(sitedata.titleSizeM)   }
            if(sitedata.titleText!==undefined &&  sitedata.titleText!==''){  settitleText(sitedata.titleText); settitleTextTextString(sitedata.titleText)   }
            if(sitedata.titleTextSpanish!==undefined && sitedata.titleTextSpanish!==''){  settitleTextSpanish(sitedata.titleTextSpanish)   }            
            if(sitedata.titleType!==undefined){  settitleType(sitedata.titleType)   }


        } catch (error) {
                console.log(error);
                
        }
            settheme(responseSite.docs[0].data())
            console.log(responseSite.docs[0].data(),'ok');
            if(sitedata.siteName!==undefined &&  sitedata.siteName!==''){
            setsiteName(renderSiteName(sitedata.siteName))
            }else{
                const nama= renderSiteName(sitedata.path)
                setsiteName(nama)
            }
        } 
    }else{

        // if site not in url
        
        const responseSite =  await getDocs(query(collection(firebase,`businesses/${response.docs[0].id}/sites`),where('businessId','==',response.docs[0].id)));
        if(!responseSite.empty){

            responseSite.docs.forEach((res:any)=>{      
             const   sitedata=  res.data()
             vipcheckbox(response,sitedata);
             if(sitedata.isArchive===undefined || sitedata.isArchive===null || sitedata.isArchive===0 || sitedata.isArchive==='0'){
                setSiteId(res.id);
                setSite(res)

                try {
                
                    if (sitedata.theme && sitedata.theme.logo) {
                        setLogo(sitedata.theme.logo)
                    }
                        
                } catch (error) {
                   console.log(error);
                        
                }
    

            try {
                if(sitedata.howitworksColor!==undefined && sitedata.howitworksColor!==''){ sethowitworksColor(sitedata.howitworksColor) }
                if(sitedata.howitworksBgColor!==undefined && sitedata.howitworksBgColor!==''){ sethowitworksBgColor(sitedata.howitworksBgColor) }
                
             if(sitedata.enableExpanolBtn!==undefined && sitedata.enableExpanolBtn===true){  setenableExpanolBtn(sitedata.enableExpanolBtn)  }
             if(sitedata.howitworksImg!==undefined && sitedata.howitworksImg!==''){   sethowitworksImg(sitedata.howitworksImg); }
            if(sitedata.homeBtnEnable!==undefined){ sethomeButotn(sitedata.homeBtnEnable); }
            if(sitedata.homeBtnUrl!==undefined){ sethomeBtnUrl(sitedata.homeBtnUrl); }
            if(sitedata.homeButtonText!==undefined && sitedata.homeButtonText!==null && sitedata.homeButtonText!==''){ sethomeButtonText(sitedata.homeButtonText); }
                if(sitedata.homeButtonTextSpanish!==undefined && sitedata.homeButtonTextSpanish!==null &&  sitedata.homeButtonTextSpanish!=='' ){ sethomeButtonTextSpanish(sitedata.homeButtonTextSpanish); }
                if(sitedata.homeButtonBgColor!==undefined && sitedata.homeButtonBgColor!==null && sitedata.homeButtonBgColor!==''){ sethomeButtonBgColor(sitedata.homeButtonBgColor); }
                if(sitedata.homeButtonColor!==undefined && sitedata.homeButtonColor!==null && sitedata.homeButtonColor!==''){ sethomeButtonColor(sitedata.homeButtonColor); }
            if(sitedata.heightForDesktop!==undefined){ setheightForDesktop(sitedata.heightForDesktop); }
            if(sitedata.widthForDesktop!==undefined){ setwidthForDesktop(sitedata.widthForDesktop); }
            if(sitedata.kioskUiLogoBorderEnable!==undefined && sitedata.kioskUiLogoBorderEnable==true){ setkioskUiLogoBorderColor(sitedata.kioskUiLogoBorderColor); }
                   
            if(sitedata.retToPageSize!==undefined){  setReturnFontSize(sitedata.retToPageSize)   }
            if(sitedata.retToPageSizeM!==undefined){  setReturnFontSizeM(sitedata.retToPageSizeM)   }
            if(sitedata.retToPageText!==undefined &&  sitedata.retToPageText!==''){  setReturnpageText(sitedata.retToPageText); setreturnTextString(sitedata.retToPageText)      }
            if(sitedata.retToPageTextSpanish!==undefined && sitedata.retToPageTextSpanish!==''){  setReturnpageTextSp(sitedata.retToPageTextSpanish)   }           
            if(sitedata.retToPageType!==undefined){  setReturnFontType(sitedata.retToPageType)   }

            if(sitedata.accessDashboardSize!==undefined){  setaccessdashboardurnFontSize(sitedata.accessDashboardSize)   }
            if(sitedata.accessDashboardSizeM!==undefined){  setaccessdashboardurnFontSizeM(sitedata.accessDashboardSizeM)   }
            if(sitedata.accessDashboardText!==undefined &&  sitedata.accessDashboardText!==''){  setaccessdashboardurnpageText(sitedata.accessDashboardText); setaccessdashboardurnTextString(sitedata.accessDashboardText)  }
            if(sitedata.accessDashboardTextSpanish!==undefined && sitedata.accessDashboardTextSpanish!==''){  setaccessdashboardurnpageTextSp(sitedata.accessDashboardTextSpanish)   }           
            if(sitedata.accessDashboardType!==undefined){  setaccessdashboardurnFontType(sitedata.accessDashboardType)   }
                        

            if(sitedata.descSize!==undefined){  setdescSize(sitedata.descSize)   }
            if(sitedata.descSizeM!==undefined){  setdescSizeM(sitedata.descSizeM)   }
            if(sitedata.descText!==undefined &&  sitedata.descText!==''){  setdescText(sitedata.descText); setdescTextTextString(sitedata.descText);   }
            if(sitedata.descTextSpanish!==undefined && sitedata.descTextSpanish!==''){  setdescTextSpanish(sitedata.descTextSpanish)   }           
            if(sitedata.descType!==undefined){  setdescType(sitedata.descType)   }
            
            if(sitedata.descTextSecondline!==undefined &&  sitedata.descTextSecondline!==''){  setdescTextSecondline(sitedata.descTextSecondline); setdescTextSecondlineTextString(sitedata.descTextSecondline);   }
            if(sitedata.descTextSpanishSecondline!==undefined && sitedata.descTextSpanishSecondline!==''){  setdescTextSpanishSecondline(sitedata.descTextSpanishSecondline)   } 


            
            if(sitedata.tncSize!==undefined  && sitedata.tncSize!==''){  settncSize(sitedata.tncSize)   }
            if(sitedata.tncSizeM!==undefined  && sitedata.tncSizeM!==''){  settncSizeM(sitedata.tncSizeM)   }
            if(sitedata.tncText!==undefined &&  sitedata.tncText!==''){  settncText(sitedata.tncText); settncTextSpanishTextString(sitedata.tncText)   }
            if(sitedata.tncTextSpanish!==undefined && sitedata.tncTextSpanish!==''){  settncTextSpanish(sitedata.tncTextSpanish)   }            
            if(sitedata.tncType!==undefined){  settncType(sitedata.tncType)   }

            if(sitedata.titleSize!==undefined){  settitleSize(sitedata.titleSize)   }
            if(sitedata.titleSizeM!==undefined){  settitleSizeM(sitedata.titleSizeM)   }
            if(sitedata.titleText!==undefined &&  sitedata.titleText!==''){  settitleText(sitedata.titleText); settitleTextTextString(sitedata.titleText)   }
            if(sitedata.titleTextSpanish!==undefined && sitedata.titleTextSpanish!==''){  settitleTextSpanish(sitedata.titleTextSpanish)   }            
            if(sitedata.titleType!==undefined){  settitleType(sitedata.titleType)   }


        } catch (error) {
                console.log(error);
                
        }
        
            settheme(sitedata)
            console.log(sitedata,'ok');
            
            if(sitedata.siteName!==undefined &&  sitedata.siteName!==''){
                setsiteName(renderSiteName(sitedata.siteName))
                }else{
                 const nama= renderSiteName(sitedata.path)
                 setsiteName(nama)
                }
            }
            }); //foreach end
        } 

    }

    } catch (error) {
       console.log(error);
        
    } 
     
    setpageloader(false)
}


const vipcheckbox=(snap:any,siteDoc:any)=>{
try {

    if (siteDoc.enableVipClub) {
        setenableVipClub(siteDoc.enableVipClub)

      }

      if (siteDoc.englishKeyword) {      
          setenglishKeyword(siteDoc.englishKeyword)
      
      }

      if (siteDoc.englishLocation) {
        setenglishLocation(siteDoc.englishLocation)
      }

      let englishVipClubCheckboxText = "Want to join " + snap.docs[0].data().businessName + " " + renderSiteName(siteDoc.path) + "'s VIP Club? Click here! ";

      if (siteDoc.englishVipClubCheckboxText) {
        englishVipClubCheckboxText = siteDoc.englishVipClubCheckboxText
      }
      setenglishVipClubCheckboxText(englishVipClubCheckboxText)

      let englishVipClubHoverText = snap.docs[0].data().businessName + " -REWARDS DONE RIGHT. " + snap.docs[0].data().businessName + "'s VIP Members enjoy exclusive access to all things " + snap.docs[0].data().businessName + ". Members earn discounts on parts, free yard entries, " + snap.docs[0].data().businessName + " merch, early yard access, and other automotive accessories - on us. Here at " + snap.docs[0].data().businessName + ", your parts are our priority! We understand your needs and work endlessly to make sure that we've got you covered. Joining our VIP Club is simple: sign up with your mobile number, enable text updates, and let the rewards come to you!";

      if (siteDoc.englishVipClubHoverText) {
        englishVipClubHoverText = siteDoc.englishVipClubHoverText
      }
      setenglishVipClubHoverText(englishVipClubHoverText)

      if (siteDoc.spanishKeyword) {
      
        setspanishKeyword(siteDoc.spanishKeyword)
     
      }

      if (siteDoc.spanishLocation) {
        setspanishLocation(siteDoc.spanishLocation)
      }

      let spanishVipClubCheckboxText = "¿Quiere unirse al club VIP de " + snap.docs[0].data().businessName + " " + renderSiteName(siteDoc.path) + "? ¡Haga clic aquí!";

      if (siteDoc.spanishVipClubCheckboxText) {
        spanishVipClubCheckboxText = siteDoc.spanishVipClubCheckboxText;
      }
     setspanishVipClubCheckboxText(spanishVipClubCheckboxText)

      let spanishVipClubHoverText = snap.docs[0].data().businessName + " -REWARDS DONE RIGHT. " + snap.docs[0].data().businessName + "'s VIP Members enjoy exclusive access to all things " + snap.docs[0].data().businessName + ". Members earn discounts on parts, free yard entries, " + snap.docs[0].data().businessName + " merch, early yard access, and other automotive accessories - on us. Here at " + snap.docs[0].data().businessName + ", your parts are our priority! We understand your needs and work endlessly to make sure that we've got you covered. Joining our VIP Club is simple: sign up with your mobile number, enable text updates, and let the rewards come to you!";

      if (siteDoc.spanishVipClubHoverText) {
        spanishVipClubHoverText = siteDoc.spanishVipClubHoverText;
      }
      setspanishVipClubHoverText(spanishVipClubHoverText)
    
    } catch (error) {
    console.log(error);
    
    }
}


const renderSiteName = (path: any) => {

    if (path.includes("-")) {
      let words = path.split("-");
      words = words.filter((e:any) => e !== "vehicles"); // remove 'vehicles' word from the site name

      const uppercase = words.map((str:any) => {
        return str.charAt(0).toUpperCase() + str.substring(1);
      });
      return uppercase.join(" ");
    }


    return path.charAt(0).toUpperCase() + path.substring(1);
  };


const mobileChangex=(e:any)=>{
    if(e.length<10){
        setmob1Disable(true)
    }else{
        setmob1Disable(false)
    }
    if(e.length<=10){
    setMobilex(e)   
    }
}

const gotodashboard=()=>{
    let url = window.location.href
const lsp= url.split('')
    const split = url.split('/');
    if(lsp[lsp.length-1]==='/'){
    window.location.href =   url+mobile
    }else{

        window.location.href =  split[split.length-2].includes('-') && split[split.length-2].length<=1 ? url+mobile  :  url+'/'+mobile 
    }
    
}
const finalStyle = {
    background:theme.jiSignUpUiBackgroundColor!==undefined ? theme.jiSignUpUiBackgroundColor : '',
    color:theme.jiSignUpUiFontColor!==undefined ? theme.jiSignUpUiFontColor : '',
    height:document.documentElement.scrollHeight+40,
    }
    
    
const finalStylereturnPage={
        fontSize:screenSize>720 ? ReturnFontSize : ReturnFontSizeM,
        fontFamily:ReturnFontType,
        background:theme.jiSignUpUiBackgroundColor!==undefined ? theme.jiSignUpUiBackgroundColor : '',
        color:theme.jiSignUpUiFontColor!==undefined ? theme.jiSignUpUiFontColor : '',
}

const finalStyleaccessdashboardPage={
    fontSize:screenSize>720 ? accessdashboardurnFontSize : accessdashboardurnFontSizeM ,
    fontFamily:accessdashboardurnFontType,
    fontWeight:'unset',
    background:theme.jiSignUpUiBackgroundColor!==undefined ? theme.jiSignUpUiBackgroundColor : '',
    color:theme.jiSignUpUiFontColor!==undefined ? theme.jiSignUpUiFontColor : '',
}

const finalStyledesc={
    fontSize:screenSize>720 ? descSize: descSizeM,
    fontFamily:descType,
    background:theme.jiSignUpUiBackgroundColor!==undefined ? theme.jiSignUpUiBackgroundColor : '',
    color:theme.jiSignUpUiFontColor!==undefined ? theme.jiSignUpUiFontColor : '',
}

const finalStyletnc={
    fontSize:screenSize>720 ? tncSize : tncSizeM ,
    fontFamily:tncType,
    background:theme.jiSignUpUiBackgroundColor!==undefined ? theme.jiSignUpUiBackgroundColor : '',
    color:theme.jiSignUpUiFontColor!==undefined ? theme.jiSignUpUiFontColor : '',
    width:'74%',
    margin:'auto'
}

const finalStyletime={
    fontSize:screenSize>720 ? titleSize : titleSizeM ,
    fontFamily:titleType,
    background:theme.jiSignUpUiBackgroundColor!==undefined ? theme.jiSignUpUiBackgroundColor : '',
    color:theme.jiSignUpUiFontColor!==undefined ? theme.jiSignUpUiFontColor : '',
}

return (pageloader===true ? <Spinner  size={50}  /> : pageloader===false ? <div className='justInContainer' style={finalStyle}>    



    {busienssPath === 'wilberts-u-pull-it' ? <Button  
    style={{background:homeButtonBgColor,color:busienssPath !== 'wilberts-u-pull-it' ? homeButtonColor : '#ffffff'}}  
    onClick={()=>{homeBtnUrl!==null && homeBtnUrl!=='' ? window.location.href=homeBtnUrl :window.location.href ='/just-in/'+busienssPath+'/'+sitePath}} 
    className='homebutton' 
    intent='success'><Icon 
    icon='home' 
    size={20}/></Button>:''}

       {(window.location.pathname.includes('just-in') && busienssPath === 'wilberts-u-pull-it') ?    <link rel="stylesheet" type="text/css" href={'https://texnrewards.com/wilbert7.css'} /> : ''}  
       {/* {(window.location.pathname.includes('just-in') && busienssPath === 'wilberts-u-pull-it') ?    <link rel="stylesheet" type="text/css" href={require('../assets/css/wilbert.css')} /> : ''}   */}

       {(window.location.pathname.includes('just-in') && busienssPath === 'go-pull-it-atlanta-east') || busienssPath === 'go-pull-it' ?    <link rel="stylesheet" type="text/css" href={'https://texnrewards.com/gopullit12.css'} /> : ''}  
        {/* {(window.location.pathname.includes('just-in') && busienssPath === 'go-pull-it-atlanta-east') || busienssPath === 'go-pull-it' ?  <link rel="stylesheet" type="text/css" href={require('../assets/css/gopullit.css')} /> : ''}   */}
        {(window.location.pathname.includes('just-in') && busienssPath === "garys-u-pull-it") || (busienssPath ==="ctv-pik-a-part" || busienssPath ==="horseheads-pick-a-part" || busienssPath ==="upullusave") ? <script src='https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.min.js'/>:''}
{!vehiclePicksPage  ?
<span>
<div style={{height:'170px'}}>
    {showLogo ?<img src={logo}
    onClick={()=>busienssPath === 'wilberts-u-pull-it' ? window.location.href ='https://www.wilberts.com/u-pull-it/' : console.log('click')}
    style={{
        maxHeight:'90%',
        maxWidth:'90%',
        width:widthForDesktop,
        height:heightForDesktop,
        border:kioskUiLogoBorderColor!=='' ? `3px solid ${kioskUiLogoBorderColor}` :'none'}}
    className='logoLanding'
    />
    :''}
</div>

<div className='subContainer'> 

<Dialog title={lang.howitworksText}  isOpen={Infomodal} onClose={()=>setInfomodal(false)}>
    <DialogBody >
        <div
         style={{
            color:howitworksColor,
            background:howitworksBgColor,
            padding:'10px'
        }}
        >
    <p dangerouslySetInnerHTML={{__html:lang.howitworks}}></p>
    {howitworksImg ?
                        (<img
                          src={howitworksImg}
                          style={{
                            marginTop: "20px",
                            verticalAlign: "middle",
                            width: "100%",
                          }}
                        />
                        ):('')
                      }
    </div>
    </DialogBody>
    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <Button onClick={()=>setInfomodal(false)}>Close</Button>
                        </div>
                    </div>
</Dialog>
    <H2 className='heading2 ml-0px' style={finalStyletime}>{parse(titleTextTextString)}  {businessName} {siteName!==null ? busienssPath === 'wilberts-u-pull-it' ? 'of':'in' : ''} <span style={{textTransform:'capitalize'}}>{siteName}</span>
    <span className='infosign'  onClick={()=>setInfomodal(true)}></span>
    </H2>
   <p style={{margin:'13px 0px'}}><span className='heading2 subheading' style={finalStyledesc}>{parse(descTextTextString)} </span><br/>
    <span className='heading2 subheading' style={finalStyledesc}>{parse(descTextSecondlineTextString)}</span>
    </p>
    <MakeModelYearInput
     lang={langstr}    
     callBack={(e:any,str:any)=>{setlangstr(str); setlang(e);
         if(str=='eng'){ 
            setreturnTextString(ReturnpageText)
            setaccessdashboardurnTextString(accessdashboardurnpageText) 
            setdescTextTextString(descText)
            setdescTextSecondlineTextString(descTextSecondline)   
            settncTextSpanishTextString(tncText)  
            settitleTextTextString(titleText)        
            }else{
                setreturnTextString(ReturnpageTextSp)
                setaccessdashboardurnTextString(accessdashboardurnpageTextSp)
                setdescTextTextString(descTextSpanish)
                setdescTextSecondlineTextString(descTextSpanishSecondline) 
                settncTextSpanishTextString(tncTextSpanish)  
                settitleTextTextString(titleTextSpanish)    
            }
             }}
     showVehiclePicks={(e:any)=>setvehiclePicksPage(e)}
     setMobilex={(e:any)=>setMobilex(e)}
     businessId={businessId}
     mobile={mobile}
     siteId={siteId}
     busienssPath={busienssPath}
     sitePath={sitePath}
     theme={theme}

     englishVipClubCheckboxText={englishVipClubCheckboxText}
     englishVipClubHoverText={englishVipClubHoverText}
     spanishVipClubCheckboxText={spanishVipClubCheckboxText}
     spanishVipClubHoverText={spanishVipClubHoverText}   
     englishKeyword={englishKeyword} 
     spanishKeyword={spanishKeyword}
     spanishLocation={spanishLocation} 
     englishLocation={englishLocation}
     setisVipClub={(e:any)=>setisVipClub(e)}
     setisVipClubS={(e:any)=>setisVipClubS(e)}
     isVipClubS={isVipClubS}
     isVipClub={isVipClub}
     homeBtnUrl={homeBtnUrl}

     enableVipClub={enableVipClub}
     />


 
          
    <p className='text2' style={finalStyletnc}>
          {tncTextSpanishTextString} <a href='https://client.texnrewards.net/terms_of_use.asp'>Ts & Cs / Privacy Policy</a>
    </p>
    <br/>
    <div className='sub2Container'>
            <H2 className='heading2'  style={finalStylereturnPage}>{returnTextString}</H2>
            <H3 className='h22'  style={finalStyleaccessdashboardPage}>{accessdashboardurnTextString}</H3>
            <InputGroup
            type='number'
            className='inputForMobile'
            value={mobile}
            onChange={(e)=>mobileChangex(e.target.value)}
            />
            <Button  onClick={()=>gotodashboard()} disabled={mob1Disable} className='mt-2' intent='success' large={true}><Icon icon='log-out'/>&nbsp;&nbsp;&nbsp;{lang.next}</Button>
<br/>
      
    </div>

    {homeButotn && busienssPath !== 'wilberts-u-pull-it'  ? <Button  onClick={()=>window.location.href=homeBtnUrl}  
    className='mt-2'     style={{background:busienssPath !== 'wilberts-u-pull-it' ? homeButtonBgColor : '#f07338' ,color:busienssPath !== 'wilberts-u-pull-it' ? homeButtonColor : '#ffffff'}} 
    large={true}><Icon style={{color:busienssPath !== 'wilberts-u-pull-it' ? homeButtonColor : '#ffffff'}} 
    icon='home'/>&nbsp;&nbsp;&nbsp;{langstr==='eng' ? homeButtonText : homeButtonTextSpanish}</Button>:''}

</div>
</span>: <VehiclePicksPage
            lang={langstr}
            strings={lang}
            enableExpanolBtn={enableExpanolBtn}
            widthForDesktop={widthForDesktop}
            heightForDesktop={heightForDesktop}
            kioskUiLogoBorderColor={kioskUiLogoBorderColor}
            callBack={(e:any,str:any)=>{setlangstr(str); setlang(e)}}
            businessId={businessId}
            siteId={siteId}
            showVehiclePicks={(e:any)=>setvehiclePicksPage(e)}
            busienssPath={busienssPath}
            sitePath={sitePath}
            mobile={mobile}
            logo={logo}
            feedType={feedType}
            theme={theme}
                />}
    </div>:<div></div>)

}