import React, { useEffect }  from 'react';
import '../assets/css/style.css'
import '../assets/css/bootstrap.css'
import { Card, Elevation, H2, Icon } from '@blueprintjs/core';
import { AllRoutes } from '../routes/routes';
import { Link } from 'react-router-dom';

export const Home = ()=>{

    return(
        <div className='home container-fluid'>         
<br/>
<div className='row p-1'>
{AllRoutes().map((r:any ,index:any)=>(r.homeGrid ? 
<div key={index} className='col-md-4 mt-12'>
    <Card className='min-h-27' elevation={Elevation.TWO}>
    <Link to={r.path}><Icon
        className="elementor-icon"
        icon={r.icon}
        iconSize={100}
    /></Link>
    <H2 className='heading' >{r.name}</H2>
    <p className='description'>{r.description}</p>
    </Card>
</div>:''
))}



</div>
        </div>
    )
}