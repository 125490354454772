export interface IStrings {
    add: string;
    added: string;
    addNewPick: string;
    close: string;
    disclaimer: string;
    enterMobile: string;
    enterMobileDashboard: string,
    enterMobileReturnString: string,
    getAlertsPromo: string;
    getAlertsPromoSecondLine: string;
    helpTxt: string;
    helpTxtDesc: string;
    homebtn:string;
    howitworks:string
    howitworksText:string
    make: string;
    minMax: string;
    model: string;
    modelError: string;
    moreImages: string;
    myPicks: string;
    new: string;
    newVehicles: string;
    next: string;
    noFreshStock: string;
    rowNumber: string;
    signUpWelcome: string;
    selectAVehicle: string;
    stockNumber: string;
    toggleLanguage: string;
    vehicleInformation: string;
    welcomeInstructions: string;
    year: string;
    yearRange: string;
  }
  
  export interface ILanguageStrings {
    eng: IStrings;
    esp: IStrings;
    [key: string]: IStrings;
  }
  
  export const strings: ILanguageStrings = {
    eng: {
      add: "Add",
      addNewPick: "ADD A NEW PICK",
      added: "Added",
      close: "Close",
      disclaimer:
        "By selecting Next, you are signing up to receive automated text alerts and marketing calls from an autodialer system or live person. Consent to receive automated text alerts not required for purchase. Upon completion of this form, you will receive a text message with instructions to confirm your opt-in. Approx 31 msg/mo & Data Rates May Apply. Reply HELP for HELP, STOP ALL to cancel.",
      enterMobile: "Enter your mobile number",
      enterMobileDashboard: "Enter your mobile number to access dashboard",
      enterMobileReturnString: "Returning to this page?",
      getAlertsPromo: "Get Alerts for newly-added vehicles, ",
      getAlertsPromoSecondLine:
        "Straight to your phone! We'll send you a text when it arrives.",
      helpTxt: "Help",
      helpTxtDesc: `To add a new pick, press the green "Add New Pick" button.<br /><br />You will receive a text on the first full day that a vehicle is available to the public so you have the best chance of the parts you are looking for still being there.  If you add a pick of a vehicle which is already in the yard, then you will not receive a text for those vehicles – only new ones added after you make your picks.<br /><br />Picks expire after 90 days.  The number of days left next to each pick lets you know how many days are remaining.  You will not receive text notifications once your pick expires.<br /><br />To see more information on vehicles, click the pick on the left-hand side and each new vehicle will show up under New Vehicles on the right-hand side.<br /><br />The New Vehicle column on the right-hand side shows how many days ago each vehicle was added.  After 7 days, then vehicles do not show up anymore in the right-hand column.<br /><br />To view more Vehicle Information, then click on each individual vehicle shown at the right.<br /><br />To delete a pick before the 90 days is up, then click the X mark displayed to the left of each pick on the left-hand side.`,
      homebtn:'Home',
      howitworks:'If you are returning to this page and want to check the vehicle picks you have already made, then enter your phone number it the bottom box below where it says “Returning to this page? Enter your mobile number to access Dashboard”.<br /><br />If you haven’t ever entered a vehicle pick, then you will need to pick a Make and Model you want to receive notifications for.  Year range is optional.<br /><br />Once you are finished entering the vehicle you want to receive notifications for, then enter a valid mobile number.<br /><br />You can access your Dashboard with the green Next button.  Your Dashboard is where you can edit, renew or delete vehicle picks. ',
      howitworksText:'How It Works',
      make: "Make",
      minMax: "Minimum/Maximum (optional)",
      model: "Model",
      modelError:
        "We couldn't find any models matching your search. Try a different year range.",
      moreImages: "More Images",
      myPicks: "My Picks",
      new: "New",
      newVehicles: "New Vehicles",
      next: "Next",
      noFreshStock: "No Fresh Stock",
      rowNumber: "Row Number",
      selectAVehicle: "Select a vehicle",
      signUpWelcome: "Sign up for Just In vehicle alerts at ",
      stockNumber: "Stock Number",
      toggleLanguage: "Cambiar a Español",
      // toggleLanguage: "Español",
      vehicleInformation: "Vehicle Information",
      welcomeInstructions: "Press the green 'add' button to add a new pick",
      year: "Year",
      yearRange: "Range (optional)",
    },
    esp: {
      add: "Agregar",
      addNewPick: "Agregar una nueva selección",
      added: "Añadido",
      close: "Cerca",
      disclaimer:
        "Al seleccionar Siguiente, se registra para recibir alertas de texto automatizadas y llamadas de marketing de un sistema de marcación automática o una persona en vivo. No se requiere consentimiento para recibir alertas de texto automáticas para la compra. Al completar este formulario, recibirá un mensaje de texto con instrucciones para confirmar su suscripción. Se pueden aplicar hasta 31 msg / mes y tarifas de datos. Responda HELP para HELP, STOP ALL para cancelar.",
      enterMobile: "Ingrese su número de teléfono móvil",
      enterMobileDashboard: "Ingrese su número de teléfono móvil",
      enterMobileReturnString: "Ingrese su número de teléfono móvil",
      getAlertsPromo: "Obtenga alertas para vehículos recién agregados",
      getAlertsPromoSecondLine:
        "Directo a su teléfono! Le enviaremos un mensaje de texto cuando llegue",
      helpTxt: "El auxilio",
      helpTxtDesc: `Para agregar una nueva selección, presione el botón verde "Agregar nueva selección".<br /><br />Recibirá un mensaje de texto el primer día completo en que un vehículo esté disponible para el público para que tenga la mejor oportunidad de que las piezas que está buscando sigan allí. Si agrega una selección de un vehículo que ya está en el patio, no recibirá un mensaje de texto para esos vehículos, solo se agregarán nuevos después de hacer sus selecciones.<br /><br />Las selecciones caducan después de 90 días. El número de días que quedan junto a cada selección le permite saber cuántos días quedan. No recibirá notificaciones de texto una vez que expire su selección.<br /><br />Para ver más información sobre los vehículos, haga clic en la opción de selección en el lado izquierdo y cada nuevo vehículo aparecerá debajo de Vehículos nuevos en el lado derecho.<br /><br />La columna de Vehículo nuevo en el lado derecho muestra cuántos días atrás se agregó cada vehículo. Después de 7 días, los vehículos ya no aparecen en la columna de la derecha.<br /><br />Para ver más información del vehículo, haga clic en cada vehículo individual que se muestra a la derecha.<br /><br />Para eliminar una selección antes de que transcurran los 90 días, haga clic en la marca X que se muestra a la izquierda de cada selección en el lado izquierdo.`,
      homebtn:'Hogar',
      howitworks:'Si regresa a esta página y desea verificar las selecciones de vehículos que ya realizó, ingrese su número de teléfono en el cuadro inferior donde dice “¿Regresando a esta página? Ingrese su número de teléfono móvil para acceder al Tablero ”.<br /><br />Si nunca ingresó una selección de vehículo, deberá elegir una Marca y Modelo para los que desea recibir notificaciones. El rango de años es opcional.<br /><br />Una vez que haya terminado de ingresar el vehículo para el que desea recibir notificaciones, ingrese un número de teléfono móvil válido.<br /><br />Puede acceder a su Panel de control con el botón verde Siguiente. Su Tablero es donde puede editar, renovar o eliminar selecciones de vehículos.',
      howitworksText:'Cómo Funciona',
      make: "Marca",
      minMax: "Mínimo / Máximo (opcional)",
      model: "Modelo",
      modelError:
        "No pudimos encontrar ningún modelo que coincida con su búsqueda. Pruebe un rango de año diferente.",
      moreImages: "Mas imagenes",
      myPicks: "Mis selecciones",
      new: "Nuevo",
      newVehicles: "Vehículos nuevos",
      next: "Próximo",
      noFreshStock: "Sin stock fresco",
      rowNumber: "Numero de fila",
      selectAVehicle: "Seleccione un vehiculo",
      signUpWelcome: "Regístrese para recibir alertas de texto de Fresh Stock en",
      stockNumber: "Número de inventario",
      toggleLanguage: "Change to English",
      vehicleInformation: "Información del vehículo",
      welcomeInstructions:
        "Presione el botón verde 'agregar' para agregar una nueva selección",
      year: "Año",
      yearRange: "Range (optional)",
    },
  };