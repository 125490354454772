import { Button, ButtonGroup } from "@blueprintjs/core";
import * as React from "react";
const PictureAndSignature = (dat:any)=>{
    const pictureDisplay=dat.data.pictureDisplay;
    const SignatureDisplay=dat.data.SignatureDisplay;
    const setPicSignDisplay= dat.setPicSignDisplay;
    return (<div>
                {dat.data.screenSize<750? <ButtonGroup className="signpicbuton">
        <Button onClick={()=>setPicSignDisplay({pictureDisplay:'block',SignatureDisplay:'none'})} intent={pictureDisplay==='block' ? "primary":'none'}>Picture</Button>
        <Button onClick={()=>setPicSignDisplay({pictureDisplay:'none',SignatureDisplay:'block'})}  intent={SignatureDisplay==='block' ? "primary":'none'}>Signature</Button>
        </ButtonGroup>:''}
        <div className="liveupdateinfo"  >    
       {dat.data.screenSize>1190 || dat.data.screenSize<750 ? <div style={{textAlign:'center',display:pictureDisplay}}> <label >{dat.data.screenSize>750  ? 'Picture':''}</label> <div   className="imgdiv"><img className="minmaximg" src={dat.data.viewCheckIn.picture===null || dat.data.viewCheckIn.picture==='' ? dat.data.picture : dat.data.viewCheckIn.picture }/></div></div>:''}
        <div style={{textAlign:'center',display:SignatureDisplay}} ><label  >{dat.data.screenSize>750  ? 'Signature':''}</label><div className="imgdiv">   <img className="minmaximg" src={dat.data.viewCheckIn.sinature===null || dat.data.viewCheckIn.sinature==='' ? dat.data.sinature : dat.data.viewCheckIn.sinature }/></div></div>
      </div>
      </div>
    )
}

export default PictureAndSignature;