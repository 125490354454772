import { Button, ButtonGroup, Dialog, DialogBody, H2, HTMLTable, Icon, Spinner } from "@blueprintjs/core";
// import * as firebase from "firebase";
import * as React from "react";
// import VehicleModal from "src/components/VehicleModal";
// import { firestore } from "src/firebase";
import { strings } from '../../components/justInComponents/strings';
import { Timestamp, addDoc, collection, deleteDoc, doc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { firebase } from "../../global/firebase";




class VeiclePicksDashboard extends React.Component<any, any> {
    public state:any ={
        openHelp:false,
        vehicles:[],
        lang:strings.eng,
        showVehicleModal:false,
        saveLoading:true,
        bool:false,
        edit:false,
                showAvailablePicks:false,
                vehicleModalVehicle:[],
                siteForvehicleModal:[],
        availablePicks:{
            vehicles:[],
            yearMin:'',
            yearMax:'',
            make:'',
            model:'',
        },
        editPick:{
                UniqueKey:'',
                businessId:'',
                combined:'',
                firstName:'',
                language:'',
                make:'',
                vehicles:[],
                mobile:'',
                model:'',
                modelsGroup:'',
                siteId:'',
                textSent:'',
                timestamp:'',
                userId:'',
                yearMax:'',
                yearMin:''
            }
    }


   public  componentDidMount(): void {
    setTimeout(() => {
        const main = document.querySelector<HTMLElement>('body')!;
        main.style.paddingTop='10px'           
        main.style.background='#ffffff' 
    }, 500);
        this.getSetPicks();
      
    }

    public getuniqueVehicleById=async(UniqueKey:any,data:any)=>{
        const arr:any =[];
        
           await data.map(async(val:any)=>{
                const row = val.data();
                    if(row.UniqueKey===UniqueKey){  
                        const vehi = Object.assign(row, this.filterSites(row.siteId));
                        const Available= await this.getAvailabelVehicles(vehi,row); 
                        row['checked']=true;
                        row['preAdded']=true;
                        row['availables'] =Available;           
                        arr.push(row)
                    }
            });
            return arr;
        
        }

public filterSites=(id:any)=>{
        const sites= this.props.data.sites;        
        const re=   sites.filter((s:any)=>(s.id===id));                
        return re[0]
}

    public getSetPicks=()=>{
        this.ResetPage();        
        const busienssId=this.props.data.business.id;     
        const mobile=this.props.data.mobile;  
        const vehicles = getDocs(query(collection(firebase,`vehiclePicks`),where('businessId','==',busienssId),where('mobile','==',mobile)));
       const final:any[] = []
         vehicles.then(async(r:any)=>{
           const data= r.docs;
           const re =    await  data.map(async(row:any)=>{
            if(row.data().UniqueKey!==undefined){
            const key = row.data().UniqueKey;                         
                final[key]=row.data();
                final[key]['loading']=false;
                final[key]['vehicles']  =await this.getuniqueVehicleById(key,data);   
                
            }
            });
            if(re){
                setTimeout(() => {
                  this.setState({vehicles:final,saveLoading:false});                                       
                }, 1000);
            }
 
      
       })
    }



    public getAvailabelVehicles=async(site:any,row:any)=>{
        const dateOffset = (24*60*60*1000) * 7; // 5 days
        const date = new Date();
        date.setTime(date.getTime() - dateOffset);
        const collections = site.s3id!==null &&  site.s3id!=='' ? 'crushS3Feed' : site.urgid!==null &&  site.urgid!=='' ? 'hollanderVehicles' :''
        
            if(row.make && row.model && row.yearMax && row.yearMin){
             const xx=row;
                try {
                    const arr:any = [];
                    //  code wil remove after 90days
                    const oprator ='modelsGroup' in xx ? 'in'  : '=='
                    const modelColumn ='modelsGroup' in xx ? xx['modelsGroup'] : xx['model']

                    const vehicles =await getDocs(query(collection(firebase,collections),where('businessId','==',xx['businessId']),where('siteId','==',xx['siteId']),where('freshOn','>=',date),where('make','==',xx['make'])));
                         
                           
                      if(vehicles.empty===false){                    
                      const xv= vehicles.docs.map((x:any)=>{
                            try {
                              const data= x.data(); 
                              let validVehicle= false  
                              if(oprator==='in' && modelColumn.includes(data['model'])){
                                validVehicle=true
                              }
                              if(oprator==='==' && modelColumn===data['model']){
                                validVehicle=true
                              }        
                              if(data['year']<=xx['yearMax'] && data['year']>=xx['yearMin'] && validVehicle){
                                const urgImage:any = null
                            //    if(stockImgUrl!==null){
                            //     urgImage=  stockImgUrl.replace('xxxxx',data['vstockno']).replace('xxxxx',data['vstockno'])
                            //     }
                              const pick = {
                                  HOL_YEAR:data['year'],
                                  COLOR: null,
                                  MAKE: [data['make']],
                                  MODEL: [data['model']],
                                  STOCKNUMBER: [data['vstockno']],
                                  YARD_NAME: [data['businessName']],
                                  YARD_DATE: data['freshOn']!=null ? [new Date(data['freshOn'].seconds*1000).toDateString()] : null,
                                  VEHICLE_ROW: 'rowNo' in data && data['rowNo']!=null ? [data['rowNo']] : [data['location']],
                                  VIN:'vin_no' in data && data['vin_no']!==null ?  [data['vin_no']] : null,
                                  URG_IMAGE:urgImage,
                                  URG_IMAGE_SLIDESHOW: null,
                               }                 
                              arr.push(pick) 
                              }
                              
                            } catch (error) {
                              console.log(error);
                              
                            }
                        });
                        if(xv){          
                            return arr
                        }
                  }
                } catch (error) {
                    console.log(error);
                    
                   return []; 
                }
                
            }

        return []

    }

    public printSites=(rowa:any)=>{
        try {    
    const text:any = [];
    rowa.map((row:any) => {
                text.push(row.name);         
        })
        const arr2 = text.sort();
    return arr2.join(', ')
        
    } catch (error) {
        return ''    
    }
}

public HaveNewVehicleCount=(rowq:any)=>{
    try {
        
        let count:any =0;
        rowq.map((row:any) => {   
                         
               count += row['availables'].length;           
            });
    const finalCount = count;
if(finalCount>0 && finalCount<=1){
    return count +' New Vehicle'     
}


if(finalCount>1){
    return  count +' New Vehicles'      
}else{
    return  'No New Vehicles'    
}
            
    } catch (error) {
        return 'danger'    
    }
}

public HaveNewVehicle=(rowq:any)=>{
    try {
        const  text:any =[];
        rowq.map((row:any) => {                           
                text.push(row['availables'].length>0);           
            });

        if(text.includes(true)){

            return 'success' 
        }else{

            return 'danger'  
        }            
    } catch (error) {
        return 'danger'    
    }
}

public renderSiteName = (path: any) => {

        if (path.includes("-")) {
          let words = path.split("-");
          words = words.filter((e:any) => e !== "vehicles"); // remove 'vehicles' word from the site name
    
          const uppercase = words.map((str:any) => {
            return str.charAt(0).toUpperCase() + str.substring(1);
          });
          return uppercase.join(" ");
        }
    
        return path.charAt(0).toUpperCase() + path.substring(1);
      };


public openEdit=(row:any)=>{    
    this.setState({editPick:row,edit:true});
}


public daysLeft=(pick:any)=>{
    const remainDaysValue =  90;
    if (pick.timestamp) {
        const timestampToDate = pick.timestamp.toDate();
        const diff = new Date().getTime() - new Date(timestampToDate).getTime();
        const delta = diff / 1000;
        const days = Math.floor(delta / 86400);

        return (remainDaysValue - (days > remainDaysValue ? remainDaysValue : days));
     
      }else{
       return  remainDaysValue
      }
}


public checkedEditCheckox=(id:any)=>{
let val=false;
const row2=this.state.editPick.vehicles;
if(this.state.editPick.vehicles!==undefined){
    row2.map(async(row:any,key:any) => {   
            if(row.id===id && row.checked===true){
                val=true
            }        
        });    
    }
    return val;         
     }
 
     public FinalRemovePickFromMultiLocation=async(target:any,id:any,site:any)=>{
        const arr:any = [];
        const rows= this.state.editPick.vehicles;
        let newRow = true;
        rows.map(async(row:any,key:any) => {     
          if(row.id===id ){
              row.checked= target.target.checked ? true :false
              arr.push(row)
              newRow=false
          }  else{
              arr.push(row)
          }         
      }); 
  
      if(newRow===true){
             try {
          const x:any=[]        
          x.UniqueKey=rows[0].UniqueKey
          x.availables=[]
          x.businessId=rows[0].businessId
          x.combined=rows[0].combined
          x.firstName=rows[0].firstName
          x.id=id
          x.make=rows[0].make
          x.mobile=rows[0].mobile
          x.model=rows[0].model
          x.modelsGroup=rows[0].modelsGroup
          x.textSent=rows[0].textSent
          x.timestamp=rows[0].timestamp
          x.userId=rows[0].userId
          x.yearMax=rows[0].yearMax
          x.yearMin=rows[0].yearMin
          x.siteId =id;
          x.name =site.name;
          x.path =site.path;
          x.s3id =site.s3id;
          x.urgid =site.urgid;
          x.preAdded=false;
          x.checked= target.target.checked ? true :false; 
          // const vehi = Object.assign(x, this.filterSites(x.siteId));
          // const Available= await this.getAvailabelVehicles(vehi,x);  
          const Available:any= [];
          x.availables=Available;
          arr.push(x)
                      
      } catch (error) {
              console.log(error,'error');
              
      }
      }
  
      const m1 = this.state.editPick;  
      m1.vehicles =arr;
      this.setState({editPick:m1})
  
        
  }

public actionParform=async()=>{

    this.setState({saveLoading:true});
    const rows =this.state.editPick.vehicles;
    console.log(rows,'ppllplp');
    
    rows.map(async(row:any,key:any) => { 
        if(row.preAdded &&  !row.checked){
            
        const r = await getDocs(query(collection(firebase,'vehiclePicks'),where('UniqueKey','==',this.state.editPick.UniqueKey),where('siteId','==',row.id)));
            if(!r.empty){
                const idrw = r.docs[0].id;                
                await  deleteDoc(doc(firebase,'vehiclePicks',idrw));
            }
        }
        if(!row.preAdded &&  row.checked){
            const pickFinal:any = this.state.editPick;
            pickFinal.siteId =row.id;
            pickFinal.vehicles=[];   
            await addDoc(collection(firebase, "vehiclePicks"), pickFinal)                
        }
    });

    setTimeout(() => {

    this.ResetPage();
    this.getSetPicks();
            
    }, 1000);
}


public ResetPage=()=>{
    this.setState({
        vehicles:[],
        bool:false,
        availablePicks:[],
        showAvailablePicks:false,
        edit:false,
        editPick:{
                UniqueKey:'',
                businessId:'',
                combined:'',
                firstName:'',
                language:'',
                make:'',
                vehicles:[],
                mobile:'',
                model:'',
                modelsGroup:'',
                siteId:'',
                textSent:'',
                timestamp:'',
                userId:'',
                yearMax:'',
                yearMin:''
            }
    })
}



public refreshPick=async(UniqueKey:any)=>{
    
    const vehicles = this.state.vehicles;
    const myTimestamp = Timestamp.fromDate(new Date());
    vehicles[UniqueKey].timestamp =myTimestamp;                
    this.setState({vehicles})

    const picks =await getDocs(query(collection(firebase,'vehiclePicks'),where('UniqueKey','==',UniqueKey)));

   if(picks.empty===false){
   picks.docs.map(async(v:any)=>{

    await updateDoc(doc(firebase, "vehiclePicks", v.id), {
        timestamp:new Date()
    }).then(docRef => {
        console.log('days successfully updated ');
    }).catch((e)=>{console.log(e); })


    });

   }

}

public DeleteVehiclePick=async(UniqueKey:any)=>{
    if(window.confirm('Are you sure to delete this pick')){       
        const picks = await getDocs(query(collection(firebase,'vehiclePicks'),where('UniqueKey','==',UniqueKey)));
        if(picks.empty===false){
        picks.docs.map(async(v:any)=>{
            await  deleteDoc(doc(firebase,'vehiclePicks',v.id));
        });
        setTimeout(() => { 
        this.getSetPicks();   
    }, 1000);
        }
    }
}

public showAvailablePicks=(rowx:any)=>{
    this.setState({showAvailablePicks:true,availablePicks:[]});
    this.setState({availablePicks:rowx});
}

public daysAgo=(row:any)=>{
const date1:any=  new  Date(row);
const date2:any = new Date();
const diffTime = Math.abs(date2 - date1);
const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
console.log(diffDays + " days");
return diffDays+ " days ago"

}

public openVehicleModel=(row:any,avaiables:any)=>{
row.siteName=row.path;
    this.setState({
        showVehicleModal:true,
        vehicleModalVehicle:avaiables,
        siteForvehicleModal:row
    })
}

public render(){

    const {vehicles,edit,editPick,saveLoading,showAvailablePicks,availablePicks,showVehicleModal,siteForvehicleModal,openHelp,lang,vehicleModalVehicle} =this.state;
  const finalVehicle:any= [];
     Object.keys(vehicles).forEach((key) =>{
        finalVehicle.push(vehicles[key]);
        
    })
    try {

        const el =document.querySelectorAll<HTMLElement>('.css-1k7bvv7')[0];
        if(el!==undefined && el!==null){
          el.style.backgroundColor='#1E1D1B'  
        } 
                
    } catch (error) {
        console.log(error);
        
    }
    const availablePicks2:any= availablePicks.vehicles;
//     if(availablePicks.matches!==undefined && availablePicks.matches!==null){
//     Object.keys(availablePicks.matches).forEach((key) =>{
//         availablePicks2.push(availablePicks.matches[key]);
       
//    });
// }

        return(
            <div className="contain-Main-ml">
       
            <div className="ml-logo-container">
                <img className="ml-logo" src={this.props.data.business.logo!=='' ? this.props.data.business.logo : 'https://just-in.texnrewards.com/favicon.ico'}/>
            </div>

            {!showAvailablePicks ?   
             <div className="contain-Main-ml-sub" style={{marginTop:'2vh'}}> 
                <div className="ml-logo-container">
                <ButtonGroup>
                <Button intent="success" onClick={()=>this.props.return({showVehiclePicks:false,showAddPickScreen:true,showDefaultPage:false})}>Add New Vehicle</Button>&nbsp;&nbsp;
                <Button intent="primary" onClick={()=>this.setState({openHelp:true})} icon={'info-sign'}>How It Works</Button>
                </ButtonGroup>
            </div>
            <div className="ml-logo-container-list"><br/>
            <HTMLTable border={1}>
                <thead>
                <tr>                    
                <th>&nbsp;</th>
                <th>My Picks </th>
                <th colSpan={2}> New </th>
                </tr>
                </thead>
                <tbody>
                {finalVehicle.length>0  ?  finalVehicle.map((rowc:any,i:any) =>(
              <tr key={i}>
              <td >
                  <ButtonGroup>
                  <Button intent="primary" onClick={()=>this.openEdit(rowc)}><Icon icon='edit'/></Button>
                  <Button onClick={()=>this.DeleteVehiclePick(rowc.UniqueKey)} intent="danger"><Icon icon='cross'/></Button>
                  </ButtonGroup>
              </td>
              <td onClick={()=>{this.HaveNewVehicle(rowc.vehicles)==='danger' ? alert('No Available Vehicles.') : this.showAvailablePicks(rowc)}}>
                  {rowc.yearMin}-{rowc.yearMax} {rowc.make} {rowc.model}
                  <p>{this.printSites(rowc.vehicles)}&nbsp;&nbsp;{this.HaveNewVehicle(rowc.vehicles)==='success' ? <Button style={{minHeight:'17px',padding: '0px 4px',fontSize:'12px'}} intent="success" small={true}>click here</Button> :''}</p>
              </td>
              <td>
              <Icon iconSize={23} icon={this.HaveNewVehicle(rowc.vehicles)==='success' ? 'known-vehicle' :'unknown-vehicle'} intent={this.HaveNewVehicle(rowc.vehicles)}/><br/>
              <span> {this.daysLeft(rowc)}d left </span><br/><span> {this.HaveNewVehicleCount(rowc.vehicles)}</span>
              </td>
              <td><Button loading={rowc.loading} icon='refresh' intent="success" className={`rororor rotate${rowc.UniqueKey}`} onClick={()=>this.refreshPick(rowc.UniqueKey)}/></td>                 
              </tr>
                 
                )):  <tr><td colSpan={4}>{saveLoading ? <Spinner size={24} intent="primary"/> : 'Currently, no vehicles have been added. To add a new vehicle pick, simply select the "Add New Vehicle" button and fill out the form. If you need assistance understanding how Just In Vehicle Alerts operates, click the How It Works button for detailed information.'}</td></tr>}
                </tbody>
            </HTMLTable>
            </div>
            </div>:
            
            // availablePicks
            <div className="ml-logo-container-list">
            <HTMLTable border={1}>
                <thead>
                <tr>                    
                <th colSpan={3}><Button intent='primary' icon='fast-backward' onClick={()=>this.setState({showAvailablePicks:false,availablePicks:[]})} />&nbsp;&nbsp;&nbsp; <span>New Vehicles</span></th>
                </tr>
                </thead>
                <tbody>
                {availablePicks2.length>0  ? availablePicks2.map((rowx:any,i:any)=>(rowx.checked && rowx.availables.length>0 ?
                   (rowx.availables.map((rowxT:any,ix:any)=>(<tr key={i}>                  
                    <td>
                        {rowxT.HOL_YEAR} {availablePicks.make} {availablePicks.model} 
                         <br/>
                        Available  At {rowx.name}

                    </td>
                    <td>
                            {this.daysAgo(rowxT.YARD_DATE)}
                    </td>  
                   <td>
                     <Button loading={rowx.loading} icon='eye-open' small={true} intent="success" onClick={()=>this.openVehicleModel(rowx,rowxT)} />
                    </td>                 
                    </tr>))):''
                 
                )):<tr><td colSpan={4}>No Fresh Stock</td></tr>}
                </tbody>
            </HTMLTable>
            </div>
            // 
            }


{showVehicleModal ?
        <Dialog 
         style={{color:'#000000'}} 
         title={lang.vehicleInformation} isOpen={showVehicleModal} onClose={()=>this.setState({ showVehicleModal: false })}>
        <DialogBody >            
    <H2 className="text-center"style={{color:'#000000'}} >{lang.vehicleInformation}</H2>
    {vehicleModalVehicle.HOL_YEAR!==undefined?<HTMLTable border={1}>
    <tbody>
    <tr><th style={{color:'#000000'}}>{lang.year}:</th><td style={{color:'#000000'}}>{vehicleModalVehicle.HOL_YEAR}</td></tr>
    <tr><th style={{color:'#000000'}}>{lang.make}:</th><td style={{color:'#000000'}}>{vehicleModalVehicle.MAKE[0]}</td></tr>
    <tr><th style={{color:'#000000'}}>{lang.model}:</th><td style={{color:'#000000'}}>{vehicleModalVehicle.MODEL[0]}</td></tr>
    <tr><th style={{color:'#000000'}}>Business:</th><td style={{color:'#000000'}}>{vehicleModalVehicle.YARD_NAME!==undefined && vehicleModalVehicle.YARD_NAME[0]!==undefined ? this.renderSiteName(vehicleModalVehicle.YARD_NAME[0])  :''}</td></tr>
    <tr><th style={{color:'#000000'}}>Location:</th><td style={{color:'#000000'}}> {siteForvehicleModal.siteName!==undefined && siteForvehicleModal.siteName!==null ? this.renderSiteName(siteForvehicleModal.siteName)  :''}</td></tr>
    <tr><th style={{color:'#000000'}}>{lang.rowNumber}:</th><td style={{color:'#000000'}}>{vehicleModalVehicle.VEHICLE_ROW[0]!==undefined && vehicleModalVehicle.VEHICLE_ROW[0]!==undefined ?vehicleModalVehicle.VEHICLE_ROW[0]  :''}</td></tr>
    <tr><th style={{color:'#000000'}}>{lang.stockNumber}:</th><td style={{color:'#000000'}}>{vehicleModalVehicle.STOCKNUMBER[0]}</td></tr>
    <tr><th style={{color:'#000000'}}>VIN:</th><td style={{color:'#000000'}}>{vehicleModalVehicle.VIN[0]}</td></tr>
    </tbody>
    </HTMLTable>:''}
    </DialogBody>
        </Dialog>
        :''
}

<Dialog title= {'How It Works'} isOpen={openHelp} onClose={()=>this.setState({openHelp:false})}>
    <p style={{padding:'10px'}}>
To add a new pick, press the green "Add New Vehicle" button.
<br/><br/>
You will receive a text on the first full day that a vehicle is available to the public so you have the best chance of the parts you are looking for still being there. If you add a pick of a vehicle which is already in the yard, then you will not receive a text for those vehicles – only new ones added after you make your picks.
<br/><br/>
Picks expire after 90 days. The number of days left next to each pick lets you know how many days are remaining. You will not receive text notifications once your pick expires.
<br/><br/>
To see more information on vehicles, click the pick on the left-hand side and each new vehicle will show up under New on the right-hand side.
<br/><br/>
The New Vehicle column on the right-hand side shows how many days ago each vehicle was added. After 7 days, then vehicles do not show up anymore in the right-hand column.
<br/><br/>
To view more Vehicle Information, then click on each individual vehicle shown at the right.
<br/><br/>
To delete a pick before the 90 days is up, then click the X mark displayed to the left of each pick on the left-hand side.
<br/><br/>
To edit your location selections for a vehicle, select the edit pencil icon positioned to the left of the pick. Adjust the checkmarks to include only the location(s) for which you wish to receive notifications.
<br/><br/>
If you want to refresh the days left on your Vehicle selection, then select the refresh button to the right of your vehicle pick. This will renew the pick back to 90 days until expiration.    
    
    </p>
</Dialog>

<Dialog title= {editPick.yearMin+'-'+editPick.yearMax+' '+editPick.make+' '+editPick.model} isOpen={edit} onClose={()=>this.setState({edit:false})}>
<div style={{paddingTop:'10px',paddingLeft:'20px',fontSize:'22px',textTransform:'capitalize'}}>
                                {/* {business.path==='fenix-u-pull' ? 'ALL 3 NY Locations' : 'Select All'} */}
                                Check all that you would like to register for:
                </div>
<div style={{padding:'10px'}} >
           {this.props.data.sites.map((s:any,i:any)=>(
     <p key={i} style={{paddingTop:'10px',paddingLeft:'20px',fontSize:'21px',textTransform:'capitalize'}}> {s.path} 
    <input type="checkbox"
    checked={this.checkedEditCheckox(s.id)}
    onChange={(e)=>this.FinalRemovePickFromMultiLocation(e,s.id,s)}
    style={{float:'right',marginRight:'20px'}} 
       />
    </p>))}
<div style={{textAlign:'center'}}>
    <Button onClick={()=>this.setState({edit:false})} intent="warning">Cancel</Button>&nbsp;&nbsp;
    <Button onClick={()=>this.actionParform()} intent="success" loading={saveLoading}>Save</Button>&nbsp;&nbsp;
</div>
</div>
            </Dialog>
            
            </div>
          
            
        )

    }

}

export default VeiclePicksDashboard;