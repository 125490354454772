
import { Button, H2, H4, Icon, InputGroup } from "@blueprintjs/core"
import React, { useEffect, useState } from "react"
import { strings } from '../../components/justInComponents/strings';
import _ from "lodash";
import makes from "./makes";
import { addDoc, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { firebase } from "../../global/firebase";

export const MakeModelYearInput=(data:any)=>{
const currentYear =new Date().getUTCFullYear()
const [langStrings,setlangStrings]=useState(strings.eng)
const [years,setyears]=useState([] as any)
const [MinYear,setMinYear]=useState(1960 as any)
const [MaxYear,setMaxYear]=useState(currentYear as any)
const [make,setmake]=useState(makes[0] as any)
const [Model,setModel]=useState(null as any)
const [MakeModels,setMakeModels]=useState([] as any)
const [Mobile,setMobile]=useState(data.mobile)
const [loading,setloading]=useState(false)
const [Error,setError]=useState('')
const [mobDisable,setmobDisable]=useState(true)

const [ModelErorr,setModelErorr]=useState('')
    useEffect(()=>{
        console.log( data,'kssoko');
        
            if(data.lang==='eng'){
            setlangStrings(strings.eng)
            }
            if(data.lang==='esp'){
            setlangStrings(strings.esp)
            }  

            const max = new Date().getUTCFullYear();
            const min = 1960;
            const yearRange = _.range(min, max + 1);
            setyears(yearRange);
if(Model==null){
            getModels(make,MinYear,MaxYear);
}

    },[data,MaxYear,MinYear]);



const getModels=async(v:any,min:any,max:any)=>{   
    setloading(true)
    setmake(v)   
    const data =await getDocs(query(collection(firebase,'vehicles'),where('make','==',v),where('year','<=',max),where('year','>=',min)))
    const arr:any=[];
    if(!data.empty){
        data.docs.map((x:any)=>{  
            try {           
                if(x.data().make==='BMW' || x.data().make==='MERCEDES-BENZ'){
                    arr.push(x.data().groupName)
                }else{
                    arr.push(x.data().model)
                }                    
            } catch (error) {
                console.log(error);            
            }
        })  
        const arrUnique =  await arr.filter((value:any, index:any, array:any) => array.indexOf(value) === index)          
        const arrUniquex =  await arrUnique.sort();
        
        setMakeModels(arrUniquex)
        setModel('')
        setloading(false)
    }else{
        setMakeModels(arr) 
        setModel(null)
        setloading(false) 
    }
}

const updateMinYear=(e:any)=>{
    setMinYear(parseInt(e.target.value))
    getModels(make,parseInt(e.target.value),MaxYear)
    
}
const updateMaxYear=(e:any)=>{
    setMaxYear(parseInt(e.target.value)) 
    getModels(make,MinYear,parseInt(e.target.value))   
}

const mobileChange=(e:any)=>{   
    if(e.length<10){
    setmobDisable(true)
    }else{
        setmobDisable(false)
    }

    if(e.length<=10){
        setMobile(e)
        }
}

function onlyUnique(value:any, index:any, array:any) {
    return array.indexOf(value) === index;
  }
const addPick=async()=>{
    setError('')
    setloading(true);
    
if(Model!==null && Model!==''){
    vipclubFun()
    const array:any=[]
    if(make==='BMW' || make==='MERCEDES-BENZ'){
        
      const datas =await getDocs(query(collection(firebase,'vehicles'),where('make','==',make),where('groupName','==',Model),where('year','>=',MinYear),where('year','<=',MaxYear)))

      datas.docs.map((x:any)=>{
            array.push(x.data().model)
      }) 

    }else{
      array.push(Model)
    }
const finalarray =  array.filter(onlyUnique);

 const  pick ={    
    businessId:data.businessId,
    combined:make+' '+Model,
    firstName:'',
    make:make,
    mobile:Mobile,
    model:Model,
    modelsGroup:finalarray,
    siteId:data.siteId,
    textSent:false,
    userId:null,
    yearMax:MaxYear,
    yearMin:MinYear,
    timestamp:new Date(),
   }
   if(Mobile.length===10 && Mobile!=='' && Mobile!==null && Model!==null && data.businessId!==undefined && data.businessId!==null){

   const docRef = await addDoc(collection(firebase, "vehiclePicks"), pick);
  console.log("Document written with ID: ", docRef.id);
   setloading(false);
   data.showVehiclePicks(true)
   data.setMobilex(Mobile)
   }else{
    setError('Mobile Field is required*')
    setloading(false);
   }
}else{
    setModelErorr('SELECT MODEL*')
}
}


const vipclubFun=async()=>{
    const businessQuery1 =  await getDoc(doc(firebase,'businesses',data.businessId))
    let businessDoc:any;

    if (businessQuery1.exists()) {
      businessDoc = businessQuery1.data();
      const tearApartVehicleSlc = (data.siteId && data.siteId === '17VBJyeH2crmqQC2OSxx' && businessDoc.businessId && businessDoc.businessId === 'gBHr1GdJSlpASgUvkoLW') ? true : false;
      const tearApartVehicleOgden = (data.siteId && data.siteId === '1YYyeZ1YXjGyc6Z6vwRt' && businessDoc.businessId && businessDoc.businessId === 'gBHr1GdJSlpASgUvkoLW') ? true : false;

      const slc = data.siteId && data.siteId === 'bsZLKWJMaoIwztWi0gMg' && businessDoc.businessId && businessDoc.businessId === 'WpJtEW5saD7d4bRutXXb' ? true : false;
      const ogden = data.siteId && data.siteId === 'JGOUaPdjCw7AxY1auyYy' && businessDoc.businessId && businessDoc.businessId === 'SmhXMTflviq60mlf221Z' ? true : false;
      if (tearApartVehicleSlc || tearApartVehicleOgden || slc || ogden) {
        let keyword = '';
        let vipClub = false;
        let location = '';
        if (data.lang === 'eng') {
          vipClub = data.isVipClub;
          keyword = data.englishKeyword;
          if (slc || tearApartVehicleSlc) {
            // keyword = 'tearapartslc';
            location =data.englishLocation;
          }
          if (ogden || tearApartVehicleOgden) {
            location = data.englishLocation;
            // keyword = 'tearapart';
          }
        } else {
          vipClub = data.isVipClubS;
          keyword = data.spanishKeyword;
          location = '';
          if (slc || tearApartVehicleSlc) {
            location = data.englishLocation;
            // keyword = 'tearapartslcespanol';
          }
          if (ogden || tearApartVehicleOgden) {
            location = data.englishLocation;
            // keyword = 'tearapartespanol';
          }
        }

        if (vipClub) {
          fetch(
            "https://client.texnrewards.net/gateway/contactmanager_keyword.asp",
            {
              body: JSON.stringify({
                keyword,
                location,
                mobile:Mobile,
                redirectURL:data.homeBtnUrl,
                shortcode: businessDoc.shortcode,
                user_guid: businessDoc.guid,
              }),
              method: 'POST',
              mode: 'cors',

            }
          )
            .catch((error) => {
              console.error(error);
            });
        }
      } else {
        let keyword = '';
        let vipClub = false;
        const location = data.englishLocation;

        if (data.lang === 'eng') {
          vipClub = data.isVipClub;
          keyword = data.englishKeyword;
        } else {
          vipClub = data.isVipClubS;
          keyword = data.spanishKeyword;
        }

        if (vipClub) {
          fetch(
            "https://client.texnrewards.net/gateway/contactmanager_keyword.asp",
            {
              body: JSON.stringify({
                keyword,
                location,
                mobile:Mobile,
                redirectURL: data.homeBtnUrl,
                shortcode: businessDoc.shortcode,
                user_guid: businessDoc.guid,
              }),
              method: 'POST',
              mode: 'cors',

            }
          )
            .catch((error) => {
              console.error(error);
            });
        }

      }

    }
}

const finalStyle = {
    background:data.theme.jiSignUpUiBackgroundColor!==undefined ? data.theme.jiSignUpUiBackgroundColor : '',
    color:data.theme.jiSignUpUiFontColor!==undefined ? data.theme.jiSignUpUiFontColor : '',

    }

const finalStylecc = {
        background:data.theme.jiSignUpUiBackgroundColor!==undefined ? data.theme.jiSignUpUiBackgroundColor : '',
        color:data.theme.jiSignUpUiFontColor!==undefined ? data.theme.jiSignUpUiFontColor : '',
        fontSize:'24px'
        }



const setmodelfun=(e:any)=>{
    setModel(e.target.value);
    if(e.target.value!==''){
        setloading(false);
    }
    if(Mobile.length===10 && Mobile!=='' && Mobile!==null && Model!==null && data.businessId!==undefined && data.businessId!==null){
                            setmobDisable(false)
         }
}
return (
        <div className="" style={finalStyle}>
     { 
   data.theme!==undefined && data.theme.enableExpanolBtn!==undefined &&   data.theme.enableExpanolBtn===true?
      (data.lang==='eng'? <Button intent='success' className="toggle_lng" onClick={()=>data.callBack(strings.esp,'esp')}>{strings.eng.toggleLanguage}</Button> : 
     <Button intent='success'  className="toggle_lng"  onClick={()=>data.callBack(strings.eng,'eng')}>{strings.esp.toggleLanguage}</Button>):''
    
    }
            <H2 className="heading2newpick" style={finalStylecc}>{langStrings.addNewPick}</H2>
            <p className="yearrange">{langStrings.year} {langStrings.yearRange}</p>
        <div className="d-flex row-full margin-b-15">
            <div className="col-50">
                    <select className="w-70" onChange={(e)=>updateMinYear(e)}>
                       {years.sort((a:any,b:any)=>(a-b)).map((v:any,i:any)=><option key={i} value={v}>{v} </option>)}
                    </select>
            </div>
            <div className="col-50">
                    <select className="w-70"  onChange={(e)=>updateMaxYear(e)}>
                    {years.sort((a:any,b:any)=>(b-a)).map((v:any,i:any)=>v>=MinYear?<option key={i} value={v}>{v} </option>:'')}
                    </select>
            </div>
        </div>
        <div className="d-flex row-full margin-b-15">
            <div className="col-50">
                <div className="specialcss">{langStrings.make}</div>
                    <select className="w-70" onChange={(e:any)=>getModels(e.target.value,MinYear,MaxYear)}>
                    {makes.map((v:any,i:any)=><option key={i} value={v}>{v} </option>)}
                    </select>
            </div>
            <div className="col-50">
            <div className="specialcss">{langStrings.model}</div>
                    <select className="w-70"  onChange={(e:any)=>setmodelfun(e)}>
                    <option value={''}>SELECT MODEL</option>
                    {MakeModels[0]!==undefined ? MakeModels.map((v:any,i:any)=><option key={i} value={v}>{v} </option>) : <option value={''}>SELECT MODEL</option>}
                    </select>
                    <p style={{color:'red'}}>{ModelErorr}</p>    
            </div>
        </div>

        {(data.mobile!==undefined && data.mobile!==null && data.mobile!=='') && data.frompicks!==undefined ? '' : <p className='h22 entermobiletext mt-12'  style={finalStyle}>{langStrings.enterMobile}</p>}
            <InputGroup
            type={(data.mobile!==undefined && data.mobile!==null && data.mobile!=='' ) && data.frompicks!==undefined ? 'hidden' :'number'}
            className='inputForMobile'
            value={Mobile}
            onChange={(e)=>mobileChange(e.target.value)}
            />
            <p style={{color:'red'}}>{Error}</p>
            <Button className=' nextbuttoncss' intent='success' disabled={loading || mobDisable } onClick={()=>addPick()} large={true}><Icon icon='log-out' />&nbsp;&nbsp;&nbsp;{langStrings.next}</Button>
            <br/>

            {data.enableVipClub ? (data.lang === 'eng'?
          (<p style={{ textAlign:"left", paddingLeft:"5px", display: "flex" }}><input type='checkbox' style={{ marginRight:"10px" }} checked={data.isVipClub} 
             onChange={(e)=>data.setisVipClub(e.target.checked)} /><span style={{ cursor:"pointer" }}  title={data.englishVipClubHoverText}>{data.englishVipClubCheckboxText} [?]</span></p>)
          :
          (<p style={{ textAlign:"left", paddingLeft:"5px", display: "flex" }}><input type='checkbox' style={{ marginRight:"10px" }} checked={data.isVipClubS} 
             onChange={(e)=>data.setisVipClubS(e.target.checked)} /><span style={{ cursor:"pointer" }}  title={data.spanishVipClubHoverText}>{data.spanishVipClubCheckboxText} [?]</span></p>)):''
        }
             <br/>
        </div>
       )
    
}