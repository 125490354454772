import { H1, H6, Spinner } from "@blueprintjs/core"
import { collection, deleteDoc, doc, getDoc, getDocs, query } from "firebase/firestore";
import * as React from "react"
import { firebase } from "../global/firebase";
class Unsubscribed extends React.Component<any, any> {
public state ={
    text1:'',
    text2:'',

}
 public  componentDidMount(): void {
     this.deleteReportUser()
    }

public deleteReportUser=async()=>{
    const id = new URLSearchParams(window.location.search).get('id');
    const type = new URLSearchParams(window.location.search).get('type');
        const idx = id!==undefined && id!==null ? id : '';
        if(idx!==''){

            
        await  getDoc(doc(firebase,'report-scheduling',idx)).then(async(res:any)=>{
     if(res.exists()){ 

        if(type==='all'){
        const queryUser=  res.data().map!==undefined ? res.data().map.email : ''    
        console.log(queryUser,'ok');
        
        await getDocs(query(collection(firebase,'report-scheduling'))).then((re:any)=>{

        if(!re.empty){
            const arr:any = [];
            console.log(re,'1');
            
                re.docs.map((row:any)=>{
                    console.log(row.data().map,'1');
                        if(row.data().map!==undefined && row.data().map.email===queryUser){
                            console.log(row.data().map.email,'1');
                           arr.push(row.id) 
                        }
                });

               arr.map(async(idxx:any)=>{
                console.log(idxx,'1');
                    await  deleteDoc(doc(firebase,'report-scheduling',idxx));
                })

        }
    })
    this.setState({text1:"You've Unsubscribed",text2:"You have been successfully unsubscribed from our all mailing list."});  
        }else if(type==='one'){
            await    deleteDoc(doc(firebase,'report-scheduling',idx));
            this.setState({text1:"You've Unsubscribed",text2:"You have been successfully unsubscribed from our mailing list."});  
        }else{
            this.setState({text1:'Something went wrong!',text2:'Url is not valid or expired'});
        }

      
     }else{
            this.setState({text1:'Something went wrong!',text2:'Url is not valid or expired'});
     }
    });

}else{
    this.setState({text1:'Something went wrong!',text2:'Url is not valid or expired'});
}
}
public render(){
return (
    <div
    style={{
        backgroundColor:' #fff',
        padding:'20px',
        display:'flow',
        borderRadius:'8px',
        boxShadow:'0 0 10px rgba(0, 0, 0, 0.1)',
        maxWidth:'500px',
        width:'100%',
        textAlign:'center',
        margin:'auto'
    }}
    className="container">
           <img  src="https://www.texnrewards.com/wp-content/uploads/2023/04/TexnRewards_mobile_connection-01.png" width="200"/>
   {this.state.text1!=='' ?<div><H1 style={{margin:'6px'}}>{this.state.text1}</H1>
    <H6>{this.state.text2}</H6>
    </div>: <Spinner size={20} intent="primary"/>}
</div>
)

}

}

export default  Unsubscribed;