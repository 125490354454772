
import { Button, Classes, Dialog, H1, H2, H3, H4, HTMLTable, Icon } from "@blueprintjs/core";
import * as React from "react";
import { firebase, urls } from "../../global/firebase";
import '../../assets/css/style.css'
import { range } from "lodash";
import { collection, getDoc, getDocs, query, where } from "firebase/firestore";

export  class CustomerInfo extends React.Component{

public state:any={
    coupons:[],
    inActiveCoupons:[],
    yardapikey:'',
    consumer_phone_number:'0000000000',
    nextcoupon:[],
    spendamt:[],
    detail:[],
    modal:false,
    valu:[],
    discounttotal:0,
    tooltipopen:false,
    tooltiptext:'',
    name:''

}

public  componentDidMount(): void {
    const params = new URL(window.location.href).searchParams;  
    const yardapikey = params.get("yard_api_key")!; // is the string "Jonathan Smith".
    const Consumerphonenumber = params.get("consumer_phone_number")!; // is the string "Jonathan Smith".
    const val = params.get("val")!; // is the string "Jonathan Smith".
    this.setState({yardapikey,consumer_phone_number:Consumerphonenumber,valu:val.split(',')})
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    
    const urlencoded = new URLSearchParams();
    urlencoded.append("yard_api_key", yardapikey);
    urlencoded.append("consumer_phone_number", Consumerphonenumber);
    try {
 
   const el:any= document.querySelector('.bp5-navbar')!
     el.style.display = 'none'
            
    } catch (error) {
        console.log(error);
        
    }
    const requestOptions:any = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };
    
     getDocs(query(collection(firebase,'users'),where('mobile','==',Consumerphonenumber))).then((r:any)=>{
           
        if(r.size>0){
         const data = r.docs[0].data();
       
            this.setState({name:data.name.displayName})

        }
            
    })



    fetch(urls.pointsystem+"p1s5e4_get_consumer_information", requestOptions)
      .then(response => response.json())
      .then(result => {
        console.log(result);
        const arrInactive:any =[]
        const arrActive:any =[]
        let discounttotal = 0;
        let spedamt = 0
        result.coupons.map((x:any)=>{
        Object.keys(x).map((l:any)=>{
          
            if(x[l].coupon_used!==undefined && x[l].coupon_used===1){   
              x[l].order = new Date(x[l].issue_date).getTime();         
              arrInactive.push(x[l])
              discounttotal+=x[l].transaction_amount
            }else if(x[l].coupon_used!==undefined && x[l].coupon_used===0){
                x[l].order = new Date(x[l].date_used).getTime();
                arrActive.push(x[l])
                
                const maxValue = this.state.valu.reduce((max:any, current:any) => (current > max ? current : max), this.state.valu[0]); 
                let maxbracket =0
                try {
                maxbracket =parseInt((maxValue.split('-')[0].split(' ')[0]).replace('%','') )           
                if(maxbracket===x[l].coupon_percentage){

                    spedamt+=x[l].amount_spent
               }
                } catch (error) {
                    console.log(error);
                    
                 }
                
            }
        })
        })
        const arrInactivex = arrInactive.sort((a:any,b:any)=>(b.order-a.order))
        const arrActivex = arrActive.sort((a:any,b:any)=>(b.order-a.order))
         
         result.next_coupon.progress_towards=parseInt(result.next_coupon.progress_towards)+spedamt

         this.setState({discounttotal,inActiveCoupons:arrInactivex,coupons:arrActivex,nextcoupon:result.next_coupon,spendamt:result.spent_amounts})   
         
          
        })
      .catch(error => console.log('error', error));
}


public render(){
    const styleTh=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        textAlign:'center'as const,
        fontWeight:'600',
        borderRadius:'3px'

    }

    const styleTh1=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        textAlign:'center'as const,
        fontWeight:'800',
        color:'#388e3c',
        borderRadius:'3px'
        
    }
    const styleTh2=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        fontWeight:'800',
        textAlign:'center'as const,
        color:'#d32f2f',
        borderRadius:'3px'
        
    }
 
    
    const styleThX=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        textAlign:'left'as const,
        borderRadius:'3px'

    }

    const styleThY=  {
        padding: '10px',        
        border: '1px solid #bdbdbd',
        textAlign:'right'as const,
        color:'#424242',
         fontWeight:'600',
        borderRadius:'3px'
    }

    const stylespan=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'22px',
        cursor:'pointer',
        color:'#fff'

    }
    const stylespan2=  {
        padding: '4px',
        fontWeight:600,
        fontSize:'15px',
        cursor:'pointer'
    }

const Datetimeformate=(date:any)=>{
    
const da = new Date(date);
const day = da.getUTCDate()<10 ? '0'+da.getUTCDate():da.getUTCDate()
const month = (da.getUTCMonth()+1)<10 ? '0'+(da.getUTCMonth()+1):(da.getUTCMonth()+1)
const year = da.getUTCFullYear()

return `${month}/${day}/${year}`
}

const timeConvertFormat = (time:any)=>{

    const timeString = time
    // Prepend any date. Use your birthday.
    const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
      .toLocaleTimeString('en-US',
        {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
      );
      return timeString12hr;

}

    const AmountFormate=(x:any)=>{
           
        const options = { 
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          };
            const formatted =  Math.round(Number(x)).toLocaleString('en', options);
            console.log(formatted);
        return formatted
    }

    const prettyMobile = (mobile: string) => {
        const areaCode = mobile.substring(0, 3);
        const prefix = mobile.substring(3, 6);
        const suffix = mobile.substring(6, 10);
      
        return `(${areaCode}) ${prefix}-${suffix}`;
      };
      
const csvDownload=()=>{
    const arr:any = []
    arr.push(['Customer:  '+prettyMobile(this.state.consumer_phone_number),' ',' ',' ',' ',' '])
    arr.push(['Coupon Deduction','Coupon Issue Date','Coupon Expire Date',' ',' ',' '])
    arr.push(['Active Coupon',' ',' ',' ',' ',' '])
    if(this.state.coupons.length>0){        
    this.state.coupons.map((r:any)=>{
        arr.push([valuupdate(r.coupon_percentage+'%'),Datetimeformate(r.issue_date),Datetimeformate(r.expiry_date),' ',' ',' ']);    
    });

    }else{
        arr.push(['','No active coupons at this time',' ',' ',' ',' ']); 
    }


    if(this.state.nextcoupon!==undefined && this.state.nextcoupon.coupon_cap_amount!==undefined){
        
        if(arr.length<=12){
            range(12-arr.length).map(()=>{
                arr.push([' ',' ',' ',' ',' ','']);
            })
        }

        arr[1][4] = 'Next Reward Milestone'
        arr[2][4] = 'Coupon Percentage'
        arr[3][4] = 'Points Earned'
        arr[4][4] = 'Points Needed'
        arr[5][4] = 'Points Needed'
        arr[5][4] = ''
        arr[6][4] = 'Customer Spend & Discount Overview'
        arr[7][4] = 'Last Month Spend'
        arr[8][4] = 'Last 3 Months Spend'
        arr[9][4] = 'Last 6 Months Spend'
        arr[10][4] = 'Last Year Spend'
        arr[11][4] = 'Total Customer Discounts'



        arr[2][5] = valuupdate(this.state.nextcoupon.coupon_percentage+'%')
        arr[3][5] = Math.round(parseInt(this.state.nextcoupon.progress_towards,10))+' Points'
        arr[4][5] = Math.round(parseInt(this.state.nextcoupon.progress_left,10))+' Points'

        if(this.state.spendamt!==undefined){
            arr[7][5] ='$'+Math.round(this.state.spendamt.last_month.toFixed(2))
            arr[8][5] = '$'+Math.round(this.state.spendamt.last_3_months.toFixed(2))
            arr[9][5] = '$'+Math.round(this.state.spendamt.last_6_months.toFixed(2))
            arr[10][5] = '$'+Math.round(this.state.spendamt.last_year.toFixed(2))
            arr[11][5] = '$'+Math.round(this.state.discounttotal)
        }

    }

    arr.push(['Redeemed Coupons',' ',' ',' ',' ',' '])
    arr.push(['Points Before Coupon','Coupon Deduction','Date/Time Coupon Redeemed','Coupon Issue Date','Coupon Expire Date','Customer Discount'])
    if(this.state.inActiveCoupons.length>0){        
    this.state.inActiveCoupons.map((r:any)=>{
        
        arr.push([Math.round(r.amount_spent)+' Points',valuupdate(r.coupon_percentage+'%'),useddate(r),Datetimeformate(r.issue_date),Datetimeformate(r.expiry_date),'$'+r.transaction_amount]); 
        
    });

    }else{
        arr.push(['','No active coupons at this time',' ',' ',' ',' ']); 
    } 
const seperator = ",";


const csv = [
    ...arr.map((row:any )=>  [0,1,2,3,4,5].map(field => `${row[field]}`).join(seperator))
]

//Export our csv in rows to a csv file
let csvContent = "data:text/csv;charset=utf-8," 
    + csv.join("\n");
var encodedUri = encodeURI(csvContent);
window.open(encodedUri);
       

}

const useddate=(r:any)=>{
const time =  r.time_used!==undefined ? timeConvertFormat(r.time_used) :'' ;
const dat =r.date_used!==undefined ? Datetimeformate(r.date_used) :'';
return  `${dat} ${time} CST`
}


const valuupdate=(ind:any)=>{    
    let wr = '';
    if(this.state.valu.length>0){
        this.state.valu.map((v:any)=>{
if(v.includes(ind)){
    wr=v
}

        })
    }

    return wr;
}


const AmountFormatex=(rx:any)=>{
try {
    

let wr = 0;
if(this.state.valu.length>0){
    this.state.valu.map((v:any)=>{
if(v.includes(rx.coupon_percentage+'%')){
   console.log(rx.amount_spent,parseInt(v.split('-')[1]));
   
wr=rx.amount_spent-parseInt(v.split('-')[1])
}
    })
}
return wr.toFixed()
} catch (error) {
    return 0
}
}

const openmodal=(detail:any)=>{
try {

this.setState({detail:JSON.parse(detail),modal:true})
    
} catch (error) {
    console.log(error);
    
}
}

    return(
        <div id='stylestyle' style={{padding:'13px',fontSize:'17px',textAlign:'left',background:'#fff'}}>
<Dialog

title="Help"
icon="info-sign"
isOpen={this.state.tooltipopen}
style={{zIndex:'232131213231'}}
onClose={()=>this.setState({tooltipopen:false})}>
<div style={{padding:'10px',zIndex:'232131213231'}}>
{this.state.tooltiptext}
</div>
</Dialog>


<Dialog

                title="Coupon Trasaction Details"
                icon="info-sign"
                isOpen={this.state.modal}
                style={{width:'90%'}}                
                onClose={()=>this.setState({modal:false})}>
                <div className={Classes.DIALOG_BODY} style={{overflow:'auto'}}>

                    {this.state.detail!==null && this.state.detail[0]!==undefined && this.state.detail.length>0?<HTMLTable border={1}>
                    <tr>
                            <th>Detail Number <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'An internal reference number in Crush for further transaction details.'})}/></th>
                            <th>Transaction ID <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'This is the reference number in Crush for this specific transaction.'})}/></th>
                            <th>Discount Rate (%) <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The discount rate applied from the coupon. Calculated as a percentage of the Part Total.'})}/></th>
                            <th>Discount Type <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The specific type of coupon applied in this transaction.'})}/></th>
                            <th>Discount Amount <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Total dollar amount saved based on the Part Total and Discount Rate.'})}/></th>
                            <th>Pricing Standard <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Pricing standard used for this order, typically set as'})}/></th>
                            <th>Part Name <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The specific type of part(s) associated with this transaction.'})}/></th>
                            <th>Part Quantity <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The total quantity of parts included in this transaction.'})}/></th>
                            <th>Part Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The total price of all parts in the transaction before applying any discounts.'})}/></th>
                            <th>Core Quantity <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The number of cores (recyclable parts) included in this order.'})}/></th>
                            <th>Core Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The core charge amount for this transaction, representing the cost of recyclable parts.'})}/></th>
                            <th>Warranty Total <Icon style={{cursor:'pointer'}} icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The amount spent on warranties for this order, which may be $0 if no warranty was purchased.'})}/></th>
                           
                           
                        </tr>
                     { this.state.detail.map((x:any)=>(  <tr>
                        
                        <td>{x.DetailNo}</td>
                        <td>{x.TransRno}</td>
                        <td>{x.DiscountRate}</td>
                        <td>{x.DiscountName}</td>
                        <td>${Number(x.DiscountAmount).toFixed(2)}</td> 
                        <td>{x.PriceList}</td>
                        <td>{x.PartName}</td>
                        <td>{x.PartQty}</td>                        
                        <td>${Number(x.PartTotal).toFixed(2)}</td>
                        <td>{x.CoreQty}</td>                        
                        <td>${Number(x.CoreTotal).toFixed(2)}</td>
                        <td>${Number(x.WarrantyTotal).toFixed(2)}</td>
                                        
                        </tr>))}
                      
         

                    </HTMLTable>:''}
          </div>
          </Dialog>

            {/* <H1>Yard Api Key: {this.state.yardapikey}</H1> */}
            <div style={{position:'fixed',padding:'5px',border:'1px solid #000',top:'0px',background:'#90caf9',color:'#fff',borderRadius:'5px'}}>
            Download Csv:&nbsp; <Button intent="success" onClick={()=>csvDownload()} title="download csv"><Icon style={{color:'#fff'}} icon='download'/></Button>

            </div>  {this.state.name!==''? <H3 style={{color:'#424242'}}>Name: {this.state.name}</H3> :''}          <H3 style={{color:'#424242'}}>Customer Phone Number: {prettyMobile(this.state.consumer_phone_number)}</H3>
            
            <div className="row-full-width" style={{background:'#fff'}}>
            <div className="w-71">
         <div style={{margin:'10px',background:'#fff'}}>    
              <div>
              <div style={{background: 'linear-gradient(17deg, #ededed, #00000003)'}}>
                    <span style={stylespan2}> <H4 style={{color:'#3a3a3a',padding:'3px',background:'linear-gradient(171deg, #eeeeee, transparent)'}}>&nbsp;Active Coupons <Icon  icon='info-sign' onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Active Coupons section displays all coupons the customer qualifies for based on their current points balance. The highest-value coupon, shown at the top, is the redeemable coupon available at checkout. This coupon is updated each evening to reflect purchases and redemptions. If a purchase moves the customer into a new tier, the highest coupon will update the next day, replacing the previous one. Lower-tier coupons are also listed here but are not redeemable. Redeemed coupons appear in the Redeemed Coupons table. The Coupon Issue Date shows when each tier was reached, and the Coupon Expire Date follows preset rules.'})}  /></H4></span>
                        <p  style={{overflow:'auto',paddingBottom:'5px'}}>    
                        <HTMLTable  >   
                            <thead>                  
                                <tr>                                
                                 
                                    <th style={styleTh1}>Coupon Deduction</th>
                                    <th style={styleTh1}>Coupon Issue Date</th>
                                    <th style={styleTh1}>Coupon Expire Date</th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                            {this.state.coupons.length>0?  this.state.coupons.map((r:any,i:any)=>(
                                       
                                <tr >                                 
                                    <td style={i==0  ? {background:'#e0f7fa',boxShadow:'0px 0px 9px 3px rgb(52 135 56 / 50%)',border:'1px solid #388e3c',padding: '10px',borderRadius:'3px',textAlign:'center'} :styleTh}>
                                        <H4>{valuupdate(r.coupon_percentage+'%')} <sup><small><em>{(i==0 ? ' (Primary)':'')}</em></small></sup></H4></td>
                                    <td style={i==0  ? {background:'#e0f7fa',border:'1px solid #388e3c',boxShadow:'0px 0px 9px 3px rgb(52 135 56 / 50%)',padding: '10px',borderRadius:'3px',textAlign:'center'} :styleTh}><H4>{Datetimeformate(r.issue_date)}</H4></td>
                                    <td style={i==0  ? {background:'#e0f7fa',border:'1px solid #388e3c',boxShadow:'0px 0px 9px 3px rgb(52 135 56 / 50%)',padding: '10px',borderRadius:'3px',textAlign:'center'} :styleTh}><H4>{Datetimeformate(r.expiry_date)}</H4></td>
                              
                                </tr>

                            )) :  <tr><td colSpan={3}>No active coupons at this time</td></tr> } 
                            </tbody>
                            </HTMLTable>                       
                        </p>
                        </div>    

                        {/* INACTIVE COUPONS */}
                        <div style={{background: 'linear-gradient(124deg, #f2f2f2, #00000000)'}}>
                       <span style={stylespan2}> <H4 style={{color:'#3a3a3a',padding:'3px',background:'linear-gradient(171deg, #eeeeee, transparent)'}}>&nbsp;Redeemed Coupons <Icon  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Redeemed Coupons are displayed in real-time when a coupon is redeemed through Crush. However, some fields, like Points After Coupon and SubTotal, are updated in the evening after Crush sends this data. Until then, these fields will show as N/A. Please check back the following day for the updated information.'})} /></H4></span>
                        <p  style={{overflow:'auto'}}>    
                            <HTMLTable >  
                                <thead>         
                                <tr>   
                                <th style={styleTh2}>Date/Time Coupon Redeemed </th>                             
                                   <th style={styleTh2}>Points Before Coupon </th>
                                    <th style={styleTh2}>Coupon Deduction </th>                                    
                                    <th style={styleTh2}>Points After Coupon <Icon  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Points After Coupon will display N/A until the day after a coupon is applied. This is because it’s not just Points Before Coupon minus Coupon Deduction. The SubTotal, which updates nightly, also factors into the calculation'})}/></th> 
                                    <th style={styleTh2}>Customer Discount </th>
                                    <th style={styleTh2}>SubTotal <Icon  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'Points After Coupon should also display N/A until the SubTotal is updated, as its not simply a matter of subtracting the coupon deduction from the Points Before Coupon. The SubTotal amount needs to be factored in because points are earned based on the final SubTotal, after the coupon deduction is applied, in order to accurately calculate the Points After Coupon.'})}/></th>

                                    <th style={styleTh2}>Coupon Issue Date </th>
                                    <th style={styleTh2}>Coupon Expire Date </th>                                    
                                    <th style={styleTh2}>Detail <Icon  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Details section provides in-depth transaction information for redeemed coupons. If details are not yet available, it may be because the coupon was redeemed earlier today, and the data updates overnight. Check back the following day for full details. Once populated, this section includes: Transaction ID (reference number in Crush), Detail Number (additional reference), Discount Rate (percentage off), Discount Amount (amount saved), Part Total (cost of parts before discount), Part Name (type of part), Part Quantity, Warranty Total (if any), Core Quantity, and Core Total.'})}/></th>
                                </tr>
                                </thead>  
                                
                                <tbody>   
                            {this.state.inActiveCoupons.length>0? this.state.inActiveCoupons.map((rx:any)=>(
                         
                                <tr>
                                    <td style={styleTh}>{rx.date_used!==undefined ? Datetimeformate(rx.date_used) :''}  {rx.time_used!==undefined ? timeConvertFormat(rx.time_used) :''} CST</td>
                                    <td style={styleTh}>{AmountFormate(rx.points_before_coupon)+' Points'}</td>
                                    <td style={styleTh}>{valuupdate(rx.coupon_percentage+'%')}</td>
                                    <td style={styleTh}>{AmountFormate((parseInt(rx.points_after_coupon)>0 ? parseInt(rx.points_after_coupon) :0)+(parseInt(rx.p1s5e3_subtotal,10)>0 ? parseInt(rx.p1s5e3_subtotal,10) :0))+' Points'}</td>
                                    <td style={styleTh} title={rx.transaction_id!==undefined ? rx.transaction_id :''}>${rx.transaction_amount!==undefined ? rx.transaction_amount :''}</td>
                                    <td style={styleTh}>{parseInt(AmountFormate(rx.p1s5e3_subtotal),10)<=0  ? 'N/A' :'$'+AmountFormate(rx.p1s5e3_subtotal) }</td>

                                    <td style={styleTh}>{Datetimeformate(rx.issue_date)}</td>
                                    <td style={styleTh}>{Datetimeformate(rx.expiry_date)}</td>
                                    <td style={styleTh} onClick={()=>openmodal(rx.p1s5e3_transaction_details)}>{rx.p1s5e3_transaction_details!==null ? <Icon icon='eye-open'/>:'N/A'}</td>

                                </tr>  
                                                                 
                            )):   <tr><td colSpan={3} style={{color:'#757575'}}><em>No redeemed coupons at this time</em></td></tr> }  

                            <tr>
                            <td style={styleTh} colSpan={3}></td>
                            <td style={styleTh}><b>Total Customer Discounts</b></td>
                            <td style={styleTh}><b>${this.state.discounttotal <1 ? this.state.discounttotal : AmountFormate(this.state.discounttotal)}</b></td>
                            <td style={styleTh}></td>
                            <td style={styleTh}></td><td style={styleTh}></td>
                            <td style={styleTh}></td>
                            </tr>    
                               </tbody>     
                               </HTMLTable>                  
                        </p>
                        </div>                        
                        </div>            
                
                
                        </div>

             </div>
            <div className="w-31">
                       <div style={{margin:'10px',background:'#e3f2fd',padding:'4px'}}>
                        <H4>Customer Point & Next Coupon Overview <Icon style={{color:'#5f6368',cursor:'pointer'}}  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Next Coupon shows the value of the reward you are currently working towards.'})}/></H4>
                        {this.state.nextcoupon!==undefined && this.state.nextcoupon.coupon_cap_amount!==undefined  ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                              <HTMLTable>                          
                                
                                 <tr>
                                <th style={styleThX}><H4>Current Point Balance <Icon style={{color:'#5f6368',cursor:'pointer'}}  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Current Points Balance shows the total points you’ve accumulated for purchases, including those where a coupon was applied. When you redeem a coupon, the points deducted are subtracted from this balance, but points from the subtotal of that transaction are added back. The balance is updated at the end of each day, so the points shown reflect the previous days activity and may not account for any purchases or redemptions made today. Even after applying a coupon, any leftover points after the deduction remain in your balance. Its possible to redeem a coupon and earn enough points in the same day to qualify for a new coupon, which will be issued after the day’s transactions are processed'})} /></H4></th>
                                <td style={styleThY}><H4 style={{color:'#388e3c'}}>{AmountFormate(parseInt(this.state.nextcoupon.progress_towards,10))} Points</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Points Need To Next Coupon</H4></th>
                                <td style={styleThY}><H4>{AmountFormate(parseInt(this.state.nextcoupon.progress_left,10))} Points</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Next Coupon Value</H4></th>
                                <td style={styleThY}><H4>{this.state.nextcoupon.coupon_percentage}%</H4></td>
                                </tr>
                                </HTMLTable>
                        </p>
                        :''}
                        </div>  


                         <div  style={{margin:'10px',background:'#e3f2fd',padding:'4px'}}>
                        <H4>Customer Spend & Discount Overview <Icon  style={{color:'#5f6368',cursor:'pointer'}}  icon='info-sign'  onClick={()=>this.setState({tooltipopen:true,tooltiptext:'The Customer Spend & Discount Overview provides a breakdown of the customer’s spending over the last month, 3 months, 6 months, and the past year, along with the total amount saved through discounts. This overview gives insights into customer spending behavior and shows how much has been discounted over time.'})} /></H4>
                        {this.state.spendamt.last_3_months!==undefined    ? 
                        <p  style={{marginLeft:'20px',marginTop:'8px'}}>
                                 <HTMLTable>
                              <tr>
                                <th style={styleThX}><H4>Last Month Spend</H4></th>
                                <td style={styleThY}><H4>${AmountFormate(this.state.spendamt.last_month.toFixed(2))}</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Last 3 Months Spend</H4></th>
                                <td style={styleThY}><H4>${AmountFormate(this.state.spendamt.last_3_months.toFixed(2))}</H4></td>
                                </tr>
                                <tr>
                                <th style={styleThX}><H4>Last 6 Months Spend</H4></th>
                                <td style={styleThY}><H4>${AmountFormate(this.state.spendamt.last_6_months.toFixed(2))}</H4></td>
                                </tr>                              
                                <tr>
                                <th style={styleThX}><H4>Last Year Spend</H4></th>
                                <td style={styleThY}><H4>${AmountFormate(this.state.spendamt.last_year.toFixed(2))}</H4></td>
                                </tr>

                                <tr>
                                <th style={{border:'2px solid #000000',padding:'4px'}}><H4><b>Total Customer Discounts</b></H4></th>
                                <td style={{border:'2px solid #000000',padding:'4px',borderLeft:'none',textAlign:'end'}}><H4>${AmountFormate(this.state.discounttotal)}</H4></td>
                                </tr>
                                </HTMLTable>
                        </p>
                        :''}
                    </div>                    
             
                </div>
                    </div>
        </div>
    )
}


}


