
import { AnchorButton, Button,  ButtonGroup,  H3, HTMLTable, Icon, InputGroup,  Slider, Spinner, Tab, Tabs } from '@blueprintjs/core';
import * as React from "react";
import { useEffect, useState } from "react";
import { DateRange, DateRangeInput } from '@blueprintjs/datetime';
import { format, Locale, parse } from "date-fns";
import '../assets/css/datetime.css'
import { firebase, functions } from '../global/firebase';
import { httpsCallable } from 'firebase/functions';
import { addDoc, collection, doc, getDocs, onSnapshot, query, where } from 'firebase/firestore';
import csvDownload from 'json-to-csv-export'
import { useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import ProgressBar from '@ramonak/react-progress-bar';
import { ScaleLoader } from 'react-spinners';

const locales: { [localeCode: string]: Locale } = require("date-fns/locale");


const Reports=()=>{

  const auth:any = useAuthUser();
  const user =  auth()
    return(
        <div className="main_div_report" >
               <Tabs
                animate={true}
                key={"vertical"}
                vertical={false}>

                <Tab id="rx1" title="Check-Ins Report" panel={ 
                <div>
                    <H3>Check-Ins Report</H3>
                    <div>
                        <ReportForm functionName="generateCheckInsReport" Name='checkIn'/>
                    </div>
                </div>
                }/>
                <Tab id="rx2" title="Vehicle Picks Report" panel={ 
                <div>
                    <H3>Vehicle Picks Report</H3>
                    <div>
                    <ReportForm functionName="generateVehiclePicksReport"  Name='vehiclePick'/>
                    </div>
                </div>
                }/>
                <Tab id="rx3" title="Reports Delivered to Mail" panel={ 
                <div>
                    <H3>Reports Delivered to Mail</H3>
                    <div>
                    <ReportForm functionName="reportToMail" Name='delivertomain'/>
                    </div>
                </div>
                }/>
                <Tab id="rx4" title="Void Check-Ins Report" panel={ 
                <div>
                    <H3>Void Check-Ins Report</H3>
                    <div>
                    <ReportForm functionName="voidCheckins" Name='voidCheckins'/>
                    </div>
                </div>
                }/>


       {/* {(user!==undefined  && user!==null) && (user.accountData.permissions!==undefined && user.accountData.permissions.archivecheckinreport!==undefined &&  user.accountData.permissions.archivecheckinreport===true) || ( user.accountData.permissions.role!==undefined &&  user.accountData.permissions.role=='admin' ) ? 
       <Tab id="rx5" title="Check-Ins Report older than 12 months"  panel={ 
                <div>
                    <H3>Check-Ins Report (CST Timezone)</H3>
                    <div>
                    <ReportForm functionName="generateArchivedCheckInsReport" Name='archivedreport'/>
                    </div>
                </div>
                }/>:''}


<Tab id="rx56" title="Check-Ins Report Counts"  panel={ 
                <div>
                    <H3>Check-Ins Report (counts)</H3>
                    <div>
                    <ReportForm functionName="generateCheckInsReportcount" Name='archivedreportcount'/>
                    </div>
                </div>
                }/> */}


                </Tabs>
        </div>
    )

}


export default Reports


export const DATE_FNS_FORMATS: any[] = [
    getDateFnsFormatter("MM/dd/yyyy"),
    getDateFnsFormatter("yyyy-MM-dd"),
    getDateFnsFormatter("yyyy-MM-dd HH:mm:ss"),
    getDateFnsFormatter("LLL do, yyyy 'at' K:mm a"),
];

function getDateFnsFormatter(formatStr: string): any {
    return {
        formatDate: (date:any, localeCode:any) => format(date, formatStr, maybeGetLocaleOptions(localeCode)),
        parseDate: (str:any, localeCode:any) => parse(str, formatStr, new Date(), maybeGetLocaleOptions(localeCode)),
        placeholder: `${formatStr}`,
    };
}

function maybeGetLocaleOptions(localeCode: string): { locale: Locale } | undefined {
    if (locales[localeCode] !== undefined) {
        return { locale: locales[localeCode] };
    }
    return undefined;
}


let unsubscribe = ()=>{};
export  const ReportForm=(data:any)=>{
    const [businesses,setbusiness]=useState([] as any);
    const [sites,setsites]=useState([] as any);
    const[ range,setrange]=useState([null,null] as DateRange)
    const [formate,setformate]=useState(DATE_FNS_FORMATS[0])
    const [businessId,setbusinessId]=useState('');
    const [businessPath,setbusinessPath]=useState('');
    const [sitePath,setsitePath]=useState('');
    const [loading,setloading]=useState(false)
    const [siteId,setsiteId]=useState('');
    const [csv,setcsv]=useState('');
    const [Email,setEmail]=useState('');
    const [Period,setPeriod]=useState('weekly')
    const [ReportOf,setReportOf]=useState('vehicle-picks')
    const [bool,setbool]=useState(false)
    const [progressx,setprogress]=useState(0)
    const [datesarray,setdatesarray]=useState([])
    const final:any = useSelector(state=>state);
      useEffect(()=>{

         if(formate===undefined){ setformate(DATE_FNS_FORMATS[0]) }         
            if(businesses[0]===undefined){                    
            setbool(!bool)
            const lengthplus=(final.BusinessesReducer.Businesses.length+final.BusinessesReducer.Archived.length)

     
            if(final.BusinessesReducer.Businesses[0]!==undefined && final.BusinessesReducer.Businesses!==null
              //  &&  lengthplus=== final.BusinessesReducer.len

              
              ){  
                
                          
          // fetch('https://client.texnrewards.net/gateway/v2/sent_message_count.asp?token={CF462D20-97AB-4B19-B784-9F7B22C2E7A7}&encoding=3&start_date=11/1/2024&end_date=11/6/2024').then((r)=>{
          //   console.log(r);
            
          //                 }).catch((e)=>{
          //                   console.log(e);
                            
          //                 })
                
                try {
                setbusiness(final.BusinessesReducer.Businesses)
                setsites(final.BusinessesReducer.Businesses[0].sites)                      
            } catch (error) {
                   console.log(error);
                    
            }
            }           
        }          

      },[bool,data])

    const busienssChange=(id:any)=>{
        setcsv('')
          setbusinessId(id)
        businesses.map((res:any)=>{
                if(res.businessId===id){
                    setsites(res.sites)
                    try {
                    if(res.sites!==undefined && res.sites.length>0){
                    setsiteId(res.sites[0]['siteId'])
                    }
                    
                      
                  } catch (error) {
                      console.log(error,'site [] erorr');
                      
                  }
                }
        })        
    }
   const siteChange=(id:any)=>{
    setcsv('')
         setsiteId(id)
   } 

   const setrangeDate=(v:any)=>{
    setcsv('') 
let daysxx =91
if(data.functionName==='generateArchivedCheckInsReport'){
  daysxx=31
}

if(data.functionName==='generateCheckInsReportcount'){
  daysxx=366
}
    const startDate = v[0]
    const endDate = v[1]
    if(endDate && startDate){
        if(diffInMonths(startDate,endDate)>=daysxx){
            alert('Sorry you can download maximun '+daysxx+' days data');
            return false;
          }else{
            setrange(v)
          return true;
      }
          }else{
            setrange(v)
            return;
      }
   }

   const diffInMonths = (d1:any, d2:any) => {
    
  const diff = d2.getTime() - d1.getTime();       
  const daydiff = diff / (1000 * 60 * 60 * 24);   
 return daydiff;
 }

const generateDate=(v:any)=>{
const d=  new Date(v);
const day = d.getDate();
const month =d.getMonth()+1;
const year=  d.getFullYear();


return  `${month}/${day}/${year}`
// return d
}

const getCheckInsReport=async()=>{

     unsubscribe()
    const addMessage = httpsCallable(functions, data.functionName,{ timeout :500000});


    unsubscribe=   onSnapshot(doc(collection(firebase,'reportsProgress'),businessId),{includeMetadataChanges:false}, async(querySnapshot:any) => {    
               
    if(querySnapshot.exists()  && querySnapshot.data().progress!==undefined){
      setprogress(querySnapshot.data().progress)
        if(querySnapshot.data().progress>=100){
          setprogress(0)
        }
    }else{
      setprogress(0)
    }
    });


    if(range[0]!==undefined && range[0]!==null && range[1]!==undefined && range[1]!==null
        && businessId!=='' 
        ){
          setloading(true)
    const startDate = generateDate(range[0]);
    const endDate = generateDate(range[1]);
          console.log(startDate,endDate);
    const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone;
      // sites.map((x:any)=>{  
      //     if(x.siteId===siteId) {    
      //     timezone= x.timezone
      //      }

      // })
      let siteIdx:any = siteId
      if(siteId===''){
          siteIdx =null
        }
   await addMessage({businessId,siteId:siteIdx,startDate,endDate,timezone})
    .then(async(result) => {

      proccessSiteAndBusinessPath(businessId,siteId)
             
      const data:any=await result.data;
      console.log(data);
      if(data!=='{"result": null}' && data.length>100 ){
        console.log(data.replace('{"result": "','').replace('}',''),'kookok');
        
            setcsv(data.replace('{"result": "','').replace('}',''))
      }else{
        alert('No data Found')
      }
            setloading(false)
    }).catch((e)=>{
      console.log(e,'erorrr');
      
        setloading(false)
    });
         
        }else{
            alert('Please Select busienss and date range');
        }

   }

const proccessSiteAndBusinessPath=(Bid:any,Sid:any)=>{

  businesses.map((v:any)=>{
    if(v.businessId===Bid){
      setbusinessPath(v.path)
    }
      });

    sites.map((vx:any)=>{
        if(vx.siteId===Sid){
          setsitePath(vx.path)
        }
          });

}


//    void

const generateQuery =async ({
    businessId,
    endDate,
    siteId,
    startDate,
  }: {
    businessId?: string;
    endDate?: string;
    siteId?: string;
    startDate?: string;
  }) => {
    const voidcheckin =collection(firebase,'voidedCheckIns')
    const end = new Date(endDate ? endDate : "");
    const start = new Date(startDate ? startDate : "");
    
    const q = query(voidcheckin,
        where("businessId", "==", businessId),
        where("siteId", "==", siteId),
        where("voidedAt", ">=", start),
        where("voidedAt", "<=", end),
        );
    const querySnapshot = await getDocs(q);
    return querySnapshot;
  };

  
  const getCheckIns = async (query: any) => {
    const snapshot = await query;
    const checkIns: any = [];
    let checkInsRev: any = [];

    await snapshot.forEach((doc:any) => {
      if (doc.exists) {
        const checkInId = doc.id;
        const checkInData = doc.data();

        checkIns.push({ ...checkInData, checkInId });
      }
    });

    checkInsRev = checkIns.reverse();

    return checkInsRev;
};

const convertTimestamp = (timestamp: any) => {
    const date = timestamp.toDate();
    const formatted = date.toLocaleString();
    return formatted;
  };

const processMobile = (mobile: string | null) => {
    if (mobile) {
      if (mobile === "6028181729") {
        return "opted out";
      }
      return mobile;
    }
    return null;
  };

const processCheckIns = (checkIns: any) =>
checkIns.map(
  (checkin:any) => ({
    timestamp: convertTimestamp(checkin.timestamp),    
    location: checkin.sitePath,
    language:checkin.language,
    mobile: checkin.secretMobile || processMobile(checkin.mobile),
    firstname: (checkin.name && checkin.name.firstname) || null,
    lastname: (checkin.name && checkin.name.lastname) || null,
    zip:checkin.zip,
    supercard: checkin.supercard ? checkin.supercard : "",    
    voidedAt: convertTimestamp(checkin.voidedAt),
  })
);


const generateFieldsArray = (businessId:any) => {
       const fields = [
        "Date/Time",
        "Location",
        "Language",
        "Mobile",
        "First Name",
        "Last Name",
        "ZIP Code",
        "supercard",
        "Void At",
      ];

  
    return fields;
  };
  
const generateVoidCheckInsReport = 
async (data: {
  businessId?: any;
  endDate?: any;
  siteId?: any;
  startDate?: any;
}) => {
    if (data.businessId) {
      const query = await generateQuery({
        businessId: data.businessId,
        endDate: data.endDate,
        siteId: data.siteId,
        startDate: data.startDate,
      });

      const checkIns = await getCheckIns(query);
      const processed = processCheckIns(checkIns);

      const dataToConvert = {
        data: processed,
        filename: 'void_CheckIns_Reports',
        delimiter: ',',
        headers: generateFieldsArray(data.businessId)
      }

      const voidCsv =
        processed.length > 0
          ?  csvDownload(dataToConvert)
          : null;

      if (voidCsv) {
        return encodeURI(voidCsv);
      }else{
        alert('Void CheckIns Not Found')
        return null;
      }
  }else{
    return null;
  }
};

const getoidCheckInsReport=(async()=>{
    const endDate =range[1]
    const startDate = range[0];
    setloading(true)
    const dataf={
        businessId,
        endDate,
        siteId,
        startDate
    }    
   await generateVoidCheckInsReport(dataf)
   setloading(false)
})

const validateEmail = (email:String) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

const SignUpForEmailReport=async()=>{
if(businessId!=='' &&  Email!=='' && validateEmail(Email)){
  setloading(true)
 const docc = await getDocs(query(collection(firebase,'report-scheduling')))
const arr = docc.docs.filter((v)=>{
      try {
        const r= v.data().map;
        if(r.businessId===businessId && r.siteId===siteId && r.email===Email && r.type===ReportOf &&  r.occurence===Period){
           return true
        }else{
          return false
        }

      } catch (error) {
          console.log(error);  
          return false  
      }
 })

  if(arr.length<=0){
const  map = {
    active:true,
    businessId,
    siteId,
    email:Email,
    type:ReportOf,
    occurence:Period
  }
  await addDoc(collection(firebase, "report-scheduling"), 
    {
      map
  }
  ).catch((e)=>{
    console.log(e);             
  }).then(async(i:any)=>{
    setloading(false)
    alert('Successfully Added Report Schedule')
                     
  })
}else{
  alert('busienss and  site and email already exist.');
  setloading(false)
}
}else{
  alert('busienss and  site and email are required.')
  setloading(false)
}


}


const getCheckInsReportcount=()=>{

 console.log(range,businessId);
 
  
  if(range[0]!==undefined && range[0]!==null && range[1]!==undefined && range[1]!==null
    && businessId!=='' 
    ){
     
      
      var x1 = range[0]
      var y1 = range[1]
const arr:any = []
 while (x1 <= y1) {
  
  console.log(x1.getDate());
   x1.setMonth(x1.getMonth()+1)
   console.log(x1.getDate());
   
   arr.push({date:`${x1.toDateString()}`,new:'',return:'',optout:'',void:'',recheck:'',total:'',loading:false,success:false})
 }

 setdatesarray(arr)

    }else{
      alert('Please select date and business')
    }

}


// checkin count report

const getcheckincounts=async(datac:any,index:any)=>{

  console.log(datac);
  loadingsetget(index,true)


  const addMessage = httpsCallable(functions, data.functionName,{ timeout :500000});

  if(datac.date!==undefined && datac.date!==null && businessId!==''){

const timezone=Intl.DateTimeFormat().resolvedOptions().timeZone;

  let siteIdx:any = siteId
  if(siteId===''){
      siteIdx =null
    }
    const eDate =datac.date
await addMessage({businessId,siteId:siteIdx,eDate,timezone})
.then(async(result) => {
  proccessSiteAndBusinessPath(businessId,siteId)
         
  const data:any=await result.data;
  console.log(data);
  if(data!==null && data.length>100 ){
    
    console.log(data);
    loadingsetget(index,false)
  }else{
    alert('No data Found')
    loadingsetget(index,false)
  }
        
}).catch((e)=>{
  console.log(e,'erorr');
  loadingsetget(index,false)

});

    }


}


const loadingsetget=(index:any,bool:boolean)=>{
  const arr=  datesarray.filter((x:any,y:any)=>{
    if(y==index){
      x.loading=bool;  
    }
    return x
      });
      setdatesarray(arr)
}


return(
    <div>
        <div style={{display:data.functionName!=='reportToMail' ? window.innerWidth<=720 ?'grid' : 'flex' : 'inline-table'}}>
        <select className='selectInput' onChange={(e)=>busienssChange(e.target.value)}><option value={''}>select business</option> {businesses.map((val:any,i:any)=>(val.sites!==undefined && val.sites.length>0 ?<option key={i} value={val.businessId}>{val.businessName}</option>:''))}</select>
        <select className='selectInput'  onChange={(e)=>siteChange(e.target.value)}><option value={''}>select site</option> 
        {sites.length>1?<option  value={''}>all</option>:''}
        {sites.map((val:any,i:any)=>((val.isArchive===undefined || val.isArchive===0 || val.isArchive==='0') ?  <option key={i} value={val.siteId}>{val.path}</option>:''))}
        </select>
        {data.functionName!=='reportToMail'?
        <DateRangeInput  
        popoverProps={{ position: 'auto' }}                   
                   {... data.functionName=='generateCheckInsReportcount' ?  getDateFnsFormatter("MM/yyyy") : getDateFnsFormatter("MM/dd/yyyy")}
                   allowSingleDayRange={false} 
                   shortcuts={false}
                   
                    value={range}                
                    onChange={(range:any)=>setrangeDate(range)}
                />
                :''} 
    {data.functionName!=='reportToMail'? <span>&nbsp;
       {csv==='' ? 
       <ButtonGroup>
       {<Button className='buttonInput'  loading={loading} onClick={()=>data.functionName!=='voidCheckins' &&  data.functionName!=='generateCheckInsReportcount' ? getCheckInsReport():  data.functionName=='generateCheckInsReportcount' ?  getCheckInsReportcount() : getoidCheckInsReport()}>{data.functionName=='generateCheckInsReportcount' ? 'generate report':'get report '}&nbsp;&nbsp;&nbsp;<Icon icon='globe-network'  color='#fff' size={15}/>&nbsp;</Button>}
  
{loading && progressx>0 && progressx<100?<Button className='buttonInputX' intent={progressx>=10 && progressx<=30 ? 'primary':  progressx>=30 && progressx<=40 ? 'none': progressx>=40 && progressx<=60 ? 'warning' : progressx>=60 && progressx<=100 ? 'success' :'primary'} small={true}>{progressx}%</Button>:''}
       </ButtonGroup>:
         <AnchorButton
         download={`${data.Name}-${businessPath}-${sitePath}-${generateDate(range[0])}-to-${generateDate(range[1])}.csv`}
         href={`data:text/csv;charset=utf-8,${csv}`}
         intent="success"
         rightIcon="download"
         loading={loading}
        //  minimal={true}
         
       >
         download csv
       </AnchorButton>
       }
       </span>       
       :''}  

{data.functionName==='reportToMail'? 
  <InputGroup
  type="email"
   disabled={false}
   large={false}
   placeholder="Enter email..."
   readOnly={false}
   onChange={(f:any)=>setEmail(f.target.value) }
/>
:''}
{data.functionName==='reportToMail'?
 <select className='selectInput'  onChange={(e)=>setReportOf(e.target.value)}>
  <option value={'vehicle-picks'}>Vehicle Picks Reports</option>
  <option value={'check-ins'}>Check Ins Reports</option>
  </select>:''}
  {data.functionName==='reportToMail'? <select className='selectInput'  onChange={(e)=>setPeriod(e.target.value)}>
      <option value="weekly">Weekly (every Mon)</option>
      <option value="monthly">Monthly (every 1st)</option>
      <option value="daily">Daily</option>
  </select>:''}

  {data.functionName==='reportToMail'?
  <Button className='buttonInput' onClick={()=>SignUpForEmailReport()} loading={loading} >Sign Up</Button>
      :''}
        </div>


{/* 
<HTMLTable border={1}>
<tr>
    <th>Month/Year</th>
    <th>New</th>
    <th>Return</th>
    <th>Opt-Out</th>
    <th>Void</th>
    <th>Recheck</th>
    <th>Total</th>
    <th>Action</th>
  </tr>
  <tbody>

  {datesarray.map((xy:any,inx:any)=>
  <tr>
    <td>{`${new Date(xy.date).getMonth()<10 ? '0'+new Date(xy.date).getMonth():new Date(xy.date).getMonth()}/${new Date(xy.date).getFullYear()}`}</td>
    <td>{xy.loading ? <ScaleLoader   width={10} height={15} color="#fff" /> :xy.new}</td>
    <td>{xy.loading ? <ScaleLoader   width={10} height={15} color="#fff" /> :xy.return}</td>
    <td>{xy.loading ? <ScaleLoader   width={10} height={15} color="#fff" /> :xy.optout}</td>
    <td>{xy.loading ? <ScaleLoader   width={10} height={15} color="#fff" /> :xy.void}</td>
    <td>{xy.loading ? <ScaleLoader   width={10} height={15} color="#fff" /> :xy.recheck}</td>
    <td>{xy.loading ? <ScaleLoader   width={10} height={15} color="#fff" /> :xy.total}</td>
    <th>{ xy.success ? <Button intent='danger'>Remove</Button> :<Button loading={xy.loading} intent='primary' onClick={()=>getcheckincounts(xy,inx)}>Get</Button>}</th>
  </tr>
)}
  </tbody>
</HTMLTable> */}

    </div>
)




}