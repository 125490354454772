export default [
    "ACURA",
   //  "AMC",
    "AMERICAN MOTORS",
    "AUDI",
    "BMW",
    "BUICK",
    "CADILLAC",
    "CHEVROLET",
    "CHRYSLER",
    "DAEWOO",
    "DAIHATSU",
    "DATSUN",
    "DODGE",
    "EAGLE",
    "FIAT",
    "FORD",
    "GEO",
    "GMC",
    "HONDA",
    "HUMMER",
    "HYUNDAI",
    "INFINITI",
    "INTERNATIONAL",
    "ISUZU",
    "JAGUAR",
    "JEEP",
    "KIA",
    "LAND ROVER",
    "LEXUS",
    "LINCOLN",
    "MASERATI",
    "MAZDA",
    "MERCEDES-BENZ",
    "MERCURY",
    "MERKUR",
   //  "MG",
   //  "MGS GRAND SPORT (MARDIKIAN)",
    "MINI",
    "MITSUBISHI",
    "NISSAN",
    "OLDSMOBILE",
    "PLYMOUTH",
    "PONTIAC",
    "PORSCHE",
    "RAM",
   //  "RENAULT",
    "SAAB",
    "SATURN",
   //  "SCION",
    "SMART",
    "SUBARU",
    "SUZUKI",
    "TOYOTA",
    "TRIUMPH",
    "VOLKSWAGEN",
    "VOLVO",
   ];