import { collection, doc, setDoc } from "firebase/firestore";
import { firebase } from "../global/firebase";



const getCouponsFromIVision = async (guid: string, mobile: string, token: string) => {
    try {
      console.log('Ivision client coupons');      
      const url = `https://client.texnrewards.net/gateway/v2/coupons_by_mobile.asp?user_guid=${guid}&mobile=${mobile}&token=${token}&action_type_id=1&encoding=3`;
      const response = await fetch(url);
      
      const couponsOriginal =await response.json();  
    
        return couponsOriginal;
    } catch (error) {
      if (error instanceof Error) {
        return [];
      }else{
        return [];
      }
    }
  
    
  };

export  const Refreshcoupons=async(data:any)=>{
try {

const {businessId,guid,mobile,userId,token} = data;
if (businessId && guid && mobile && userId) {
    try {

      const couponsRef = collection(firebase,`users/${userId}/coupons`);

      const couponsOriginal = await getCouponsFromIVision(guid, mobile,token);
      if(Object.keys(couponsOriginal).length>0){
        for (let index = 0; index < Object.keys(couponsOriginal).length; index++) {
          const x = couponsOriginal[index];
          const ccguid = x['coupon_code_guid'].replace('{','').replace('}','')
          const contactsguid = x['contacts_guid'].replace('{','').replace('}','')
          const ele={  
            barcode_id:x['barcode_id'],
            cc_guid:ccguid,
            user_guid:guid,
            businessId,
            contacts_guid:contactsguid,
            coupon_code:x['coupon_code'],
            coupon_description:x['coupon_description'],
            coupon_guid:x['coupon_guid'],
            coupon_name:x['coupon_name'],
            date_in:new Date(x['date_in']),
            expires_date:new Date(x['expires_date']),
            issued_date:new Date(x['issued_date']),
            redemption_flag: x['redemption_flag'],
            is_expired:x['is_expired'], 
        }
       const idx = ccguid.replaceAll('-','')
        setDoc(doc(couponsRef,idx),ele);
        console.log(ele,);
          
        }
  }
 
    } catch (error) {  
        console.log(error);
    }
  } else {

    const error = `Missing something important: businessID=${businessId} guid=${guid} mobile=${mobile} userId=${userId}`;
    console.error(new Error(error));

  }
  
} catch (error) {
  console.log(error,'787');
  
}

}