import { Button, ButtonGroup } from "@blueprintjs/core";
import * as React from "react";

const SelectTimeFrame = (dat:any)=>{
let allshow = false;
console.log(dat.data);

dat.data.busData.forEach((xs:any)=>{
if( xs.businessId===dat.data.selectedBusiness && xs.todaysallTabOption!==undefined && xs.todaysallTabOption===true){
    allshow=true
    
}
})    
const data=  dat.data;
const getPreviousData=dat.getPreviousData;
const hourtoAmPm=dat.hourtoAmPm;
    return (
        <ButtonGroup className="btn-group">
<div className="liveupdateinfo" style={{display:'block',textAlign:'center'}}>Select Time Frame:</div>
{allshow ? <Button className="btn-p-f" disabled={data.loading}  intent={'primary'} onClick={()=>getPreviousData('all')}>All</Button> : ''}
{data.filterHours.map((u:any,i:any)=>
<Button key={i} disabled={data.loading} className="btn-p-f" intent="primary" onClick={()=>getPreviousData(u)}>{hourtoAmPm(u)}</Button>
 )}
<Button className="btn-p-f" disabled={data.loading}  intent="primary" onClick={()=>getPreviousData('live')}>Latest</Button>
</ButtonGroup>
    )
};

export default SelectTimeFrame;