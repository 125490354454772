import * as React from "react";
import BusinessAndSiteSelect from "./BusinessAndSiteSelect";

const TopLogos =(dat:any)=>{
    const businessSelected = dat.businessSelected;
    const SiteSelected=dat.SiteSelected;
    const busData = dat.busData;
    const siData=dat.siData;
    const theme = dat.theme;
    const link = 'https://www.texnrewards.com/wp-content/uploads/2023/04/TexnRewards_mobile_connection-01.png';
return (
    <div style={{display:'flex',justifyContent:'center',borderBottom:'2px solid #000'}}>
      <div className="topLogos"><img src={link}/></div>  
      {busData!==undefined && busData!==null ?  <BusinessAndSiteSelect
   busData={busData}
   siData={siData}
   SiteSelected={(e:any)=>SiteSelected(e)}
   businessSelected={(e:any)=>businessSelected(e)}
   />:''}
     <div className="topLogos">  <img src={theme.logo!==undefined && theme.logo!==null ? theme.logo : link }/></div> 


    </div>
)

}

export default TopLogos;